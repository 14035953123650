import { gql } from '@apollo/client'

import { STORAGE_POST_FIELDS, StoragePost } from './storage_post'

export type UserDocumentContentType = 'application/pdf'

export const USER_DOCUMENT_CONTENT_TYPE_LABELS: { [key in UserDocumentContentType]: string } = {
  'application/pdf': 'Documento PDF',
}

type ProviderType = 'ADMIN' | 'USER'

export type UserDocumentType = 'LEGAL' | 'OPERATIONAL'

export const USER_DOCUMENT_TYPE_LABELS: { [key in UserDocumentType]: string } = {
  'LEGAL': 'Documento legal',
  'OPERATIONAL': 'Documento operacional',
}

export type UserDocument = {
  id: string
  documentType: string
  contentType: string
  description: string
  timestamp: number
  storagePost: StoragePost
}

export const USER_DOCUMENT_FIELDS = gql`
  ${STORAGE_POST_FIELDS}
  fragment UserDocumentFields on UserDocument {
    id
    documentType
    contentType
    description
    timestamp
    storagePost {
      ...StoragePostFields
    }
  }
`

export type UserDocumentView = {
  id: string
  documentType: UserDocumentType
  contentType: UserDocumentContentType
  description: string
  provider: ProviderType
  timestamp: number
  storageUrl?: string
}

export const USER_DOCUMENT_VIEW_FIELDS = gql`
  fragment UserDocumentViewFields on UserDocumentView {
    id
    documentType
    contentType
    description
    provider
    timestamp
    storageUrl
  }
`

export type UserDocumentsVars = {
  endDate: string
  startDate: string
}

export type UserDocumentsData = {
  userDocuments: UserDocumentView[]
}

export const USER_DOCUMENTS_QUERY = gql`
  ${USER_DOCUMENT_VIEW_FIELDS}
  query UserDocuments (
    $endDate: ISO8601DateTime!
    $startDate: ISO8601DateTime!
  ) {
    userDocuments (
      endDate: $endDate
      startDate: $startDate
    ) {
      ...UserDocumentViewFields
    }
  }
`

export type CreateUserDocumentVars = {
  contentType: string
  description: string
  documentType: string
  timestamp: string
}

export type CreateUserDocumentData = {
  createUserDocument: UserDocument
}

export const CREATE_USER_DOCUMENT_MUTATION = gql`
  ${USER_DOCUMENT_FIELDS}
  mutation CreateUserDocument(
    $contentType: String!
    $description: String!
    $documentType: String!
    $timestamp: ISO8601DateTime!
  ) {
    createUserDocument(
      input: {
        contentType: $contentType
        description: $description
        documentType: $documentType
        timestamp: $timestamp
      }
    ) {
      ...UserDocumentFields
    }
  }
`

export type UpdateUserDocumentVars = {
  userDocumentId: string
}

export type UpdateUserDocumentData = {
  updateUserDocument: string
}

export const UPDATE_USER_DOCUMENT_MUTATION = gql`
  mutation UpdateUserDocument(
    $userDocumentId: ID!
  ) {
    updateUserDocument(
      input: {
        userDocumentId: $userDocumentId
      }
    )
  }
`

export type DeleteUserDocumentVars = {
  userDocumentId: string
}

export type DeleteUserDocumentData = {
  deleteUserDocument: string
}

export const DELETE_USER_DOCUMENT_MUTATION = gql`
  mutation DeleteUserDocument(
    $userDocumentId: ID!
  ) {
    deleteUserDocument(
      input: {
        userDocumentId: $userDocumentId
      }
    )
  }
`
