import { gql } from '@apollo/client'

export type BankAccountType = 'SIGHT' | 'CHECKING'

export const BANK_ACCOUNT_TYPE_LABELS: { [key in BankAccountType]: string } = {
  'SIGHT': 'Cuenta vista',
  'CHECKING': 'Cuenta corriente',
}

export type BankAccount = {
  id: string
  accountType: BankAccountType
  bankCode: string
  number: string
}

export const BANK_ACCOUNT_FIELDS = gql`
  fragment BankAccountFields on BankAccount {
    id
    accountType
    bankCode
    number
  }
`

export type BankAccountsVars = Record<string, never>

export type BankAccountsData = {
  bankAccounts: BankAccount[]
}

export const BANK_ACCOUNTS_QUERY = gql`
  ${BANK_ACCOUNT_FIELDS}
  query BankAccounts {
    bankAccounts {
      ...BankAccountFields
    }
  }
`

export type CreateBankAccountVars = {
  accountType: string
  bankCode: string
  number: string
}

export type CreateBankAccountData = {
  createBankAccount: string
}

export const CREATE_BANK_ACCOUNT_MUTATION = gql`
  mutation CreateBankAccount(
    $accountType: String!
    $bankCode: String!
    $number: String!
  ) {
    createBankAccount(
      input: {
        accountType: $accountType
        bankCode: $bankCode
        number: $number
      }
    )
  }
`

export type DeleteBankAccountVars = {
  bankAccountId: string
}

export type DeleteBankAccountData = {
  deleteBankAccount: string
}

export const DELETE_BANK_ACCOUNT_MUTATION = gql`
  mutation DeleteBankAccount(
    $bankAccountId: ID!
  ) {
      deleteBankAccount(
        input: {
          bankAccountId: $bankAccountId
        }
      )
   }
`
