import * as React from 'react'

import { Divider, MenuItem, Stack, Typography } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { Select } from 'formik-mui'
import { MobileDatePicker } from 'formik-mui-x-date-pickers'
import * as Yup from 'yup'

import { DocumentUpload } from '../components'
import {
  BUSINESS_CONTENT_TYPES,
  BUSINESS_DOCUMENT_TYPE_LABELS,
  PERSON_CONTENT_TYPES,
} from '../queries'
import {
  hasValidContentType,
  hasValidSize,
  minPoiDate,
} from '../services'

export type DocumentType = 'ID_CARD' | 'PASSPORT' | 'COMPANY_CONSTITUTION'

export type IdentityProofsFormValues = {
  documentType: string
  expirationDate: Date | null
  frontImage: File | undefined
  backImage: File | undefined
}

const januaryFirst2050 = new Date(2524608000000)

export const getIdentityProofsInitialValues = (isBusiness: boolean): IdentityProofsFormValues => ({
  documentType: isBusiness ? 'COMPANY_CONSTITUTION' : '',
  expirationDate: isBusiness ? januaryFirst2050 : null,
  frontImage: undefined,
  backImage: undefined,
})

export const identityProofsValidationSchema = (isBusiness: boolean) => (
  Yup.object().shape({
    documentType: Yup.string()
      .required('Este campo es obligatorio'),
    expirationDate: Yup.date()
      .typeError('La fecha ingresada es inválida')
      .min(minPoiDate(), 'La fecha ingresada debe ser en el futuro')
      .required('Este campo es obligatorio'),
    frontImage: Yup.mixed()
      .required('Este campo es obligatorio')
      .test(
        'fileType',
        `Debes subir un archivo ${isBusiness ? 'PDF' : 'HEIC/JPG/PNG'}`,
        (value) => hasValidContentType(isBusiness, value),
      )
      .test('fileSize', 'Tu archivo supera el tamaño máximo de 16 MB', hasValidSize),
    backImage: Yup.mixed()
      .when('documentType', {
        is: 'ID_CARD',
        then: Yup.mixed().required('Este campo es obligatorio'),
      })
      .test(
        'fileType',
        `Debes subir un archivo ${isBusiness ? 'PDF' : 'HEIC/JPG/PNG'}`,
        (value) => hasValidContentType(isBusiness, value),
      )
      .test('fileSize', 'Tu archivo supera el tamaño máximo de 16 MB', hasValidSize),
  })
)

const PERSON_DOCUMENT_TYPES = [
  {
    label: 'Cédula de identidad',
    value: 'ID_CARD',
  },
  {
    label: 'Pasaporte',
    value: 'PASSPORT',
  },
]

export const PersonIdentityProofsFields = () => {
  const { values } = useFormikContext<IdentityProofsFormValues>()
  const isIdCard = values.documentType === 'ID_CARD'

  return (
    <Stack spacing={3}>
      <Field
        required
        name='documentType'
        type='text'
        label='Tipo de documento'
        component={Select}
      >
        {PERSON_DOCUMENT_TYPES.map(({ label, value }) => (
          <MenuItem
            key={value}
            value={value}
          >
            {label}
          </MenuItem>
        ))}
      </Field>
      <Field
        required
        name='expirationDate'
        type='date'
        label='Fecha de expiración'
        component={MobileDatePicker}
        minDate={minPoiDate()}
        views={['year', 'month', 'day']}
        inputFormat='yyyy/MM/dd'
        toolbarTitle='Ingresa la fecha de expiración del documento'
        closeOnSelect
      />
      {isIdCard && <Divider>Imagen frontal</Divider>}
      <DocumentUpload
        name='frontImage'
        imageAlt='Imagen Frontal'
        contentTypes={PERSON_CONTENT_TYPES}
        allowWebcam
      />
      {isIdCard && (
        <React.Fragment>
          <Divider>Imagen trasera</Divider>
          <DocumentUpload
            name='backImage'
            imageAlt='Imagen Trasera'
            contentTypes={PERSON_CONTENT_TYPES}
            allowWebcam
          />
        </React.Fragment>
      )}
    </Stack>
  )
}

export const BusinessIdentityProofsFields = () => (
  <Stack spacing={2}>
    <Typography textAlign='center'>
      {BUSINESS_DOCUMENT_TYPE_LABELS['COMPANY_CONSTITUTION']}:
    </Typography>
    <DocumentUpload
      name='frontImage'
      imageAlt='Imagen del documento'
      contentTypes={BUSINESS_CONTENT_TYPES}
    />
  </Stack>
)
