import { gql } from '@apollo/client'

export type UpdateVerificationVars = Record<string, never>

export type UpdateVerificationData = {
  updateVerification: string
}

export const UPDATE_VERIFICATION_MUTATION = gql`
  mutation UpdateVerification {
    updateVerification(input: {})
  }
`
