import * as React from 'react'

import { Alert, Container } from '@mui/material'
import * as faceapi from 'face-api.js'
import Webcam from 'react-webcam'

import { FaceDetectionOverlay, LoadingOverlay, StartDetectionOverlay } from './overlays'

import type { FaceDirection } from './overlays'
import type { AlertColor } from '@mui/material'

const FACE_API_MODELS_URL = 'https://storage.googleapis.com/guita-face-api-models/'

type RecordingState = 'LOADING' | 'READY' | 'DETECTING' | 'RECORDING'

type LivenessProofInputProps = {
  onFinishRecording: (file: File) => Promise<void>
}

export const LivenessProofInput = ({
  onFinishRecording,
}: LivenessProofInputProps) => {
  const [alertText, setAlertText] = React.useState<string>('Preparando el sistema de detección')
  const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>('info')
  const [recordingState, setRecordingState] = React.useState<RecordingState>('LOADING')
  const [faceDirection, setFaceDirection] = React.useState<FaceDirection>('LEFT')

  const webcamRef = React.useRef<Webcam>(null)
  const getScreenshot = () => webcamRef.current?.getScreenshot()

  const startFaceDetection = async () => {
    setAlertText('Ubica tu rostro frente a la cámara')
    setAlertSeverity('info')
    setRecordingState('DETECTING')
    setFaceDirection('LEFT')
    detectFace()
  }

  const detectFace = async () => {
    const imageSrc = getScreenshot()

    if (!imageSrc) {
      console.error('unable to capture webcam screenshot')
      return
    }

    const image = new Image()
    image.src = imageSrc
    await image.decode()

    const detections = await faceapi.detectAllFaces(image)

    if (detections.length === 1) {
      return startRecording()
    } else if (detections.length === 0) {
      setAlertText('Acércate a la cámara por favor')
      setAlertSeverity('warning')
    } else {
      setAlertText('Debe haber solo una persona frente a la cámara')
      setAlertSeverity('warning')
    }

    setTimeout(detectFace, 500)
  }

  const startRecording = () => {
    const stream = webcamRef.current?.stream

    if (!stream?.active) {
      console.error('unable to capture webcam stream')
      return
    }

    setAlertText('Mueve tu cabeza en la dirección indicada')
    setAlertSeverity('info')
    setRecordingState('RECORDING')

    const videoType = MediaRecorder.isTypeSupported('video/mp4') ? 'video/mp4' : 'video/webm'
    const mediaRecorder = new MediaRecorder(stream, { mimeType: videoType })
    const recordedChunks: Blob[] = []

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.push(event.data)
      }
    }

    mediaRecorder.onstop = () => {
      const blob = new Blob(recordedChunks, { type: videoType })
      const file = new File([blob], 'liveness-proof', { type: videoType })
      onFinishRecording(file)
    }

    mediaRecorder.start()
    setTimeout(() => setFaceDirection('RIGHT'), 3000)
    setTimeout(() => mediaRecorder.stop(), 6000)
  }

  React.useEffect(() => {
    const loadModels = async () => {
      await faceapi.nets.ssdMobilenetv1.loadFromUri(FACE_API_MODELS_URL)

      const setReadyState = () => {
        if (getScreenshot()) {
          setAlertText('Presiona el botón para comenzar')
          setRecordingState('READY')
        } else {
          setTimeout(setReadyState, 500)
        }
      }

      setReadyState()
    }
    loadModels()
  }, [])

  return (
    <React.Fragment>
      <Container
        disableGutters
        sx={{ position: 'relative' }}
      >
        <Webcam
          ref={webcamRef}
          audio={false}
          mirrored={true}
          height={480}
          videoConstraints={{
            facingMode: 'user',
            frameRate: { ideal: 10 },
            height: { ideal: 480 },
            width: { ideal: 640 },
          }}
          style={{ width: '100%' }}
        />
        {(() => {
          switch (recordingState) {
          case 'LOADING': return <LoadingOverlay />
          case 'READY': return <StartDetectionOverlay onClick={startFaceDetection} />
          case 'RECORDING': return <FaceDetectionOverlay direction={faceDirection} />
          }
        })()}
      </Container>
      <Alert severity={alertSeverity}>{alertText}</Alert>
    </React.Fragment>
  )
}
