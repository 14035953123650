import { useQuery } from '@apollo/client'

import { BASE_APYS_QUERY, MARKET_ASSETS_QUERY } from '../queries'

import type {
  BaseApy,
  BaseApysData,
  BaseApysVars,
  MarketAsset,
  MarketAssetsData,
  MarketAssetsVars,
} from '../queries'

export type BaseApysMap = { [key: string]: number }

export const mapBaseApys = (baseApys: BaseApy[]): BaseApysMap =>
  baseApys.reduce((acc, baseApy) => ({ ...acc, [baseApy.marketAssetId]: baseApy.value }), {})

export const hasApy = (baseApys: BaseApy[]) =>
  baseApys.reduce((acc, baseApy) => acc + baseApy.value, 0) > 0

export const hasApyWithSymbol = (
  baseApys: BaseApy[],
  marketAssets: MarketAsset[],
  symbol: string,
) => {
  const marketAsset = marketAssets.find((marketAsset) => marketAsset.symbol === symbol)

  if (!marketAsset) {
    return false
  }

  const baseApy = baseApys.find((baseApy) => baseApy.marketAssetId === marketAsset.id)

  return !!baseApy && baseApy.value > 0
}

export const useHasApyWithSymbol = (symbol: string) => {
  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: baseApysLoading, data: baseApysData } =
    useQuery<BaseApysData, BaseApysVars>(BASE_APYS_QUERY)

  const marketAssets = assetsData?.marketAssets || []
  const baseApys = baseApysData?.baseApys || []

  return {
    loading: assetsLoading || baseApysLoading,
    hasApy: hasApyWithSymbol(baseApys, marketAssets, symbol),
  }
}
