import * as React from 'react'

import { Skeleton, Typography, styled } from '@mui/material'

import { percentFormatter } from '../../services'

const LightContainer = styled('span')({
  display: 'inline-block',
  position: 'relative',
  top: '.125em',
  left: '.125em',
  width: '1em',
  height: '1em',
})

const Light = styled('span')(({ theme }) => ({
  '@keyframes pulsate': {
    '0%': {
      opacity: 0.33,
      boxShadow: 'none',
    },
    '50%': {
      opacity: 1,
      boxShadow: [
        `0 0 5px ${theme.palette.common.white}`,
        `0 0 10px ${theme.palette.common.white}`,
        `0 0 2px ${theme.palette.common.white}`,
      ].join(','),
    },
    '100%': {
      opacity: 0.33,
      boxShadow: 'none',
    },
  },
  animation: 'pulsate 4s ease-out infinite',
  position: 'absolute',
  top: '25%',
  left: '25%',
  width: '50%',
  height: '50%',
  borderRadius: '50%',
  backgroundColor: theme.palette.common.white,
}))

type ApyDisplayProps = {
  sadMode: boolean
  baseApy: number | null
}

export const ApyDisplay = ({
  sadMode,
  baseApy,
}: ApyDisplayProps) => (baseApy !== null && baseApy > 0) ? (
  <Typography
    variant='body1'
    fontWeight={500}
    sx={(theme) => ({
      px: 2,
      py: 1,
      borderRadius: 2,
      lineHeight: 1,
      bgcolor: sadMode ? 'action.disabled' : 'primary.light',
      color: sadMode ? 'text.primary' : theme.palette.getContrastText(theme.palette.primary.light),
      boxShadow: theme.shadows[1],
    })}
  >
    {(baseApy !== null) ? (
      <React.Fragment>
        {percentFormatter.format(baseApy / 100.0)}
        <LightContainer>
          {!sadMode && <Light />}
        </LightContainer>
        <br />
      </React.Fragment>
    ) : (
      <Skeleton />
    )}
    <span style={{ fontSize: '.75em', fontWeight: 400 }}>
      Recompensa anual
    </span>
  </Typography>
) : (
  null
)
