import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Button, Stack } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  ErrorDisplay,
  Loading,
} from '../../../components'
import {
  BusinessIdentityFields,
  PhoneNumberFields,
  businessIdentityValidationSchema,
  getBusinessIdentityInitialValues,
  getPhoneNumberInitialValues,
  phoneNumberValidationSchema,
} from '../../../forms'
import {
  BUSINESS_IDENTITY_QUERY,
  PHONE_NUMBER_QUERY,
  UPDATE_BUSINESS_IDENTITY_MUTATION,
} from '../../../queries'
import { setFormError, translateError } from '../../../services'

import type { PhoneNumberFormValues } from '../../../forms'
import type {
  BusinessIdentity,
  BusinessIdentityData,
  BusinessIdentityVars,
  PhoneNumber,
  PhoneNumberData,
  PhoneNumberVars,
  UpdateBusinessIdentityData,
  UpdateBusinessIdentityVars,
} from '../../../queries'
import type { FormikProps } from 'formik'

type FormValues = PhoneNumberFormValues & {
  companyName: string
  identificationNumber: string
  siiActivityCode: string
  societyKind: string
  societyType: string
}

const getInitialValues = (
  businessIdentityData?: BusinessIdentity,
  phoneNumberData?: PhoneNumber,
): FormValues => ({
  ...getBusinessIdentityInitialValues(businessIdentityData || undefined),
  ...getPhoneNumberInitialValues(phoneNumberData || undefined),
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    ...businessIdentityValidationSchema.fields,
    ...phoneNumberValidationSchema.fields,
  })

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
}: FormikProps<FormValues>) => (
  <Form>
    <Stack spacing={3}>
      <BusinessIdentityFields />
      <PhoneNumberFields />
      <ErrorDisplay errorMsg={status?.errorMsg} />
    </Stack>
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type BusinessIdentityStepProps = {
  handleNext: () => void
}

export const BusinessIdentityStep = ({
  handleNext,
}: BusinessIdentityStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const { loading: businessIdentityLoading, data: businessIdentityData } =
    useQuery<BusinessIdentityData, BusinessIdentityVars>(BUSINESS_IDENTITY_QUERY)

  const { loading: phoneNumberLoading, data: phoneNumberData } =
    useQuery<PhoneNumberData, PhoneNumberVars>(PHONE_NUMBER_QUERY)

  const [updateBusinessIdentityStep] =
    useMutation<UpdateBusinessIdentityData, UpdateBusinessIdentityVars>(
      UPDATE_BUSINESS_IDENTITY_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          BUSINESS_IDENTITY_QUERY,
          PHONE_NUMBER_QUERY,
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateBusinessIdentityStep({
      variables: {
        companyName: values.companyName,
        identificationNumber: values.identificationNumber,
        siiActivityCode: values.siiActivityCode,
        societyKind: values.societyKind,
        societyType: values.societyType,
        phoneNumber: values.phoneNumber,
      },
    })

    if (response.data?.updateBusinessIdentity && response.data?.updatePhoneNumber) {
      return handleNext()
    }

    setFormError(formRef, translateError(response))
  }

  return (businessIdentityLoading || phoneNumberLoading) ? (
    <Loading />
  ) : (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(
          businessIdentityData?.businessIdentity,
          phoneNumberData?.phoneNumber,
        )}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => <StepForm {...props} />}
      </Formik>
    </LocalizationProvider>
  )
}
