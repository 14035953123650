import * as React from 'react'

import { Warning } from '@mui/icons-material'
import {
  FormControl,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { navigate } from '@reach/router'

import {
  AppContainer,
  Currency,
  CurrencySkeleton,
  GridDivider,
  OperationsList,
  SennaBankTransferDialog,
} from '../components'
import { BaseCurrencyField } from '../forms'
import { getTotalAmountLeft, withJsTimestamp } from '../services'

import type { CurrencyProps } from '../components'
import type { BulkPurchase, BulkPurchasesOverview, UserOperation } from '../queries'

type BalanceProps = {
  descriptionText: string
  loading: boolean
  currency: string
  digits?: number
  value: number
}

const Balance = ({
  descriptionText,
  loading,
  currency,
  digits,
  value,
}: BalanceProps) => (
  <AppContainer
    sm={12}
    md={6}
    sx={{ p: 3, backgroundColor: 'primary.dark', color: 'common.white' }}
  >
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
    >
      <Typography
        variant='h4'
        component='p'
        fontWeight={600}
        lineHeight='1.2'
        textAlign='center'
      >
        {loading ? (
          <CurrencySkeleton animation='wave' />
        ) : (
          <Currency
            currency={currency}
            digits={digits}
            value={value}
          />
        )}
      </Typography>
    </Stack>
    <Typography
      variant='h6'
      component='p'
      textAlign='center'
    >
      {descriptionText}
    </Typography>
  </AppContainer>
)

type CreditAvailableBannerProps = CurrencyProps

const CreditAvailableBanner = ({
  currency,
  digits,
  value,
}: CreditAvailableBannerProps) => (
  <AppContainer
    sm={12}
    sx={{
      px: 2,
      py: 1.5,
      bgcolor: 'primary.main',
      color: 'primary.contrastText',
    }}
  >
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
      spacing={1}
    >
      <Warning fontSize='small' />
      <Typography
        textAlign='center'
        lineHeight={1}
      >
        Existe un saldo a crédito de
        {' '}
        <Currency
          currency={currency}
          digits={digits}
          value={value}
        />
      </Typography>
    </Stack>
  </AppContainer>
)

type CalcNextDepositAmountPorps = {
  bulkPurchases: BulkPurchase[]
  withBankTransferDialog?: boolean
}

const CalcNextDepositAmount = ({
  bulkPurchases,
  withBankTransferDialog,
}: CalcNextDepositAmountPorps) => {
  const [depositAmount, setDepositAmount] = React.useState<number | undefined>(0)
  const [creditAmount, setCreditAmount] = React.useState(0)
  const [purchaseAmount, setPurchaseAmount] = React.useState(0)

  React.useEffect(() => {
    if (typeof depositAmount === 'undefined') {
      setCreditAmount(0)
      setPurchaseAmount(0)
      return
    }

    let available = depositAmount
    let purchased = 0

    bulkPurchases.forEach((bulkPurchase) => {
      const purchaseAmount = Math.min(available, bulkPurchase.amountLeft)
      purchased += purchaseAmount / bulkPurchase.purchasePrice
      available -= purchaseAmount
    })

    setPurchaseAmount(purchased)
    setCreditAmount(available)
  }, [bulkPurchases, depositAmount])

  return (
    <React.Fragment>
      <AppContainer
        sm={12}
        md={6}
        sx={{
          p: 3,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography
          variant='h6'
          textAlign='center'
        >
          Simular compra
        </Typography>
        <FormControl fullWidth>
          <BaseCurrencyField
            label='Monto a depositar'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <small>CLP</small>&nbsp;$
                </InputAdornment>
              ),
            }}
            onChange={setDepositAmount}
            value={depositAmount}
            digits={0}
            positive
          />
        </FormControl>
        {withBankTransferDialog && <SennaBankTransferDialog />}
      </AppContainer>
      <AppContainer
        sm={12}
        md={6}
        sx={{ p: 3, height: '100%' }}
      >
        <List>
          <ListItem
            disableGutters
            divider
          >
            <ListItemText primary='Total a recibir' />
            <ListItemText
              sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}
              primary={(
                <Currency
                  currency='USDT'
                  digits={2}
                  value={purchaseAmount}
                />
              )}
            />
          </ListItem>
          <ListItem
            disableGutters
            divider
          >
            <ListItemText primary='Precio promedio' />
            <ListItemText
              sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}
              primary={(
                <Currency
                  currency='CLP/USDT'
                  digits={2}
                  value={
                    (typeof depositAmount !== 'undefined' && purchaseAmount !== 0)
                      ? ((depositAmount - creditAmount) / purchaseAmount)
                      : 0
                  }
                />
              )}
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary='Excedente' />
            <ListItemText
              sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}
              primary={(
                <Currency
                  currency='CLP'
                  digits={0}
                  value={creditAmount}
                />
              )}
            />
          </ListItem>
        </List>
      </AppContainer>
    </React.Fragment>
  )
}

type BulkPurchasesDashboardProps = {
  loading: boolean
  bulkPurchasesOverview?: BulkPurchasesOverview
  withBankDepositSimulator?: boolean
  withBankTransferDialog?: boolean
  withUserId?: boolean
}

export const BulkPurchasesDashboard = ({
  loading,
  bulkPurchasesOverview,
  withBankDepositSimulator,
  withBankTransferDialog,
  withUserId,
}: BulkPurchasesDashboardProps) => {
  const openBulkPurchases = bulkPurchasesOverview?.openBulkPurchases || []
  const unspentBankDeposits = bulkPurchasesOverview?.unspentBankDeposits || []
  const withdrawableAmount = bulkPurchasesOverview?.withdrawableAmount || 0

  const debitAmount = getTotalAmountLeft(openBulkPurchases)
  const creditAmount = getTotalAmountLeft(unspentBankDeposits)

  const handleOpClick = withUserId
    ? ((op: UserOperation) => navigate(`users/${op.userId}/bulk-purchases`))
    : undefined

  return (
    <Grid
      container
      spacing={3}
      pb={12}
    >
      <Balance
        loading={loading}
        descriptionText='Disponible para retiro'
        currency='USDT'
        digits={2}
        value={withdrawableAmount}
      />
      <Balance
        loading={loading}
        descriptionText='Pendiente a depositar'
        currency='CLP'
        digits={0}
        value={debitAmount}
      />
      {(creditAmount > 0) && (
        <CreditAvailableBanner
          currency='CLP'
          digits={0}
          value={creditAmount}
        />
      )}
      {withBankDepositSimulator && (
        <CalcNextDepositAmount
          bulkPurchases={openBulkPurchases.slice().sort((a, b) => a.timestamp - b.timestamp)}
          withBankTransferDialog={withBankTransferDialog}
        />
      )}
      {(creditAmount > 0) && (
        <React.Fragment>
          <GridDivider>Depósitos bancarios con crédito disponible</GridDivider>
          <OperationsList
            loading={loading}
            onClick={handleOpClick}
            operations={withJsTimestamp(unspentBankDeposits)}
            withUserId={withUserId}
          />
        </React.Fragment>
      )}
      <GridDivider>Órdenes abiertas</GridDivider>
      <OperationsList
        loading={loading}
        onClick={handleOpClick}
        operations={withJsTimestamp(openBulkPurchases)}
        withUserId={withUserId}
      />
    </Grid>
  )
}
