import { gql } from '@apollo/client'

export type BulkPurchaseQuote = {
  id: string
  price: number
}

export const BULK_PURCHASE_QUOTE_FIELDS = gql`
  fragment BulkPurchaseQuoteFields on BulkPurchaseQuote {
    id
    price
  }
`

export type BulkPurchaseQuoteVars = Record<string, never>

export type BulkPurchaseQuoteData = {
  bulkPurchaseQuote: BulkPurchaseQuote
}

export const BULK_PURCHASE_QUOTE_QUERY = gql`
  ${BULK_PURCHASE_QUOTE_FIELDS}
  query BulkPurchaseQuote {
    bulkPurchaseQuote {
      ...BulkPurchaseQuoteFields
    }
  }
`
