import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Button, Stack } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  ErrorDisplay,
  Loading,
} from '../../components'
import {
  BaseIdentityFields,
  BooleanField,
  baseIdentityValidationSchema,
  getBaseIdentityInitialValues,
} from '../../forms'
import {
  PERSON_IDENTITY_QUERY,
  UPDATE_PERSON_IDENTITY_MUTATION,
} from '../../queries'
import {
  booleanToString,
  setFormError,
  stringToBoolean,
  toISO8601Date,
  translateError,
} from '../../services'

import type { BaseIdentityFormValues } from '../../forms'
import type {
  PersonIdentity,
  PersonIdentityData,
  PersonIdentityVars,
  UpdatePersonIdentityData,
  UpdatePersonIdentityVars,
} from '../../queries'
import type { FormikProps } from 'formik'
import type { ExecutionResult } from 'graphql'

type FormValues = {
  isPoliticallyExposed: string
} & BaseIdentityFormValues

const getInitialValues = (data: PersonIdentity | undefined): FormValues => ({
  isPoliticallyExposed: booleanToString(data?.isPoliticallyExposed),
  ...getBaseIdentityInitialValues(data),
})

const getMutationVariables = (data: FormValues): UpdatePersonIdentityVars => ({
  birthdate: toISO8601Date(data.birthdate),
  familyName: data.familyName,
  givenName: data.givenName,
  identificationNumber: data.identificationNumber || '',
  nationality: data.nationality,
  isPoliticallyExposed: stringToBoolean(data.isPoliticallyExposed),
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    isPoliticallyExposed: Yup.string()
      .oneOf(['true', 'false'], 'Debe seleccionar una opción')
      .required('Debe seleccionar una respuesta'),
    ...baseIdentityValidationSchema.fields,
  })

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
}: FormikProps<FormValues>) => (
  <Form>
    <Stack spacing={3}>
      <BaseIdentityFields />
      <BooleanField
        label='¿Usted es o ha sido una Persona Expuesta Políticamente (PEP)?'
        name='isPoliticallyExposed'
      />
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type PersonIdentityStepProps = {
  handleNext: () => void
}

export const PersonIdentityStep = ({
  handleNext,
}: PersonIdentityStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const { loading, data } =
    useQuery<PersonIdentityData, PersonIdentityVars>(PERSON_IDENTITY_QUERY)

  const [updatePersonIdentity] =
    useMutation<UpdatePersonIdentityData, UpdatePersonIdentityVars>(
      UPDATE_PERSON_IDENTITY_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          PERSON_IDENTITY_QUERY,
        ],
      })

  const handleResponse = (response: ExecutionResult, data?: string) => {
    if (data === 'OK!') {
      return handleNext()
    }

    setFormError(formRef, translateError(response))
  }

  const handleSubmit = async (values: FormValues) => {
    const variables = getMutationVariables(values)

    const response = await updatePersonIdentity({ variables })
    handleResponse(response, response.data?.updatePersonIdentity)
  }

  return loading ? (
    <Loading />
  ) : (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(data?.personIdentity)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => <StepForm {...props} />}
      </Formik>
    </LocalizationProvider>
  )
}
