import { gql } from '@apollo/client'

export type AssetPrice = {
  symbol: string
  timestamp: number
  value: number
}

const ASSET_PRICE_FIELDS = gql`
  fragment AssetPriceFields on AssetPrice {
    symbol
    timestamp
    value
  }
`

export type ShortRange = '24h' | '7d'

export type LongRange = '24h' | '7d' | '30d' | '1y' | 'all'

export type AssetsPricesVars = {
  quoteSymbol: string
  symbols: string[]
  range: LongRange
}

export type AssetsPricesData = {
  assetsPrices: AssetPrice[]
}

export const ASSETS_PRICES_QUERY = gql`
  ${ASSET_PRICE_FIELDS}
  query AssetsPrices(
    $quoteSymbol: String!
    $symbols: [String!]!
    $range: String!
  ) {
    assetsPrices(
      quoteSymbol: $quoteSymbol
      symbols: $symbols
      range: $range
    ) {
      ...AssetPriceFields
    }
  }
`
