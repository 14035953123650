import { gql } from '@apollo/client'

export type AllowedBlockchain = 'Tron' | 'Solana' | 'BinanceSmartChain'

export const ALLOWED_BLOCKCHAINS: { [key in AllowedBlockchain]: string } = {
  Tron: 'Tron (TRC20)',
  Solana: 'Solana',
  BinanceSmartChain: 'Binance Smart Chain (BEP20)',
}

export type WithdrawalAddress = {
  id: string
  value: string
  blockchain: string
}

export const WITHDRAWAL_ADDRESS_FIELDS = gql`
  fragment WithdrawalAddressFields on WithdrawalAddress {
    id
    value
    blockchain
  }
`

export type WithdrawalAddressesVars = Record<string, never>

export type WithdrawalAddressesData = {
  withdrawalAddresses: WithdrawalAddress[]
}

export const WITHDRAWAL_ADDRESSES_QUERY = gql`
  ${WITHDRAWAL_ADDRESS_FIELDS}
  query WithdrawalAddress {
    withdrawalAddresses {
      ...WithdrawalAddressFields
    }
  }
`

export type UpdateWithdrawalAddressVars = {
  blockchain: string
  otp: string
  value: string
}

export type UpdateWithdrawalAddressData = {
  updateWithdrawalAddress: string
}

export const UPDATE_WITHDRAWAL_ADDRESS_MUTATION = gql`
  mutation UpdateWithdrawalAddress(
    $blockchain: String!
    $otp: String!
    $value: String!
  ) {
    updateWithdrawalAddress(
      input: {
        blockchain: $blockchain
        otp: $otp
        value: $value
      }
    )
  }
`
