import { gql } from '@apollo/client'

export type RampConfigVars = Record<string, never>

export type RampConfigData = {
  rampConfig: {
    canDeposit: boolean
    canWithdraw: boolean
    isVerified: boolean
    userFiatCurrency: string
  }
}

export const RAMP_CONFIG_QUERY = gql`
  query RampConfig {
    rampConfig {
      canDeposit
      canWithdraw
      isVerified
      userFiatCurrency
    }
  }
`
