import * as React from 'react'

import { Switch, styled } from '@mui/material'

import { ColorModeContext, FirebaseContext } from '../contexts'
import IconMoon from '../images/theme_palette_switch/icon-moon.svg'
import IconSun from '../images/theme_palette_switch/icon-sun.svg'

const inputProps = { 'aria-label': 'cambiar la paleta de colores' }

const PaletteSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 22,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: theme.palette.primary.contrastText,
      transform: 'translateX(18px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url(${IconMoon})`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.main
        : theme.palette.primary.dark,
    width: 20,
    height: 20,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${IconSun})`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.primary.light,
  },
}))

export const ThemePaletteSwitch = () => {
  const { colorMode, setColorMode } = React.useContext(ColorModeContext)
  const { analytics, logEvent } = React.useContext(FirebaseContext)

  if (!colorMode) {
    return null
  }

  const checkedFromMode = colorMode === 'dark'
  const modeFromChecked = (checked: boolean) => checked ? 'dark' : 'light'

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMode = modeFromChecked(event.target.checked)
    setColorMode(newMode)

    if (!analytics) {
      return
    }

    logEvent(analytics, 'toggle_color_mode', { to: newMode })
  }

  return (
    <PaletteSwitch
      checked={checkedFromMode}
      onChange={handleChange}
      inputProps={inputProps}
    />
  )
}
