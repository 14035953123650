import * as React from 'react'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import { BooleanField } from './boolean_field'
import { SelectField } from './select_field'
import { TOP_MANAGEMENT_ROLE_LABELS } from '../queries'
import {
  SORTED_COUNTRIES,
  booleanToString,
  hasValidRut,
  stringToBoolean,
  withoutTopManagementRoles,
} from '../services'

import type {
  CreateUltimateBeneficialOwnerVars,
  UltimateBeneficialOwner,
} from '../queries'
import type { SelectChangeEvent } from '@mui/material'

export type UltimateBeneficialOwnerFormValues = {
  givenName: string
  familyName: string
  identificationNumber: string
  nationality: string
  isChileanResident: string
  isPoliticallyExposed: string
  countryCode: string
  city: string
  streetAddress: string
  roles: string[]
  participationPercentage?: number
}

export const getUltimateBeneficialOwnerInitialValues = (
  data: UltimateBeneficialOwner | undefined,
): UltimateBeneficialOwnerFormValues => ({
  givenName: data?.givenName || '',
  familyName: data?.familyName || '',
  identificationNumber: data?.identificationNumber || '',
  nationality: data?.nationality || '',
  isChileanResident: booleanToString(data?.isChileanResident),
  isPoliticallyExposed: booleanToString(data?.isPoliticallyExposed),
  countryCode: data?.countryCode || '',
  city: data?.city || '',
  streetAddress: data?.streetAddress || '',
  roles: data?.roles || [],
  participationPercentage: data?.participationPercentage || 0,
})

export const getUltimateBeneficialOwnerMutationVars = (
  values: UltimateBeneficialOwnerFormValues,
): CreateUltimateBeneficialOwnerVars => ({
  ...values,
  isChileanResident:
    values.isChileanResident ? stringToBoolean(values.isChileanResident) : true,
  isPoliticallyExposed:
    values.isPoliticallyExposed ? stringToBoolean(values.isPoliticallyExposed) : true,
})

export const ultimateBeneficialOwnerValidationSchema: Yup.SchemaOf<
  UltimateBeneficialOwnerFormValues
> = Yup.object().shape({
  givenName: Yup.string()
    .required('Este campo es obligatorio'),
  familyName: Yup.string()
    .required('Este campo es obligatorio'),
  identificationNumber: Yup.string()
    .required('Este campo es obligatorio')
    .test({
      message: 'El RUT ingresado no es válido',
      test: (documentNumber, context) => {
        if (context.parent.isChileanResident === 'true') {
          return hasValidRut(documentNumber)
        }
        return true
      },
    }),
  nationality: Yup.string()
    .required('Este campo es obligatorio'),
  isChileanResident: Yup.string()
    .oneOf(['true', 'false'], 'Este campo es obligatorio')
    .required('Este campo es obligatorio'),
  isPoliticallyExposed: Yup.string()
    .oneOf(['true', 'false'], 'Este campo es obligatorio')
    .required('Este campo es obligatorio'),
  countryCode: Yup.string()
    .required('Este campo es obligatorio'),
  city: Yup.string()
    .required('Este campo es obligatorio'),
  streetAddress: Yup.string()
    .required('Este campo es obligatorio'),
  roles: Yup.array()
    .of(Yup.string().required('Este campo es obligatorio'))
    .min(1, 'Debe seleccionar al menos un rol'),
  participationPercentage: Yup.number()
    .when('roles', {
      is: (roles: string[]) => roles.includes('ULTIMATE_BENEFICIARY'),
      then: Yup.number()
        .typeError('Debes ingresar un número')
        .min(10, 'Un beneficiario final posee mínimo 10% de la empresa')
        .max(100, 'No puede poseer mas de 100%')
        .required('Este campo es obligatorio'),
    })
    .when('roles', {
      is: (roles: string[]) => roles.includes('EFFECTIVE_CONTROL'),
      then: Yup.number()
        .typeError('Debes ingresar un número')
        .positive('Debes ingresar un monto mayor a cero')
        .max(10, 'Un control efectivo posee máximo 10% de la empresa')
        .required('Este campo es obligatorio'),
    }),
})

const LabelWithDescription = ({
  title,
  description,
}: { title: string; description: string }) => (
  <React.Fragment>
    <Typography>{title}</Typography>
    <Typography
      color='text.secondary'
      fontSize='small'
    >
      {description}
    </Typography>
  </React.Fragment>
)

const ParticipationPercentageField = () => (
  <Field
    required
    name='participationPercentage'
    type='number'
    label='Porcentaje de participación en la empresa'
    component={TextField}
    inputProps={{
      autoComplete: 'off',
      inputMode: 'numeric',
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position='start'>
          <small>%</small>
        </InputAdornment>
      ),
      onClick: (event: React.MouseEvent<HTMLInputElement>) => {
        event.currentTarget.querySelector('input')?.select()
      },
    }}
    sx={{ flexGrow: 1 }}
  />
)

const TopManagementRoleField = () => {
  const [showTopManagementRole, setShowTopManagementRole] = React.useState(false)
  const [topManagementRole, setTopManagementRole] = React.useState('')

  const { isSubmitting, setFieldValue, values } =
    useFormikContext<UltimateBeneficialOwnerFormValues>()

  return (
    <React.Fragment>
      <FormControlLabel
        disabled={isSubmitting}
        control={<Checkbox />}
        label={(
          <LabelWithDescription
            title='Alta gerencia'
            description={(
              'Persona natural que que ocupa cargos de alta gerencia dentro'
              + ' de la empresa declarante'
            )}
          />
        )}
        onChange={() => {
          setShowTopManagementRole((prev) => !prev)
          setTopManagementRole('')
          setFieldValue('roles', withoutTopManagementRoles(values.roles))
        }}
      />
      {showTopManagementRole && (
        <FormControl fullWidth>
          <InputLabel id='top-management-role-label'>
            Seleccione un cargo de alta gerencia
          </InputLabel>
          <Select
            labelId='top-management-role-label'
            id='top-management-role'
            label='Seleccione un cargo de alta gerencia'
            value={topManagementRole}
            onChange={(event: SelectChangeEvent) => {
              const newRole = event.target.value
              const nonTopManagementRoles = withoutTopManagementRoles(values.roles)
              setTopManagementRole(newRole)
              setFieldValue('roles', [...nonTopManagementRoles, newRole])
            }}
          >
            {Object.entries(TOP_MANAGEMENT_ROLE_LABELS).map(([key, label]) => (
              <MenuItem
                key={key}
                value={key}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </React.Fragment>
  )
}

const RolesFields = () => {
  const { errors, isSubmitting, setFieldValue, touched, values } =
    useFormikContext<UltimateBeneficialOwnerFormValues>()

  const toggleRole = (value: string) => {
    if (values.roles.includes(value)) {
      setFieldValue('roles', values.roles.filter((role) => role !== value))
    } else {
      setFieldValue('roles', values.roles.push(value) && values.roles)
    }
  }

  return (
    <FormControl>
      <Stack spacing={2}>
        <FormControlLabel
          disabled={isSubmitting}
          checked={values.roles.includes('LEGAL_REPRESENTATIVE')}
          control={<Checkbox />}
          label={(
            <LabelWithDescription
              title='Representante legal'
              description={(
                'Persona natural designada para actuar en nombre de la empresa declarante'
              )}
            />
          )}
          onChange={() => toggleRole('LEGAL_REPRESENTATIVE')}
        />
        <FormControlLabel
          disabled={isSubmitting || values.roles.includes('EFFECTIVE_CONTROL')}
          checked={values.roles.includes('ULTIMATE_BENEFICIARY')}
          control={<Checkbox />}
          label={(
            <LabelWithDescription
              title='Beneficiario final'
              description={(
                'Persona natural que tiene una participación en la empresa'
                + ' declarante igual o mayor al 10%'
              )}
            />
          )}
          onChange={() => toggleRole('ULTIMATE_BENEFICIARY')}
        />
        {values.roles.includes('ULTIMATE_BENEFICIARY') && (
          <ParticipationPercentageField />
        )}
        <FormControlLabel
          disabled={isSubmitting || values.roles.includes('ULTIMATE_BENEFICIARY')}
          checked={values.roles.includes('EFFECTIVE_CONTROL')}
          control={<Checkbox />}
          label={(
            <LabelWithDescription
              title='Control efectivo'
              description={(
                'Persona natural que, sin perjuicio de poseer directa o'
                + ' indirectamente una participación inferior al 10%,'
                + ' ejerce el control efectivo de la empresa declarante'
              )}
            />
          )}
          onChange={() => toggleRole('EFFECTIVE_CONTROL')}
        />
        {values.roles.includes('EFFECTIVE_CONTROL') && (
          <ParticipationPercentageField />
        )}
        <TopManagementRoleField />
        <FormHelperText error>
          {touched['roles'] && errors['roles']}
        </FormHelperText>
      </Stack>
    </FormControl>
  )
}

export const UltimateBeneficialOwnerFields = () => {
  const { setFieldValue, values } = useFormikContext<UltimateBeneficialOwnerFormValues>()

  return (
    <Stack spacing={3}>
      <Field
        required
        name='givenName'
        type='text'
        label='Nombre(s)'
        component={TextField}
        fullWidth
      />
      <Field
        required
        name='familyName'
        type='text'
        label='Apellido(s)'
        component={TextField}
        fullWidth
      />
      <SelectField
        required
        name='nationality'
        label='País de origen'
        onChange={(value) => setFieldValue('isChileanResident', value === 'CL' ? 'true' : '')}
      >
        {SORTED_COUNTRIES.map((country) => (
          <MenuItem
            key={country.iso}
            value={country.iso}
          >
            {country.name}
          </MenuItem>
        ))}
      </SelectField>
      {values.nationality !== 'CL' && (
        <BooleanField
          label='¿Tiene residencia en Chile?'
          name='isChileanResident'
        />
      )}
      <Field
        required
        name='identificationNumber'
        type='text'
        label={values.isChileanResident === 'true' ? 'RUT' : 'DNI'}
        component={TextField}
        fullWidth
      />
      <SelectField
        required
        name='countryCode'
        label='País de residencia'
      >
        {SORTED_COUNTRIES.map((country) => (
          <MenuItem
            key={country.iso}
            value={country.iso}
          >
            {country.name}
          </MenuItem>
        ))}
      </SelectField>
      <Field
        required
        name='city'
        type='text'
        label='Ciudad'
        component={TextField}
        margin='normal'
      />
      <Field
        required
        name='streetAddress'
        type='text'
        label='Calle, número, departamento, etc.'
        component={TextField}
        margin='normal'
        minRows={2}
        multiline
      />
      <Paper
        variant='outlined'
        sx={{ padding: 2, mt: 2 }}
      >
        <Typography
          textAlign='center'
          sx={{ mb: 2 }}
        >
          Seleccione las opciones que describan su relación con la empresa
        </Typography>
        <RolesFields />
      </Paper>
      <BooleanField
        label='¿Es o ha sido una Persona Expuesta Políticamente (PEP)?'
        name='isPoliticallyExposed'
      />
    </Stack>
  )
}
