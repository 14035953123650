import * as React from 'react'

import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material'

export const DrawerDivider = styled(Divider)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}))

type DrawerListItemProps = {
  text: string
  href: string
  icon?: React.ReactNode
  color?: string
  selected?: boolean
  component?: React.ElementType
}

export const DrawerListItem = ({
  text,
  href = '',
  icon = undefined,
  color = 'text.primary',
  selected = false,
  component = 'a',
}: DrawerListItemProps) => (
  <ListItemButton
    to={href}
    href={href}
    component={component}
    selected={selected}
    sx={{ borderRadius: 1, color }}
  >
    {icon && (
      <ListItemIcon sx={{ color, minWidth: (theme) => theme.spacing(5) }}>
        {icon}
      </ListItemIcon>
    )}
    <ListItemText primary={text} />
  </ListItemButton>
)

export const DrawerList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  gap: theme.spacing(1),
}))

type MobileDrawerProps = {
  width: number
  open: boolean
  onClose: () => void
  children: React.ReactNode
}

export const MobileDrawer = ({
  width,
  open,
  onClose,
  children,
}: MobileDrawerProps) => (
  <Drawer
    variant='temporary'
    elevation={4}
    open={open}
    onClose={onClose}
    sx={(theme) => ({
      display: {
        xs: 'block',
        sm: 'none',
      },
      '&>.MuiPaper-root': {
        marginTop: `${theme.mixins.toolbar.minHeight}px`,
        boxSizing: 'border-box',
        width: width,
      },
    })}
    ModalProps={{
      keepMounted: true,
    }}
  >
    {children}
  </Drawer>
)

type FixedDrawerProps = {
  width: number
  children: React.ReactNode
}

export const FixedDrawer = ({
  width,
  children,
}: FixedDrawerProps) => (
  <Drawer
    variant='permanent'
    sx={(theme) => ({
      display: {
        xs: 'none',
        sm: 'block',
      },
      '&>.MuiPaper-root': {
        marginTop: `${theme.mixins.toolbar.minHeight}px`,
        boxSizing: 'border-box',
        width: width,
      },
    })}
  >
    {children}
  </Drawer>
)

type ResponsiveDrawerProps = {
  width: number
  mobileOpen: boolean
  mobileOnClose: () => void
  children: React.ReactNode
}

export const ResponsiveDrawer = ({
  width,
  mobileOpen,
  mobileOnClose,
  children,
}: ResponsiveDrawerProps) => {
  return (
    <React.Fragment>
      <MobileDrawer
        width={width}
        open={mobileOpen}
        onClose={mobileOnClose}
      >
        {children}
      </MobileDrawer>
      <FixedDrawer
        width={width}
      >
        {children}
      </FixedDrawer>
    </React.Fragment>
  )
}
