import * as React from 'react'

import { LoadingButton } from '@mui/lab'

import type { LoadingButtonProps } from '@mui/lab'

type LandingCtaButtonProps = LoadingButtonProps & {
  isLoggedIn: () => boolean
}

export const LandingCtaButton = ({
  isLoggedIn,
  ...rest
}: LandingCtaButtonProps) => {
  const [loading, setLoading] = React.useState(true)
  const [href, setHref] = React.useState('/auth/register/')
  const [text, setText] = React.useState('Empezar')

  React.useEffect(() => {
    if (isLoggedIn()) {
      setHref('/app/')
      setText('Ingresar')
    }
    setLoading(false)
  }, [isLoggedIn])

  return (
    <LoadingButton
      variant='contained'
      href={href}
      loading={loading}
      aria-label={text}
      {...rest}
    >
      {text}
    </LoadingButton>
  )
}
