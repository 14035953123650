import { PortfolioBalance } from './portfolios'
import { getChangedAmount } from './user_operations'

import type { AssetPrice, UserOperation } from '../queries'

export type GraphPoint = {
  timestamp: number
  value: number
}

const selectAssetPrices = (assetPrices: AssetPrice[], symbol: string) =>
  assetPrices
    .filter((graphPoint) => graphPoint.symbol === symbol)
    .map((point) => ({ ...point, timestamp: point.timestamp * 1000 }))
    .sort((a, b) => a.timestamp - b.timestamp)

const getHighestValue = (graphPoints: GraphPoint[]) =>
  Math.max(...graphPoints.map((point) => point.value))

const getLowestValue = (graphPoints: GraphPoint[]) =>
  Math.min(...graphPoints.map((point) => point.value))

const getLastValue = (graphPoints: GraphPoint[]) =>
  graphPoints[graphPoints.length - 1]?.value

const getLastTimestamp = (graphPoints: GraphPoint[]) =>
  graphPoints[graphPoints.length - 1]?.timestamp

const getVariation = (graphPoints: GraphPoint[]) => {
  const firstPrice = graphPoints[0]?.value

  if (!firstPrice) {
    return 0
  }

  return 100.0 * (getLastValue(graphPoints) - firstPrice) / firstPrice
}

export type GraphData = {
  symbol: string
  points: GraphPoint[]
  ath: number
  atl: number
  lastValue: number | undefined
  lastTimestamp: number | undefined
  variation: number
}

export const getPriceGraphData = (assetPrices: AssetPrice[], symbol: string): GraphData => {
  const selectedAssetPrices = selectAssetPrices(assetPrices, symbol)

  return ({
    symbol,
    points: selectedAssetPrices,
    ath: getHighestValue(selectedAssetPrices),
    atl: getLowestValue(selectedAssetPrices),
    lastValue: getLastValue(selectedAssetPrices),
    lastTimestamp: getLastTimestamp(selectedAssetPrices),
    variation: getVariation(selectedAssetPrices),
  })
}

export const getBalanceGraphData = (
  priceGraphData: GraphData,
  operations: UserOperation[],
): GraphData => {
  const symbol = priceGraphData.symbol
  const points: GraphPoint[] = []

  if (!operations.length || !priceGraphData.points.length) {
    return ({
      symbol,
      points: [],
      ath: 0,
      atl: 0,
      lastValue: undefined,
      lastTimestamp: undefined,
      variation: 0,
    })
  }

  const sortedOperations = operations.sort((a, b) => a.timestamp - b.timestamp)
  let timestampsIndex = 0, previousAmount = 0

  sortedOperations.forEach((operation, index) => {
    const afterOpAmount = previousAmount + 1000 * getChangedAmount(operation, symbol)
    const nextOpTimestamp = sortedOperations[index + 1]?.timestamp

    for (;;) {
      const timestamp = priceGraphData.points[timestampsIndex]?.timestamp

      if (!timestamp || (nextOpTimestamp && nextOpTimestamp < timestamp)) {
        previousAmount = afterOpAmount
        return
      }

      const amount = (timestamp < operation.timestamp ? previousAmount : afterOpAmount) / 1000
      const value = amount * priceGraphData.points[timestampsIndex].value

      points.push({
        timestamp,
        value,
      })
      timestampsIndex++
    }
  })

  return ({
    symbol,
    points,
    ath: getHighestValue(points),
    atl: getLowestValue(points),
    lastValue: getLastValue(points),
    lastTimestamp: getLastTimestamp(points),
    variation: getVariation(points),
  })
}

export type PortfolioGraphData = {
  points: GraphPoint[]
  ath: number
  atl: number
  variation: number
  lastValue: number | undefined
}

export const getPortfolioGraphData =
  (portfolioBalances: PortfolioBalance[]): PortfolioGraphData => {
    const sumByTimestamp: { [key: number]: number } = {}

    portfolioBalances.forEach((portfolio) =>
      portfolio.balanceGraphData.points.forEach(({ timestamp, value }) => {
        if (sumByTimestamp[timestamp]) {
          sumByTimestamp[timestamp] += value
        } else {
          sumByTimestamp[timestamp] = value
        }
      }),
    )

    const points = Object.entries(sumByTimestamp).map(([timestamp, value]) => ({
      symbol: 'portfolio',
      timestamp: Number(timestamp),
      value,
    })).sort((a, b) => a.timestamp - b.timestamp)

    return ({
      points,
      ath: getHighestValue(points),
      atl: getLowestValue(points),
      variation: getVariation(points),
      lastValue: getLastValue(points),
    })
  }
