import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'

import { AppContainer, ButtonContainer, ButtonsContainer, ErrorDisplay } from '../../components'
import {
  UserTypeFields,
  getUserTypeInitialValues as getInitialValues,
  getUserTypeMutationValues as getMutationValues,
  userTypeValidationSchema as validationSchema,
} from '../../forms'
import { ACCOUNT_INFORMATION_QUERY, UPDATE_USER_TYPE_MUTATION } from '../../queries'
import { setFormError, translateError } from '../../services'

import type { UserTypeFormValues as FormValues } from '../../forms'
import type {
  UpdateUserTypeData,
  UpdateUserTypeVars,
} from '../../queries'

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  values,
}: FormikProps<FormValues>) => (
  <Form>
    <UserTypeFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid || !values.userType}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          Siguiente
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

export const UserTypeStep = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [updateUserType] =
    useMutation<UpdateUserTypeData, UpdateUserTypeVars>(UPDATE_USER_TYPE_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        ACCOUNT_INFORMATION_QUERY,
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateUserType({
      variables: getMutationValues(values),
    })

    if (response.data?.updateUserType === 'OK!') {
      return
    }

    setFormError(formRef, translateError(response))
  }

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <Grid container>
        <AppContainer sx={{ p: 3 }}>
          <Typography
            variant='h5'
            component='p'
          >
            Selecciona el tipo de verificación para tu cuenta
          </Typography>
          <Divider sx={{ mt: 2, mb: 3 }} />
          <Formik
            innerRef={formRef}
            initialValues={getInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => <StepForm {...props} />}
          </Formik>
        </AppContainer>
      </Grid>
    </Box>
  )
}
