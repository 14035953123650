import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'

import {
  ButtonContainer,
  ButtonsContainer,
  Dialog,
  ErrorDisplay,
  Loading,
} from '../../components'
import {
  FundsOriginFields,
  getFundsOriginInitialValues as getInitialValues,
  fundsOriginValidationSchema as validationSchema,
} from '../../forms'
import { FUNDS_ORIGIN_QUERY, UPDATE_FUNDS_ORIGIN_MUTATION } from '../../queries'
import { setFormError, translateError } from '../../services'

import type { FundsOriginFormValues as FormValues } from '../../forms'
import type {
  FundsOriginData,
  FundsOriginVars,
  UpdateFundsOriginData,
  UpdateFundsOriginVars,
} from '../../queries'

const SITE_NAME = process.env.GATSBY_SITE_NAME

type StepFormProps = FormikProps<FormValues> & {
  isBusiness: boolean
  handleBack: () => void
}

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  isBusiness,
  handleBack,
}: StepFormProps) => (
  <Form>
    <FundsOriginFields isBusiness={isBusiness} />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={handleBack}
          variant='outlined'
        >
          Atrás
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          Siguiente
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type FundsOriginStepProps = {
  isBusiness: boolean
  handleBack: () => void
  handleNext: () => void
}

export const FundsOriginStep = ({
  isBusiness,
  handleBack,
  handleNext,
}: FundsOriginStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const { loading, data } = useQuery<FundsOriginData, FundsOriginVars>(FUNDS_ORIGIN_QUERY)

  const [updateFundsOrigin] =
    useMutation<UpdateFundsOriginData, UpdateFundsOriginVars>(UPDATE_FUNDS_ORIGIN_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        FUNDS_ORIGIN_QUERY,
      ],
    })

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const handleSubmit = async (values: FormValues) => {
    const response = await updateFundsOrigin({
      variables: values,
    })

    if (response.data?.updateFundsOrigin === 'OK!') {
      return openDialog()
    }

    setFormError(formRef, translateError(response))
  }

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(data?.fundsOrigin)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <StepForm
            isBusiness={isBusiness}
            handleBack={handleBack}
            {...props}
          />
        )}
      </Formik>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title='Confirmar declaración de origen de fondos'
        contentText={(
          <React.Fragment>
            <strong>Los activos</strong> que han sido o serán abonados o depositados a cualquier
            cuenta no provienen, directa ni indirectamente, de actividades ilícitas, y dicho
            abono o depósito no constituye uno de los delitos contemplados en el artículo
            27 de la Ley 19.913, o la que la sustituya o reemplace.
            <br style={{ display: 'block', content: '""', height: '0.5em' }} />
            <strong>Los fondos</strong> no han sido y no serán abonados o depositados, directa o
            indirectamente, por o a través de las siguientes personas (las personas
            prohibidas): Terroristas u organizaciones terroristas, incluyendo aquellas
            personas o entidades indicadas en la lista en idioma inglés denominada Lista
            f Specially Designated Nationals and Blocked Persons emitida por la OFAC del
            Departamento del Tesoro del Gobierno de los Estados Unidos (Treasury Departments
            Oce of Foreign Assets Control). Banco pantalla (Banco sin presencia física).
            <br style={{ display: 'block', content: '""', height: '0.5em' }} />
            <strong>Los fondos</strong> no han sido y no serán abonados o depositados, directa o
            indirectamente, por o a través de Personas Expuestas Políticamente, o que,
            si así fuere el caso, ha entregado información al respecto a {SITE_NAME} para
            que este obtenga las correspondientes autorizaciones para operar, de
            conformidad a lo establecido en la ley N°19.913 que crea la Unidad de Análisis
            Financiero. Se entiende por Personas Expuestas Políticamente, a los individuos
            que desempeñan o han desempeñado funciones públicas destacadas en un país
            extranjero, considerando, por ejemplo, a jefes de estado o de un gobierno,
            políticos de alta jerarquía, funcionarios gubernamentales, judiciales o
            militares de alta jerarquía, altos ejecutivos de empresas estatales,
            funcionarios importantes de partidos políticos, etc., así como sus cónyuges,
            sus parientes hasta el segundo grado de consanguinidad, y las personas naturales
            con las que hayan celebrado un pacto de actuación conjunta, mediante el cual
            tengan poder de voto suficiente para influir en sociedades constituidas en Chile.
          </React.Fragment>
        )}
        contentTextProps={{ textAlign: 'justify' }}
      >
        <ButtonsContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='primary'
              variant='outlined'
              onClick={closeDialog}
            >
              Modificar
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              onClick={handleNext}
            >
              Confirmar
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Dialog>
    </React.Fragment>
  )
}
