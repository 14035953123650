import * as React from 'react'

import { Box, CircularProgress } from '@mui/material'

type LoadingProps = {
  height?: string
}

export const Loading = ({
  height = '5em',
}: LoadingProps) => (
  <Box sx={{ height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <CircularProgress />
  </Box>
)
