import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Info } from '@mui/icons-material'
import {
  Button,
  Divider,
  Stack,
  darken,
  lighten,
  useTheme,
} from '@mui/material'
import { Form, Formik } from 'formik'

import { UltimateBeneficialOwnerDisplay } from './ultimate_beneficial_owner_display'
import {
  ButtonContainer,
  ButtonsContainer,
  Dialog,
  ErrorDisplay,
  IconCard,
  Loading,
} from '../../../components'
import {
  UltimateBeneficialOwnerFields,
  getUltimateBeneficialOwnerInitialValues as getInitialValues,
  getUltimateBeneficialOwnerMutationVars as getMutationVariables,
  ultimateBeneficialOwnerValidationSchema as validationSchema,
} from '../../../forms'
import {
  CREATE_ULTIMATE_BENEFICIAL_OWNER_MUTATION,
  ULTIMATE_BENEFICIAL_OWNERS_QUERY,
} from '../../../queries'
import { setFormError, translateError } from '../../../services'

import type { UltimateBeneficialOwnerFormValues as FormValues } from '../../../forms'
import type {
  CreateUltimateBeneficialOwnerData,
  CreateUltimateBeneficialOwnerVars,
  UltimateBeneficialOwnersData,
  UltimateBeneficialOwnersVars,
} from '../../../queries'
import type { FormikHelpers, FormikProps } from 'formik'

const UltimateBeneficialOwnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
}: FormikProps<FormValues>) => (
  <Form style={{ width: '100%' }}>
    <UltimateBeneficialOwnerFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Agregar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type UltimateBeneficialOwnersStepProps = {
  handleNext: () => void
  handleBack: () => void
}

export const UltimateBeneficialOwnersStep = ({
  handleNext,
  handleBack,
}: UltimateBeneficialOwnersStepProps) => {
  const theme = useTheme()
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const { loading, data } =
    useQuery<UltimateBeneficialOwnersData, UltimateBeneficialOwnersVars>(
      ULTIMATE_BENEFICIAL_OWNERS_QUERY,
    )

  const ultimateBeneficialOwners = data?.ultimateBeneficialOwners || []

  const [createUltimateBeneficialOwner] =
    useMutation<CreateUltimateBeneficialOwnerData, CreateUltimateBeneficialOwnerVars>(
      CREATE_ULTIMATE_BENEFICIAL_OWNER_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          ULTIMATE_BENEFICIAL_OWNERS_QUERY,
        ],
      })

  const handleSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    const variables = getMutationVariables(values)
    const response = await createUltimateBeneficialOwner({ variables })

    if (response.data?.createUltimateBeneficialOwner === 'OK!') {
      closeDialog()
      resetForm()
      return
    }

    setFormError(formRef, translateError(response))
  }

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Stack
        spacing={2}
        mt={1}
      >
        <IconCard
          elevation={0}
          icon={<Info />}
          color={darken(theme.palette.info.light, 0.6)}
          bgcolor={lighten(theme.palette.info.light, 0.8)}
          sx={{ border: 1, borderColor: 'info.dark' }}
        >
          El primer representante legal debe corresponder a la persona que está
          llenando este formulario
        </IconCard>
        {ultimateBeneficialOwners.map((ultimateBeneficialOwner, index) => (
          <React.Fragment key={index}>
            <UltimateBeneficialOwnerDisplay
              ultimateBeneficialOwner={ultimateBeneficialOwner}
            />
            {ultimateBeneficialOwners.length !== index + 1 && <Divider />}
          </React.Fragment>
        ))}
        <Button
          fullWidth
          onClick={openDialog}
          variant='contained'
        >
          Agregar UBO
        </Button>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          pt={2}
        >
          <Button
            fullWidth
            onClick={handleBack}
            variant='outlined'
          >
            Atrás
          </Button>
          <Button
            fullWidth
            disabled={ultimateBeneficialOwners.length < 1}
            onClick={handleNext}
            variant='contained'
          >
            Continuar
          </Button>
        </Stack>
      </Stack>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Agregar UBO'
        maxWidth='xs'
      >
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(undefined)}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => <UltimateBeneficialOwnerForm {...props} />}
        </Formik>
      </Dialog>
    </React.Fragment>
  )
}
