import { gql } from '@apollo/client'

import { AUTHENTICATABLE_FIELDS, USER_CREDENTIAL_FIELDS } from './auth'

import type { Authenticatable, UserCredential } from './auth'

export type GoogleSignInVars = {
  originName: string
  token: string
}

export type GoogleSignInData = {
  googleSignIn: {
    authenticatable?: Authenticatable
    credentials?: UserCredential
    otherProvider?: boolean
  }
}

export const GOOGLE_SIGN_IN_MUTATION = gql`
  ${AUTHENTICATABLE_FIELDS}
  ${USER_CREDENTIAL_FIELDS}
  mutation GoogleSignIn(
    $originName: String!
    $token: String!
  ) {
    googleSignIn(
      input: {
        originName: $originName
        token: $token
      }
    ) {
      authenticatable {
        ...AuthenticatableFields
      }
      credentials {
        ...UserCredentialFields
      }
      otherProvider
    }
  }
`
