import * as React from 'react'

import { Menu } from '@mui/icons-material'
import { AppBar, IconButton, LinearProgress, Stack, styled } from '@mui/material'

import type { Breakpoint, StackProps } from '@mui/material'

const StyledProgress = styled(LinearProgress)(() => ({
  '& .MuiLinearProgress-bar': {
    background: 'var(--color-mainTitleGradient)',
  },
}))

type FixedComponentsProps = {
  navLogo?: React.ReactNode
  onMenuButtonClick?: () => void
  themePaletteSwitch?: React.ReactNode
}

const FixedComponents = ({
  navLogo,
  onMenuButtonClick,
  themePaletteSwitch,
}: FixedComponentsProps) => (
  <Stack
    direction='row'
    alignItems='center'
    justifyContent={themePaletteSwitch ? 'space-between' : 'flex-start'}
    sx={{ flexGrow: 1 }}
  >
    {onMenuButtonClick && (
      <IconButton
        aria-label='open sidebar'
        onClick={onMenuButtonClick}
        sx={{
          display: {
            xs: 'inline-flex',
            sm: 'none',
          },
          marginLeft: -1,
        }}
      >
        <Menu />
      </IconButton>
    )}
    {navLogo}
    {themePaletteSwitch}
  </Stack>
)

export type NavBarProps =  FixedComponentsProps & Pick<StackProps, 'sx'> & {
  bgcolor?: string
  children?: React.ReactNode
  maxWidth?: Breakpoint
  progressValue?: number
  showProgress?: boolean
}

export const NavBar = ({
  bgcolor,
  children,
  maxWidth,
  progressValue,
  showProgress,
  sx = [],
  ...fixedComponentsProps
}: NavBarProps) => (
  <AppBar
    color='inherit'
    position='sticky'
    elevation={1}
    sx={(theme) => ({
      bgcolor,
      zIndex: theme.zIndex.drawer + 1,
    })}
  >
    <Stack
      direction='row'
      alignItems='center'
      maxWidth={maxWidth}
      sx={[
        (theme) => ({
          minHeight: theme.mixins.toolbar.minHeight,
          gap: 2,
          px: 3,
          py: 1,
          mx: 'auto',
          width: '100%',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <FixedComponents {...fixedComponentsProps} />
      {children}
    </Stack>
    {showProgress && (
      <StyledProgress
        variant={(typeof progressValue === 'undefined') ? 'indeterminate' : 'determinate'}
        value={progressValue}
      />
    )}
  </AppBar>
)
