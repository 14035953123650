import { StoragePost } from '../queries'

export const uploadToStorage = (storagePost: StoragePost, file: File) => {
  const form = new FormData()

  storagePost.fieldsKeys.forEach((key, index) => (
    form.append(key, storagePost.fieldsValues[index])
  ))

  form.append('file', file)

  return fetch(storagePost.url, { method: 'POST', body: form })
}
