import { gql } from '@apollo/client'

export type UserNotificationType = 'BULK_PURCHASE'
  | 'DEPOSIT_ADDRESS'
  | 'FUND_PURCHASE'
  | 'FUND_SALE'
  | 'PURCHASE'
  | 'SWAP'
  | 'WITHDRAW_BANK'
  | 'WITHDRAW_BLOCKCHAIN'

export const translateUserNotificationType = (notificationType?: UserNotificationType) => {
  switch (notificationType) {
  case 'BULK_PURCHASE': return 'Orden de compra'
  case 'DEPOSIT_ADDRESS': return 'Dirección de depósito'
  case 'FUND_PURCHASE': return 'Compra cuotas de fondo'
  case 'FUND_SALE': return 'Venta cuotas de fondo'
  case 'PURCHASE': return 'Compra'
  case 'SWAP': return 'Conversión'
  case 'WITHDRAW_BANK': return 'Retiro Bancario'
  case 'WITHDRAW_BLOCKCHAIN': return 'Retiro Blockchain'
  default: return ''
  }
}

export const translateUserNotificationStatus = (resolvedAt?: string | null) => (
  resolvedAt ? 'Resuelta ✅' : 'Pendiente ⚠️'
)

export type UserNotification = {
  id: string
  content: string
  createdAt: string
  notificationType: UserNotificationType
  resolvedAt: string | null
}

export const USER_NOTIFICATION_FIELDS = gql`
  fragment UserNotificationFields on UserNotification {
    id
    content
    createdAt
    notificationType
    resolvedAt
  }
`

export type UserNotificationsVars = Record<string, never>

export type UserNotificationsData = {
  userNotifications: UserNotification[]
}

export const USER_NOTIFICATIONS_QUERY = gql`
  ${USER_NOTIFICATION_FIELDS}
  query UserNotifications {
    userNotifications {
      ...UserNotificationFields
    }
  }
`
