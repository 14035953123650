import * as React from 'react'

import { Divider, Grid } from '@mui/material'

import type { DividerProps } from '@mui/material'

type GridDividerProps = DividerProps

export const GridDivider = ({
  children,
  sx = { my: 2 },
}: GridDividerProps) => (
  <Grid
    item
    xs={12}
  >
    <Divider sx={sx}>{children}</Divider>
  </Grid>
)
