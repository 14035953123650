import * as React from 'react'

import { ContentCopy } from '@mui/icons-material'
import { Button } from '@mui/material'

const PRESS_RESET_MS = 3000

type CopyButtonProps = {
  textToCopy: string
  buttonText: string
  buttonPressedText: string
}

export const CopyButton = ({
  textToCopy,
  buttonText,
  buttonPressedText,
}: CopyButtonProps) => {
  const [pressed, setPressed] = React.useState(false)

  const resetPressed = () => setPressed(false)

  const handleClick = async () => {
    await navigator.clipboard.writeText(textToCopy)
    setPressed(true)
    setTimeout(resetPressed, PRESS_RESET_MS)
  }

  return (
    <Button
      disabled={pressed}
      onClick={handleClick}
      endIcon={<ContentCopy />}
      variant='contained'
      size='large'
      fullWidth
    >
      {pressed ? buttonPressedText : buttonText}
    </Button>
  )
}
