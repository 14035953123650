import * as React from 'react'

import { Button } from '@mui/material'

import type { ButtonProps } from '@mui/material'

type AuthScreenButtonProps = Omit<ButtonProps, 'fullWidth' | 'size'>

export const AuthScreenButton = ({
  children,
  sx = [],
  ...rest
}: AuthScreenButtonProps) => (
  <Button
    size='large'
    sx={[
      {
        borderRadius: 6,
        width: '300px',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
  >
    {children}
  </Button>
)
