import * as React from 'react'

import { MenuItem, Stack } from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import * as Yup from 'yup'

import {
  BUSINESS_TRADING_RANGE_LABELS,
  PERSON_TRADING_RANGE_LABELS,
  RELATIONSHIP_PURPOSE_LABELS,
} from '../queries'

export type TransactionProfileFormValues = {
  declaredDailyTradingRange: string
  relationshipPurpose: string
}

export const getTransactionProfileInitialValues = (
  data: TransactionProfileFormValues | undefined,
): TransactionProfileFormValues => ({
  declaredDailyTradingRange: data?.declaredDailyTradingRange || '',
  relationshipPurpose: data?.relationshipPurpose || '',
})

export const transactionProfileValidationSchema: Yup.SchemaOf<TransactionProfileFormValues> =
  Yup.object().shape({
    declaredDailyTradingRange: Yup.string()
      .required('Este campo es obligatorio'),
    relationshipPurpose: Yup.string()
      .required('Este campo es obligatorio'),
  })

export const TransactionProfileFields = ({ isBusiness }: { isBusiness: boolean }) => {
  const tradingRanges = isBusiness ? BUSINESS_TRADING_RANGE_LABELS : PERSON_TRADING_RANGE_LABELS

  return (
    <Stack spacing={3}>
      <Field
        required
        name='relationshipPurpose'
        type='text'
        label='Propósito de la relación comercial'
        component={Select}
      >
        {Object.entries(RELATIONSHIP_PURPOSE_LABELS).map(([purposeType, purposeLabel]) => (
          <MenuItem
            key={purposeType}
            value={purposeType}
          >
            {purposeLabel}
          </MenuItem>
        ))}
      </Field>
      <Field
        required
        name='declaredDailyTradingRange'
        type='text'
        label='Volumen transaccional esperado a diario'
        component={Select}
      >
        {Object.entries(tradingRanges).map(([rangeType, rangeLabel]) => (
          <MenuItem
            key={rangeType}
            value={rangeType}
          >
            {rangeLabel}
          </MenuItem>
        ))}
      </Field>
    </Stack>
  )
}
