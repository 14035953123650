import { gql } from '@apollo/client'

export type PlatformStatusVars = Record<string, never>

export type PlatformStatusData = {
  platformStatus: {
    withdrawalsDisabledUntil?: string
  }
}

export const PLATFORM_STATUS_QUERY = gql`
  query PlatformStatus {
    platformStatus {
      withdrawalsDisabledUntil
    }
  }
`
