import * as React from 'react'

import { Stack, Typography, styled } from '@mui/material'

import type { StackProps, TypographyProps } from '@mui/material'

type TextProps = Omit<TypographyProps, 'variant' | 'component'> & {
  mainTitle?: boolean
  component?: React.ElementType
}

const colorfulStyle = {
  background: 'var(--color-mainTitleGradient)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}

export const Title = ({
  mainTitle = false,
  colorful = false,
  sx = {},
  children,
  ...rest
}: TextProps & { colorful?: boolean }) => (
  <Typography
    variant={mainTitle ? 'h3' : 'h4'}
    component={mainTitle ? 'h1' : 'h2'}
    sx={{ ...sx, ...(colorful && colorfulStyle) }}
    {...rest}
  >
    {children}
  </Typography>
)

export const Subtitle = ({
  mainTitle = false,
  children,
  ...rest
}: TextProps) => (
  <Typography
    variant={mainTitle ? 'h5' : 'h6'}
    component={mainTitle ? 'h2' : 'h3'}
    {...rest}
  >
    {children}
  </Typography>
)

export const Body = ({
  mainTitle = false,
  children,
  ...rest
}: TextProps) => (
  <Typography
    variant={mainTitle ? 'subtitle1' : 'body1'}
    component='p'
    {...rest}
  >
    {children}
  </Typography>
)

export const OrderedList = styled('ol')(({ theme }) => ({
  margin: 0,
  paddingInlineStart: theme.spacing(3),
  '> li:not(:first-of-type)': {
    paddingTop: theme.spacing(2),
  },
}))

export const UnorderedList = styled('ul')(({ theme }) => ({
  margin: 0,
  paddingInlineStart: theme.spacing(3),
  '> li:not(:first-of-type)': {
    paddingTop: theme.spacing(2),
  },
}))

interface ListItemProps {
  title?: string
  children?: React.ReactNode
}

export const ListItem = ({ title, children }: ListItemProps) => (
  <li>
    {title && (
      <React.Fragment>
        <span style={{ fontWeight: 500 }}>{title}</span>
        <br />
      </React.Fragment>
    )}
    {children}
  </li>
)

export const SectionWrapper = ({ children, ...rest }: StackProps) => (
  <Stack
    spacing={6}
    px={1}
    py={6}
    {...rest}
  >
    {children}
  </Stack>
)

export const Section = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
}))
