import * as React from 'react'

import { ListItem, ListItemText, Typography } from '@mui/material'

import type { TypographyProps } from '@mui/material'

type ErrorDisplayProps = TypographyProps & {
  errorMsg?: React.ReactNode
  listItem?: boolean
}

export const ErrorDisplay = ({
  errorMsg,
  listItem,
  ...rest
}: ErrorDisplayProps) => (
  (errorMsg) && (
    (listItem) ? (
      <ListItem>
        <ListItemText
          primary={errorMsg}
          primaryTypographyProps={{
            color: 'error',
            textAlign: 'center',
            variant: 'body2',
            ...rest,
          }}
        />
      </ListItem>
    ) : (
      <Typography
        color='error'
        textAlign='center'
        variant='body2'
        {...rest}
      >
        {errorMsg}
      </Typography>
    )
  )
)
