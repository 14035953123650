import * as React from 'react'

import { Business, Person } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer } from '../components'

type UserType = {
  userType: string
}

export type UserTypeFormValues = UserType

export const getUserTypeMutationValues = (values: UserType) => ({
  isBusiness: values.userType === 'business',
})

export const getUserTypeInitialValues = {
  userType: '',
}

export const userTypeValidationSchema: Yup.SchemaOf<UserTypeFormValues> =
  Yup.object({
    userType: Yup.string()
      .oneOf(['person', 'business'], 'Debes seleccionar una opción')
      .required('Debes seleccionar una opción'),
  })

export const UserTypeFields = <FormValues extends UserTypeFormValues>() => {
  const { isSubmitting, setFieldValue, values } = useFormikContext<FormValues>()

  return (
    <ButtonsContainer>
      <ButtonContainer
        xs={12}
        sm={6}
      >
        <Button
          fullWidth
          disabled={isSubmitting}
          startIcon={<Person />}
          onClick={() => setFieldValue('userType', 'person')}
          variant={(values.userType === 'person') ? 'contained' : 'outlined'}
          color='primary'
          size='large'
          sx={(theme) => ({
            py: 2,
            background: (values.userType === 'person')
              ? theme.palette.primary.main
              : 'transparent',
          })}
        >
          Persona natural
        </Button>
      </ButtonContainer>
      <ButtonContainer
        xs={12}
        sm={6}
      >
        <Button
          fullWidth
          disabled={isSubmitting}
          startIcon={<Business />}
          onClick={() => setFieldValue('userType', 'business')}
          variant={(values.userType === 'business') ? 'contained' : 'outlined'}
          color='primary'
          size='large'
          sx={(theme) => ({
            py: 2,
            background: (values.userType === 'business')
              ? theme.palette.primary.main
              : 'transparent',
          })}
        >
          Persona jurídica
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  )
}
