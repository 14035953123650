import { gql } from '@apollo/client'

export type Authenticatable = {
  email: string
}

export const AUTHENTICATABLE_FIELDS = gql`
  fragment AuthenticatableFields on Authenticatable {
    email
  }
`

export type Credential = {
  accessToken: string
  client: string
  expiry: number
  tokenType: string
  uid: string
}

export const CREDENTIAL_KEYS: (keyof Credential)[] = [
  'accessToken',
  'client',
  'expiry',
  'tokenType',
  'uid',
]

export type UserCredential = Credential & {
  provider: string
}

export const USER_CREDENTIAL_KEYS: (keyof UserCredential)[] = [
  ...CREDENTIAL_KEYS,
  'provider',
]

export const USER_CREDENTIAL_FIELDS = gql`
  fragment UserCredentialFields on UserCredential {
    ${USER_CREDENTIAL_KEYS.join('\n')}
  }
`

export type UserRegisterVars = {
  email: string
  password: string
  passwordConfirmation: string
  nickName: string
  originName: string
}

export type UserRegisterData = {
  userRegister: string
}

export const USER_REGISTER_MUTATION = gql`
  mutation UserRegister(
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $nickName: String!
    $originName: String!
  ) {
    userRegister(
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
      nickName: $nickName
      originName: $originName
    )
  }
`

export type UserConfirmVars = {
  confirmationToken: string
}

export type UserConfirmData = {
  userConfirmRegistrationWithToken: {
    authenticatable: Authenticatable
    credentials: UserCredential
  }
}

export const USER_CONFIRM_MUTATION = gql`
  ${AUTHENTICATABLE_FIELDS}
  ${USER_CREDENTIAL_FIELDS}
  mutation UserConfirmRegistrationWithToken(
    $confirmationToken: String!
  ) {
    userConfirmRegistrationWithToken(
      confirmationToken: $confirmationToken
    ) {
      authenticatable {
        ...AuthenticatableFields
      }
      credentials {
        ...UserCredentialFields
      }
    }
  }
`

export type UserLoginVars = {
  email: string
  password: string
  otp?: string
}

export type UserLoginData = {
  userLogin: {
    authenticatable: Authenticatable
    credentials: UserCredential
  }
}

export const USER_LOGIN_MUTATION = gql`
  ${AUTHENTICATABLE_FIELDS}
  ${USER_CREDENTIAL_FIELDS}
  mutation UserLogin(
    $email: String!
    $password: String!
    $otp: String
  ) {
    userLogin(
      email: $email
      password: $password
      otp: $otp
    ) {
      authenticatable {
        ...AuthenticatableFields
      }
      credentials {
        ...UserCredentialFields
      }
    }
  }
`

export type UserLogoutVars = Record<string, never>

export type UserLogoutData = {
  userLogout: {
    authenticatable: Authenticatable
  }
}

export const USER_LOGOUT_MUTATION = gql`
  ${AUTHENTICATABLE_FIELDS}
  mutation UserLogout {
    userLogout {
      authenticatable {
        ...AuthenticatableFields
      }
    }
  }
`

export type UserPasswordResetVars = {
  email: string
}

export type UserPasswordResetData = {
  userSendPasswordResetWithToken: string
}

export const USER_PASSWORD_RESET_MUTATION = gql`
  mutation UserPasswordReset(
    $email: String!
  ) {
    userSendPasswordResetWithToken(
      email: $email
    )
  }
`

export type UserPasswordUpdateVars = {
  password: string
  passwordConfirmation: string
  resetPasswordToken: string
}

export type UserPasswordUpdateData = {
  userUpdatePasswordWithToken: {
    authenticatable: Authenticatable
    credentials: UserCredential
  }
}

export const USER_PASSWORD_UPDATE_MUTATION = gql`
  ${AUTHENTICATABLE_FIELDS}
  ${USER_CREDENTIAL_FIELDS}
  mutation UserUpdatePassword(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    userUpdatePasswordWithToken(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      authenticatable {
        ...AuthenticatableFields
      }
      credentials {
        ...UserCredentialFields
      }
    }
  }
`

export type UserUnlockAccessVars = {
  unlockToken: string
}

export type UserUnlockAccessData = {
  userUnlockAccessWithToken: string
}

export const USER_UNLOCK_ACCESS_MUTATION = gql`
  mutation UserUnlockAccess(
    $unlockToken: String!
  ) {
    userUnlockAccessWithToken(
      unlockToken: $unlockToken
    )
  }
`
