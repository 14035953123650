export type Bank = {
  label: string
}

export const BANKS: { [bankCode: string]: Bank } = {
  BANCO_CHILE: {
    label: 'Banco de Chile',
  },
  BANCO_INTERNACIONAL: {
    label: 'Banco Internacional',
  },
  SCOTIABANKCHILE: {
    label: 'Scotiabank Chile',
  },
  BCI: {
    label: 'BCI',
  },
  BANCO_BICE: {
    label: 'BICE',
  },
  HSBCBANK_CHILE: {
    label: 'HSBC Chile',
  },
  SANTANDER: {
    label: 'Banco Santander',
  },
  BANCO_ITAU: {
    label: 'Banco Itaú',
  },
  BANCO_SECURITY: {
    label: 'Banco Security',
  },
  BANCO_FALABELLA: {
    label: 'Banco Falabella',
  },
  BANCO_RIPLEY: {
    label: 'Banco Ripley',
  },
  BANCO_CONSORCIO: {
    label: 'Banco Consorcio',
  },
  SCOTIABANK_AZUL: {
    label: 'Scotiabank Azul (ex BBVA)',
  },
  BTG_PACTUAL_CHILE: {
    label: 'Banco BTG Pactual Chile',
  },
  BANCO_DO_BRASIL: {
    label: 'Banco do Brasil',
  },
  JP_MORGAN_CHASE_BANK: {
    label: 'JP Morgan Chase Bank',
  },
  BANCO_NACION_ARGENTINA: {
    label: 'Banco de la Nación Argentina',
  },
  MUFG_BANK: {
    label: 'MUFG Bank',
  },
  CHINA_CONSTRUCTION_BANK: {
    label: 'China Construction Bank',
  },
  BANK_OF_CHINA_AGEN_CHILE: {
    label: 'Bank of China (Agencia en Chile)',
  },
  BANCO_ESTADO: {
    label: 'BancoEstado',
  },
  BANCO_EDWARDS_CITI: {
    label: 'Banco Edwards | Citi',
  },
  CREDI_CHILE: {
    label: 'Banco CrediChile',
  },
  BANCO_DESARROLLO: {
    label: 'Banco Desarrollo',
  },
  TBANC: {
    label: 'TBanc',
  },
  BANCONOVA: {
    label: 'Banco Nova',
  },
  BANEFE: {
    label: 'Banefe',
  },
}

export type BankEntry = [bankCode: string, bank: Bank]

export const BANK_ENTRIES: BankEntry[] = (
  Object
    .entries(BANKS)
    .slice()
    .sort(([, bankA], [, bankB]) => bankA.label.localeCompare(bankB.label))
)
