import { Typography, alpha, styled } from '@mui/material'

export const MonospaceText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  fontFamily: 'monospace',
  overflowWrap: 'break-word',
  whiteSpace: 'pre-line',
  backgroundColor: alpha(
    theme.palette.primary.main,
    theme.palette.action.selectedOpacity,
  ),
}))
