import * as React from 'react'

import { Attachment } from '@mui/icons-material'
import { Button, Collapse, Input, Typography, useTheme } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useFormikContext } from 'formik'

import { BackdropLoading, ErrorDisplay, Image, WebcamPicture } from '../components'
import { detectFace, heicToJpeg, isMobileDevice } from '../services'

const FACE_DETECTION_ERROR_MSG = (
  'No fue posible detectar una cara en la imagen del documento. '
  + 'Por favor verifica que la orientación, iluminación y calidad de la imagen sean adecuadas.'
)

type FormValues = {
  [name: string]: File | undefined
}

type DocumentUploadProps = {
  name: string
  imageAlt: string
  contentTypes: string[]
  allowWebcam?: boolean
  detectFaces?: boolean
}

export const DocumentUpload = ({
  name,
  imageAlt,
  contentTypes,
  allowWebcam,
  detectFaces,
}: DocumentUploadProps) => {
  const labelId = `${name}Input`
  const [loading, setLoading] = React.useState<boolean>(false)
  const [preview, setPreview] = React.useState<string>('')
  const hasPreview = Boolean(preview)
  const theme = useTheme()

  const {
    errors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
  } = useFormikContext<FormValues>()
  const selectedFile = values[name]

  const freePreviewObjectUrl = () => {
    const objectUrl = preview
    setPreview('')
    URL.revokeObjectURL(objectUrl)
  }

  const handleChanged = async (newFile: File | undefined) => {
    if (!newFile) {
      await setFieldValue(name, undefined)
      freePreviewObjectUrl()
      return
    }

    setLoading(true)
    let objectUrl: string

    if (newFile.type === 'image/heic') {
      objectUrl = URL.createObjectURL(await heicToJpeg(newFile))
    } else {
      objectUrl = URL.createObjectURL(newFile)
    }

    if (detectFaces && newFile.type !== 'application/pdf') {
      if (await detectFace(objectUrl)) {
        await setFieldValue(name, newFile)
      } else {
        await setFieldValue(name, undefined)
        await setFieldTouched(name, true)
        setFieldError(name, FACE_DETECTION_ERROR_MSG)
      }
    } else {
      await setFieldValue(name, newFile)
    }

    setPreview(objectUrl)
    setLoading(false)
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    handleChanged(selectedFile)
  }, [detectFaces])

  React.useEffect(() => {
    return () => freePreviewObjectUrl()
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <label
      htmlFor={labelId}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
      }}
    >
      {loading && <BackdropLoading message='Estamos procesando tu imagen...' />}
      <Button
        startIcon={<Attachment />}
        variant='contained'
        component='span'
        fullWidth
      >
        SELECCIONAR ARCHIVO
        <Input
          sx={visuallyHidden}
          inputProps={{
            id: labelId,
            type: 'file',
            accept: contentTypes.join(),
            name: name,
            onChange: async (event: React.FormEvent<HTMLInputElement>) => {
              const files = event.currentTarget.files
              await setFieldTouched(name, true)
              await handleChanged((files) ? files[0] : undefined)
            },
          }}
        />
      </Button>
      {(allowWebcam && !isMobileDevice()) && (
        <WebcamPicture onChange={handleChanged} />
      )}
      <ErrorDisplay
        errorMsg={touched[name] && errors[name]}
        mt={2}
      />
      <Collapse in={hasPreview}>
        {hasPreview && (
          (selectedFile?.type === 'application/pdf') ? (
            <Typography
              textAlign='center'
              color='text.secondary'
              overflow='hidden'
              textOverflow='ellipsis'
              mt={1}
            >
              {selectedFile.name}
            </Typography>
          ) : (
            <Image
              src={preview}
              alt={imageAlt}
              fit='contain'
              wrapperStyle={{
                height: 'min(20vh, 250px)',
                marginTop: theme.spacing(1),
              }}
              duration={1000}
            />
          )
        )}
      </Collapse>
    </label>
  )
}
