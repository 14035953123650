import * as React from 'react'

import { CameraAlt } from '@mui/icons-material'
import { Button } from '@mui/material'
import Webcam from 'react-webcam'

import { ButtonContainer, ButtonsContainer, Dialog } from '../components'

import type { ButtonProps } from '@mui/material'

const CONTENT_TYPE = 'image/png'
const FILE_NAME = 'capture.png'

const mirrorImage = async (imgDataUrl: string) => {
  const image = new Image()
  image.src = imgDataUrl
  await image.decode()

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  canvas.width = image.width
  canvas.height = image.height

  context?.translate(canvas.width, 0)
  context?.scale(-1, 1)
  context?.drawImage(image, 0, 0)

  return canvas.toDataURL(CONTENT_TYPE)
}

const imgDataUrlToFile = (imgDataUrl: string) => {
  const [dataType, imageData] = imgDataUrl.split(',')
  const contentType = dataType.match(/:(.*?);/)?.[1]
  const binaryString = window.atob(imageData)

  let binaryStringLength = binaryString.length
  const uint8Array = new Uint8Array(binaryStringLength)

  while (binaryStringLength--) {
    uint8Array[binaryStringLength] = binaryString.charCodeAt(binaryStringLength)
  }

  return new File([uint8Array], FILE_NAME, { type: contentType })
}

type WebcamPictureProps = {
  onChange: (file: File) => Promise<void>
  sx?: ButtonProps['sx']
}

export const WebcamPicture = ({
  onChange,
  sx,
}: WebcamPictureProps) => {
  const webcamRef = React.useRef<Webcam>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const handleCapture = async () => {
    const imgDataUrl = webcamRef.current?.getScreenshot()

    if (!imgDataUrl) {
      return
    }

    const mirroredImage = await mirrorImage(imgDataUrl)
    const imageFile = imgDataUrlToFile(mirroredImage)
    onChange(imageFile)
    closeDialog()
  }

  return (
    <React.Fragment>
      <Button
        variant='contained'
        startIcon={<CameraAlt />}
        onClick={openDialog}
        sx={sx}
        fullWidth
      >
        TOMAR UNA FOTO
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='sm'
        title='Tomar una foto'
      >
        <Webcam
          audio={false}
          mirrored={true}
          ref={webcamRef}
          style={{ width: '100%' }}
          screenshotFormat={CONTENT_TYPE}
        />
        <ButtonsContainer sx={{ mt: 1 }}>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              variant='outlined'
              onClick={closeDialog}
            >
              Cancelar
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              variant='contained'
              startIcon={<CameraAlt />}
              onClick={handleCapture}
            >
              Tomar foto
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Dialog>
    </React.Fragment>
  )
}
