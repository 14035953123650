import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Button, InputAdornment, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, Loading } from '..'
import { CurrencyField } from '../../forms'
import { RAMP_CONFIG_QUERY } from '../../queries'

import type { RampConfigData, RampConfigVars } from '../../queries'
import type { FormikProps } from 'formik'

type FormValues = {
  fiatAmount: number
}

const initialValues = (fiatAmount: number) => ({
  fiatAmount,
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    fiatAmount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .integer('Debes introducir un monto sin decimales'),
  })

type PurchaseFormProps = FormikProps<FormValues> & {
  userFiatCurrency: string
}

const PurchaseForm = ({
  isSubmitting,
  isValid,
  submitForm,
  userFiatCurrency,
}: PurchaseFormProps) => (
  <Form
    onSubmit={(event) => {
      event?.preventDefault()
      submitForm()
    }}
    style={{ display: 'flex', flexDirection: 'column' }}
  >
    <CurrencyField
      name='fiatAmount'
      label='Monto a comprar'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <small>{userFiatCurrency}</small>&nbsp;$
          </InputAdornment>
        ),
      }}
      digits={0}
      positive
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          type='submit'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type AmountStepProps = {
  fiatAmount: number
  handleNext: (fiatAmount: number) => void
}

export const AmountStep = ({
  fiatAmount,
  handleNext,
}: AmountStepProps) => {
  const { loading, data } = useQuery<RampConfigData, RampConfigVars>(RAMP_CONFIG_QUERY)

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Typography
        variant='h6'
        component='span'
        textAlign='center'
        gutterBottom
      >
        Comprar dólares digitales
      </Typography>
      <Formik
        initialValues={initialValues(fiatAmount)}
        validationSchema={validationSchema}
        onSubmit={(values) => handleNext(values.fiatAmount)}
      >
        {(props) => (
          <PurchaseForm
            userFiatCurrency={data?.rampConfig.userFiatCurrency || '--'}
            {...props}
          />
        )}
      </Formik>
    </React.Fragment>
  )
}
