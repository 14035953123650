import * as React from 'react'

import { Button } from '@mui/material'

import { CopyButton, Dialog, MonospaceText } from '../components'

const BANK_ACCOUNT_DATA = [
  'Senna SpA',
  '77.069.616-K',
  'Banco BCI',
  'Cuenta Corriente',
  '46741437',
  'bci@fugiro.capital',
].join('\n')

export const SennaBankTransferDialog = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <Button
        onClick={openDialog}
        variant='outlined'
        color='primary'
      >
        Ver datos de transferencia
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Datos de transferencia'
        maxWidth='xs'
      >
        <MonospaceText
          width='100%'
          textAlign='center'
        >
          {BANK_ACCOUNT_DATA}
        </MonospaceText>
        <CopyButton
          textToCopy={BANK_ACCOUNT_DATA}
          buttonText='Copiar datos'
          buttonPressedText='¡Datos copiados!'
        />
      </Dialog>
    </React.Fragment>
  )
}
