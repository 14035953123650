import { gql } from '@apollo/client'

export type BaseApy = {
  id: string
  marketAssetId: string
  value: number
}

export const BASE_APY_FIELDS = gql`
  fragment BaseApyFields on BaseApy {
    id
    marketAssetId
    value
  }
`

export type BaseApysVars = Record<string, never>

export type BaseApysData = {
  baseApys: BaseApy[]
}

export const BASE_APYS_QUERY = gql`
  ${BASE_APY_FIELDS}
  query BaseApys {
    baseApys {
      ...BaseApyFields
    }
  }
`
