import { gql } from '@apollo/client'

export type RenewAuthorizationCodeVars = Record<string, never>

export type RenewAuthorizationCodeData = {
  renewAuthorizationCode: string
}

export const RENEW_AUTHORIZATION_CODE_MUTATION = gql`
  mutation RenewAuthorizationCode {
    renewAuthorizationCode(input: {})
  }
`
