import * as React from 'react'

import { Container, Grid } from '@mui/material'

import type { ContainerProps, GridProps } from '@mui/material'

const GridContainer = ({
  minHeight = 'min(85vh, 650px)',
  children,
  ...rest
}: GridProps) => (
  <Grid
    container
    minHeight={minHeight}
    justifyContent='center'
    {...rest}
  >
    {children}
  </Grid>
)

const GridItem = ({ sm, children }: GridProps) => (
  <Grid
    item
    container
    direction='column'
    justifyContent='center'
    xs={12}
    sm={sm}
    sx={(theme) => ({
      px: 3,
      py: 2,
      [theme.breakpoints.down('sm')]: {
        overflowX: 'auto',
      },
    })}
  >
    {children}
  </Grid>
)

export type SplitSectionProps = Omit<GridProps, 'maxWidth'> & Pick<ContainerProps, 'maxWidth'> & {
  reversed?: boolean
  spacing?: number
  imageWidth?: number
  imageComponent: React.ReactNode
}

export const SplitSection = ({
  reversed = false,
  spacing = 0,
  imageWidth = 6,
  imageComponent,
  maxWidth,
  children,
  ...rest
}: SplitSectionProps) => {
  const gridDirection = reversed ? 'row-reverse' : 'row'

  return (
    <Container
      maxWidth={maxWidth}
      sx={{ pt: 3 }}
    >
      <GridContainer
        direction={gridDirection}
        {...rest}
      >
        <GridItem sm={12 - imageWidth - spacing}>{children}</GridItem>
        {(spacing > 0) && (
          <Grid
            item
            sm={spacing}
          />
        )}
        <GridItem sm={imageWidth}>{imageComponent}</GridItem>
      </GridContainer>
    </Container>
  )
}
