import * as React from 'react'

import { useMutation } from '@apollo/client'
import { useTheme } from '@mui/material'
import { GoogleLogin as GoogleLoginButton } from '@react-oauth/google'

import { Dialog } from './dialog'
import { ErrorDisplay } from './error_display'
import { GOOGLE_SIGN_IN_MUTATION } from '../queries'
import { setCredential, translateError } from '../services'

import type { GoogleSignInData, GoogleSignInVars, UserCredential } from '../queries'

type GoogleSignInProps = {
  originName?: string
  logSuccessfulLoginEvent?: (credentials: UserCredential, method: string) => void
  logFailedLoginEvent?: () => void
  logSuccessfulRegistrationEvent?: (method: string) => void
  logFailedRegistrationEvent?: () => void
}

export const GoogleSignIn = ({
  originName,
  logSuccessfulLoginEvent,
  logFailedLoginEvent,
  logSuccessfulRegistrationEvent,
  logFailedRegistrationEvent,
}: GoogleSignInProps) => {
  const theme = useTheme()

  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [googleSignIn] =
    useMutation<GoogleSignInData, GoogleSignInVars>(GOOGLE_SIGN_IN_MUTATION, {
      errorPolicy: 'all',
    })

  const handleSuccess = async (token: string) => {
    const response = await googleSignIn({
      variables: {
        originName: originName || 'signup_form',
        token: token,
      },
    })

    const credentials = response.data?.googleSignIn?.credentials
    const otherProvider = response.data?.googleSignIn?.otherProvider

    if (otherProvider) {
      setDialogOpen(true)
    } else if (credentials) {
      logSuccessfulLoginEvent && logSuccessfulLoginEvent(credentials, 'google')
      logSuccessfulRegistrationEvent && logSuccessfulRegistrationEvent('google')
      setCredential(credentials)
      window.location.href = '/app/'
    } else {
      logFailedLoginEvent && logFailedLoginEvent()
      logFailedRegistrationEvent && logFailedRegistrationEvent()
      setErrorMsg(translateError(response))
    }
  }

  return (
    <React.Fragment>
      <GoogleLoginButton
        onSuccess={(response) => handleSuccess(response.credential || '')}
        theme={theme.palette.mode === 'light' ? 'filled_black' : 'outline'}
        text='continue_with'
        shape='pill'
        size='large'
        width='300'
      />
      <ErrorDisplay errorMsg={errorMsg} />
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title='Revisa tu bandeja de entrada'
        contentText={'Te enviamos un correo con instrucciones para iniciar sesión con tu cuenta'}
      />
    </React.Fragment>
  )
}
