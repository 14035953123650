import * as React from 'react'

import { ShowChart, ViewQuilt } from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { ButtonContainer } from '../components'

import type { ButtonContainerProps } from '../components'

export type GraphType = 'COMPOSITION' | 'TOTAL_AMOUNT'

type GraphTypeKey = {
  value: GraphType
  label: string
  icon: React.ReactNode
}

export const GRAPH_TYPES: GraphTypeKey[] = [
  {
    value: 'COMPOSITION',
    label: 'Gráfico de composición',
    icon: <ViewQuilt />,
  },
  {
    value: 'TOTAL_AMOUNT',
    label: 'Gráfico de balance',
    icon: <ShowChart />,
  },
]

export type GraphTypeButtonsProps = {
  disabled?: boolean
  keys: GraphTypeKey[]
  graphType: string
  setGraphType: (value: GraphType) => void
  containerProps?: ButtonContainerProps
}

export const GraphTypeButtons = ({
  disabled,
  keys,
  graphType,
  setGraphType,
  containerProps,
}: GraphTypeButtonsProps) => {
  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newType: GraphType | undefined,
  ) => {
    newType && setGraphType(newType)
  }

  return (
    <ButtonContainer {...containerProps}>
      <ToggleButtonGroup
        disabled={disabled}
        aria-label=''
        color='primary'
        value={graphType}
        exclusive
        onChange={handleChange}
      >
        {keys.map((key) => (
          <ToggleButton
            key={key.value}
            value={key.value}
            aria-label={key.label}
            sx={{
              py: 0.5,
              px: 1.5,
              '&.Mui-selected': {
                color: 'text.primary',
              },
            }}
          >
            {key.icon}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </ButtonContainer>
  )
}
