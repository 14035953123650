import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Videocam } from '@mui/icons-material'
import { Button, List, ListItem, ListItemText, Stack } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'

import {
  BackdropLoading,
  ButtonContainer,
  ButtonsContainer,
  Dialog,
  ErrorDisplay,
  LivenessProofPreview,
  Loading,
} from '../../components'
import { LivenessProofInput } from '../../forms'
import {
  CREATE_LIVENESS_PROOF_MUTATION,
  LIVENESS_PROOFS_QUERY,
  UPDATE_LIVENESS_PROOF_MUTATION,
} from '../../queries'
import { hasValidSize, setFormError, translateError, uploadToStorage } from '../../services'

import type {
  CreateLivenessProofData,
  CreateLivenessProofVars,
  LivenessProofView,
  LivenessProofsData,
  LivenessProofsVars,
  UpdateLivenessProofData,
  UpdateLivenessProofVars,
} from '../../queries'

type FormValues = {
  video: File | undefined
}

const getInitialValues: FormValues = ({
  video: undefined,
})

const hasValidContentType = (video: File | undefined) => (
  !!video && ['video/mp4', 'video/webm'].includes(video.type)
)

const validationSchema = (
  Yup.object().shape({
    video: Yup.mixed()
      .required('Este campo es obligatorio')
      .test('fileType', 'Debes subir un archivo MP4', hasValidContentType)
      .test('fileSize', 'Tu archivo supera el tamaño máximo de 16 MB', hasValidSize),
  })
)

type StepFormProps = FormikProps<FormValues> & {
  handleBack: () => void
  showWaitingMessage: boolean
}

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  setFieldValue,
  submitForm,
  values,
  handleBack,
  showWaitingMessage,
}: StepFormProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(true)
  const [preview, setPreview] = React.useState<string>('')

  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)

  const handleFinish = async (file: File) => {
    await setFieldValue('video', file)
    URL.revokeObjectURL(preview)
    setPreview(URL.createObjectURL(file))
    closeDialog()
  }

  return (
    <Form>
      {showWaitingMessage && (
        <BackdropLoading message='Por favor espera mientras cargamos el video...' />
      )}
      <Stack spacing={2}>
        {preview && <LivenessProofPreview src={preview} />}
        <Button
          variant='contained'
          startIcon={<Videocam />}
          onClick={openDialog}
          fullWidth
        >
          {values.video ? 'Repetir prueba' : 'Comenzar prueba'}
        </Button>
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={2}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting}
            onClick={handleBack}
            variant='outlined'
          >
            Atrás
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid || !values.video}
            onClick={submitForm}
            variant='contained'
            color='primary'
          >
            {isSubmitting ? 'Cargando...' : 'Continuar'}
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='sm'
        title='Reconocimiento facial'
      >
        <LivenessProofInput onFinishRecording={handleFinish} />
      </Dialog>
    </Form>
  )
}

type LivenessProofDisplayProps = {
  handleBack: () => void
  handleNext: () => void
  handleShowForm: () => void
  livenessProof: LivenessProofView
}

const LivenessProofDisplay = ({
  handleBack,
  handleNext,
  handleShowForm,
  livenessProof,
}: LivenessProofDisplayProps) => (
  <List>
    <ListItem>
      {(livenessProof.storageUrl) ? (
        <LivenessProofPreview src={livenessProof.storageUrl} />
      ) : (
        <ListItemText
          primary='No se ha subido un video.'
          secondary='Es posible que se haya producido un error mientras se cargaba en la plataforma.'
        />
      )}
    </ListItem>
    <Button
      onClick={handleShowForm}
      variant='outlined'
      sx={{ mt: 3, mb: 2 }}
      fullWidth
    >
      Repetir prueba
    </Button>
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleBack}
          variant='outlined'
        >
          Atrás
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleNext}
          variant='contained'
          color='primary'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </List>
)

type LivenessProofStepProps = {
  handleBack: () => void
  handleNext: () => void
}

export const LivenessProofStep = ({
  handleBack,
  handleNext,
}: LivenessProofStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [showForm, setShowForm] = React.useState(false)
  const [showWaitingMessage, setShowWaitingMessage] = React.useState(false)

  const { loading, data } =
    useQuery<LivenessProofsData, LivenessProofsVars>(LIVENESS_PROOFS_QUERY)

  const livenessProofs = data?.livenessProofs || []
  const livenessProof = livenessProofs.find((proof) => proof.documentType === 'LIVENESS')

  const [createLivenessProof] =
    useMutation<CreateLivenessProofData, CreateLivenessProofVars>(
      CREATE_LIVENESS_PROOF_MUTATION, {
        errorPolicy: 'all',
      })

  const [updateLivenessProof] =
    useMutation<UpdateLivenessProofData, UpdateLivenessProofVars>(
      UPDATE_LIVENESS_PROOF_MUTATION, {
        errorPolicy: 'all',
      })

  const uploadVideo = async (video: File | undefined) => {
    if (!video) {
      setFormError(formRef, 'No se encontró el archivo de origen.')
      return false
    }

    const createResponse = await createLivenessProof({
      variables: {
        contentType: video.type,
        documentType: 'LIVENESS',
      },
    })

    const livenessProof = createResponse.data?.createLivenessProof

    if (!livenessProof) {
      setFormError(formRef, translateError(createResponse))
      return false
    }

    const storageResponse = await uploadToStorage(livenessProof.storagePost, video)

    if (!storageResponse.ok) {
      setFormError(formRef, 'Ocurrió un error al subir tu archivo.')
      return false
    }

    const updateResponse = await updateLivenessProof({
      variables: {
        id: livenessProof.id,
      },
    })

    if (updateResponse.data?.updateLivenessProof === 'OK!') {
      return true
    }

    setFormError(formRef, translateError(updateResponse))
    return false
  }

  const handleSubmit = async (values: FormValues) => {
    setShowWaitingMessage(true)
    const updateResponse = await uploadVideo(values.video)

    if (updateResponse) {
      handleNext()
    } else {
      setShowWaitingMessage(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (!showForm && livenessProof) ? (
    <LivenessProofDisplay
      handleBack={handleBack}
      handleNext={handleNext}
      handleShowForm={() => setShowForm(true)}
      livenessProof={livenessProof}
    />
  ) : (
    <Formik
      innerRef={formRef}
      initialValues={getInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <StepForm
          handleBack={handleBack}
          showWaitingMessage={showWaitingMessage}
          {...props}
        />
      )}
    </Formik>
  )
}
