import { gql } from '@apollo/client'

export const COMPLAINT_CATEGORIES = [
  'Abuso de autoridad',
  'Acoso sexual',
  'Ambiente de trabajo hostil y amenazas físicas o violencia',
  'Conflicto de interés',
  'Corrupción y soborno',
  'Delitos contra el patrimonio cultural',
  'Discriminación, igualdad de empleo o problemas de diversidad',
  'Fraude o actividades ilegales',
  'Irregularidades contables y otros sobre control interno o de auditoría',
  'Lavado de activos y financiamiento del terrorismo',
  'Libre competencia',
  'Relaciones con clientes, proveedores o vendedores',
  'Represalias',
  'Robo',
  'Seguridad y salud en el trabajo',
  'Uso indebido de información privilegiada y manipulación de mercado',
  'Uso indebido de la información confidencial',
  'Venta no responsable',
  'Violaciones de los derechos humanos',
  'Otros',
]

export type ComplaintRequestVars = {
  category: string
  content: string
}

export type ComplaintRequestData = {
  complaintRequest: string
}

export const COMPLAINT_REQUEST_MUTATION = gql`
  mutation ComplaintRequest(
    $category: String!
    $content: String!
  ) {
    complaintRequest(
      category: $category
      content: $content
    )
  }
`
