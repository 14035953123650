const DIGIT_MULTIPLIERS = [2, 3, 4, 5, 6, 7]
const RUT_REGEX = /^[1-9]\d?((\.\d{3}){2}-[\dkK]|\d{6}-?[\dkK])$/

const sanitize = (rut: string) => (
  rut.replaceAll('.', '').replaceAll('-', '').toLowerCase()
)

const computeCheckDigit = (sanitizedRut: string) => {
  const digits = sanitizedRut.slice(0, -1).split('').reverse().map(Number)

  let sum = 0

  for (let i = 0; i < digits.length; i++) {
    sum += digits[i] * DIGIT_MULTIPLIERS[i % DIGIT_MULTIPLIERS.length]
  }

  const checkDigit = 11 - (sum % 11)

  if (checkDigit === 11) {
    return '0'
  } else if (checkDigit === 10) {
    return 'k'
  } else {
    return checkDigit.toString()
  }
}

export const hasValidRut = (rut?: string) => {
  if (typeof rut !== 'string' || !RUT_REGEX.test(rut)) {
    return false
  }

  const sanitizedRut = sanitize(rut)
  const checkDigit = sanitizedRut.slice(-1)

  return checkDigit === computeCheckDigit(sanitizedRut)
}
