import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Add } from '@mui/icons-material'
import {
  Button,
  Fab,
  MenuItem,
  Stack,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik, FormikProps } from 'formik'
import { Select, TextField } from 'formik-mui'
import { DesktopDateTimePicker } from 'formik-mui-x-date-pickers'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Dialog,
  DocumentUpload,
  ErrorDisplay,
} from '../components'
import {
  CREATE_USER_DOCUMENT_MUTATION,
  UPDATE_USER_DOCUMENT_MUTATION,
  USER_DOCUMENTS_QUERY,
  USER_DOCUMENT_CONTENT_TYPE_LABELS,
  USER_DOCUMENT_TYPE_LABELS,
} from '../queries'
import {
  hasValidSize,
  setFormError,
  toISO8601DateTime,
  translateError,
  uploadToStorage,
} from '../services'

import type {
  CreateUserDocumentData,
  CreateUserDocumentVars,
  UpdateUserDocumentData,
  UpdateUserDocumentVars,
  UserDocumentType,
} from '../queries'

export const hasValidContentType = (file: File | undefined) => (
  !file || Object.keys(USER_DOCUMENT_CONTENT_TYPE_LABELS).includes(file.type)
)

type FormValues = {
  documentType: UserDocumentType
  timestamp: Date | null
  description: string
  selectedFile?: File
}

const initialValues: FormValues = {
  documentType: 'LEGAL',
  timestamp: null,
  description: '',
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  documentType: Yup.mixed()
    .required('Este campo es obligatorio'),
  timestamp: Yup.date()
    .required('Este campo es obligatorio'),
  description: Yup.string()
    .required('Este campo es obligatorio'),
  selectedFile: Yup.mixed()
    .required('Debes adjuntar un archivo')
    .test('fileType', 'Debes subir un archivo PDF', hasValidContentType)
    .test('fileSize', 'Tu archivo supera el tamaño máximo de 16 MB', hasValidSize),
})

const DocumentCreatorForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
}: FormikProps<FormValues>) => (
  <Form style={{ width: '100%', marginTop: '10px' }}>
    <Stack
      spacing={3}
      width='100%'
    >
      <Field
        required
        name='documentType'
        type='text'
        label='Tipo de documento'
        component={Select}
        fullWidth
      >
        {Object.entries(USER_DOCUMENT_TYPE_LABELS).map(([type, label]) => (
          <MenuItem
            key={type}
            value={type}
          >
            {label}
          </MenuItem>
        ))}
      </Field>
      <Field
        required
        name='timestamp'
        type='date'
        label='Fecha de emisión'
        component={DesktopDateTimePicker}
        views={['year', 'month', 'day']}
        inputFormat='yyyy/MM/dd HH:mm:ss'
        toolbarTitle='Fecha de emisión'
        disableFuture
        closeOnSelect
        fullWidth
      />
      <Field
        required
        name='description'
        type='text'
        label='Descripcion del documento'
        component={TextField}
        margin='normal'
        fullWidth
      />
      <DocumentUpload
        name='selectedFile'
        imageAlt='Documento del usuario'
        contentTypes={Object.keys(USER_DOCUMENT_CONTENT_TYPE_LABELS)}
      />
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2, justifyContent: 'center' }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          {isSubmitting ? 'Creando...' : 'Crear documento'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

export const UserDocumentCreator = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const [createUserDocument] =
    useMutation<CreateUserDocumentData, CreateUserDocumentVars>(
      CREATE_USER_DOCUMENT_MUTATION, {
        errorPolicy: 'all',
      })

  const [updateUserDocument] =
    useMutation<UpdateUserDocumentData, UpdateUserDocumentVars>(
      UPDATE_USER_DOCUMENT_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          { query: USER_DOCUMENTS_QUERY },
        ],
      })

  const handleUpdate = async (userDocumentId: string) => {
    const response = await updateUserDocument({
      variables: { userDocumentId },
    })

    if (response.data?.updateUserDocument === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateError(response))
  }

  const handleSubmit = async (values: FormValues) => {
    if (!values.selectedFile) {
      return
    }

    const response = await createUserDocument({
      variables: {
        contentType: values.selectedFile.type,
        description: values.description,
        documentType: values.documentType,
        timestamp: toISO8601DateTime(values.timestamp),
      },
    })

    const { id, storagePost } = (response.data?.createUserDocument || {})

    if (!id || !storagePost) {
      setFormError(formRef, translateError(response))
      return
    }

    const storageResponse = await uploadToStorage(storagePost, values.selectedFile)

    if (!storageResponse.ok) {
      setFormError(formRef, 'Ocurrió un error al subir el archivo.')
      return
    }

    await handleUpdate(id)
  }

  return (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Agregar un documento'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Crear documento'
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <DocumentCreatorForm
                {...props}
              />
            )}
          </Formik>
        </LocalizationProvider>
      </Dialog>
    </React.Fragment>
  )
}
