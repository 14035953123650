import * as React from 'react'

import { Button, MenuItem, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer } from '../../components'

import type { MarketAsset } from '../../queries'
import type { FormikProps } from 'formik'

type FormValues = {
  blockchain: string
  address: string
}

const initialValues = (marketAsset: MarketAsset | null, blockchain: string, address: string): FormValues => {
  const blockchains = marketAsset?.blockchains || []

  return blockchains.includes(blockchain)
    ? ({ blockchain: blockchain, address: address })
    : ({ blockchain: blockchains[0], address: address })
}

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    blockchain: Yup.string()
      .required('Este campo es obligatorio'),
    address: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, 'La dirección solo puede contener letras o números')
      .required('Este campo es obligatorio'),
  })

type InnerFormProps = FormikProps<FormValues> & {
  marketAsset: MarketAsset | null
  handleBack: () => void
}

const InnerForm = ({
  isSubmitting,
  isValid,
  submitForm,
  values,
  marketAsset,
  handleBack,
}: InnerFormProps) => (
  <Form>
    <Field
      required
      name='blockchain'
      type='text'
      label='Blockchain'
      component={Select}
      disabled={!marketAsset || marketAsset.blockchains.length < 2}
      formControl={{ sx: { width: '100%', mb: 1 } }}
    >
      {(marketAsset?.blockchains || []).map((blockchain, index) => (
        <MenuItem
          key={index}
          value={blockchain}
        >
          {blockchain}
        </MenuItem>
      ))}
    </Field>
    <Field
      required
      inputProps={{ autoComplete: 'off' }}
      name='address'
      type='text'
      label={`Dirección ${values.blockchain || 'blockchain'}`}
      component={TextField}
      margin='normal'
      fullWidth
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleBack}
          variant='outlined'
        >
          Regresar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type AddressStepProps = {
  marketAsset: MarketAsset | null
  blockchain: string
  address: string
  handleBack: () => void
  handleNext: (blockchain: string, address: string) => void
}

export const AddressStep = ({
  marketAsset,
  blockchain,
  address,
  handleBack,
  handleNext,
}: AddressStepProps) => (
  <React.Fragment>
    <Typography
      variant='h6'
      component='span'
      textAlign='center'
      pb={2}
    >
      Retirar al blockchain
    </Typography>
    <Formik
      initialValues={initialValues(marketAsset, blockchain, address)}
      validationSchema={validationSchema}
      onSubmit={(values) => handleNext(values.blockchain, values.address)}
    >
      {(props) => (
        <InnerForm
          {...props}
          marketAsset={marketAsset}
          handleBack={handleBack}
        />
      )}
    </Formik>
  </React.Fragment>
)
