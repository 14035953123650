import * as React from 'react'

type LivenessProofPreviewProps = {
  src: string
}

export const LivenessProofPreview = ({
  src,
}: LivenessProofPreviewProps) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null)

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = src
      videoRef.current.playbackRate = 2
    }
  }, [src])

  return (
    <video
      ref={videoRef}
      autoPlay
      loop
      muted
      playsInline
      preload='auto'
      style={{
        width: '100%',
        height: 'min(30vh, 500px)',
        transform: 'scaleX(-1)',
      }}
    >
      Tu navegador no soporta video.
    </video>
  )
}
