import * as React from 'react'

import { MenuItem, Stack } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as Yup from 'yup'

import { SORTED_COUNTRIES, sortedRegions } from '../services'

import type { LegalAddress, UpdateLegalAddressVars } from '../queries'

export type LegalAddressFormValues = UpdateLegalAddressVars

export const getLegalAddressInitialValues = (
  data: LegalAddress | undefined,
): LegalAddressFormValues => ({
  countryCode: data?.countryCode || '',
  regionCode: data?.regionCode || '',
  city: data?.city || '',
  streetAddress: data?.streetAddress || '',
  postalCode: data?.postalCode || '',
})

export const legalAddressValidationSchema: Yup.SchemaOf<LegalAddressFormValues> =
  Yup.object({
    countryCode: Yup.string()
      .required('Este campo es obligatorio'),
    regionCode: Yup.string()
      .required('Este campo es obligatorio'),
    city: Yup.string()
      .required('Este campo es obligatorio'),
    streetAddress: Yup.string()
      .required('Este campo es obligatorio'),
    postalCode: Yup.string()
      .required('Este campo es obligatorio'),
  })

export const LegalAddressFields = <FormValues extends LegalAddressFormValues>() => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  return (
    <Stack spacing={3}>
      <Field
        required
        name='countryCode'
        type='text'
        label='País'
        component={Select}
        onChange={() => setFieldValue('region', '', false)}
      >
        {SORTED_COUNTRIES.map((country) => (
          <MenuItem
            key={country.iso}
            value={country.iso}
          >
            {country.name}
          </MenuItem>
        ))}
      </Field>
      <Field
        required
        name='regionCode'
        type='text'
        label='Región/Estado'
        component={Select}
        disabled={!values.countryCode}
      >
        {sortedRegions(values.countryCode).map((region, index) => (
          <MenuItem
            key={`${values.countryCode}-${region.iso}-${index}`}
            value={region.iso}
          >
            {region.name}
          </MenuItem>
        ))}
      </Field>
      <Field
        required
        name='city'
        type='text'
        label='Ciudad'
        component={TextField}
        margin='normal'
      />
      <Field
        required
        name='streetAddress'
        type='text'
        label='Calle, número, departamento, etc.'
        component={TextField}
        margin='normal'
        minRows={2}
        multiline
      />
      <Field
        required
        name='postalCode'
        type='text'
        label='Código Postal'
        component={TextField}
        margin='normal'
      />
    </Stack>
  )
}
