import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Delete, Person } from '@mui/icons-material'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material'

import { Dialog, ErrorDisplay } from '../../../components'
import {
  DELETE_ULTIMATE_BENEFICIAL_OWNER_MUTATION,
  ULTIMATE_BENEFICIAL_OWNERS_QUERY,
} from '../../../queries'
import {
  RolesList,
  findCountryByCode,
  getFullAddress,
  hasOwnershipRole,
  translateError,
} from '../../../services'

import type {
  DeleteUltimateBeneficialOwnerData,
  DeleteUltimateBeneficialOwnerVars,
  UltimateBeneficialOwner,
} from '../../../queries'
import type { ExecutionResult } from 'graphql'

type UltimateBeneficialOwnerDisplayProps = {
  ultimateBeneficialOwner: UltimateBeneficialOwner
}

export const UltimateBeneficialOwnerDisplay = ({
  ultimateBeneficialOwner,
}: UltimateBeneficialOwnerDisplayProps) => {
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [deleteUltimateBeneficialOwner] =
    useMutation<DeleteUltimateBeneficialOwnerData, DeleteUltimateBeneficialOwnerVars>(
      DELETE_ULTIMATE_BENEFICIAL_OWNER_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [ULTIMATE_BENEFICIAL_OWNERS_QUERY],
      })

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const handleResponse = (response: ExecutionResult, data?: string) => {
    if (data === 'OK!') {
      return closeDialog()
    }

    setErrorMsg(translateError(response))
  }

  const handleDelete = async () => {
    const response = await deleteUltimateBeneficialOwner({
      variables: {
        ultimateBeneficialOwnerId: ultimateBeneficialOwner.id,
      },
    })
    handleResponse(response, response.data?.deleteUltimateBeneficialOwner)
  }

  return (
    <React.Fragment>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
      >
        <Person />
        <Button
          variant='text'
          onClick={openDialog}
          size='large'
        >
          {ultimateBeneficialOwner.givenName} {ultimateBeneficialOwner.familyName}
        </Button>
        <IconButton
          onClick={handleDelete}
          edge='end'
          sx={{ '&:hover': { backgroundColor: 'transparent', color: 'error.main' } }}
        >
          <Delete />
        </IconButton>
      </Stack>
      <ErrorDisplay
        errorMsg={errorMsg}
        mt={2}
      />
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title={`${ultimateBeneficialOwner.givenName} ${ultimateBeneficialOwner.familyName}`}
      >
        <List disablePadding>
          <ListItem disableGutters>
            <ListItemText
              primary='Nacionalidad'
              secondary={findCountryByCode(ultimateBeneficialOwner.nationality)?.name}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary='Residencia en Chile'
              secondary={ultimateBeneficialOwner.isChileanResident ? 'Sí' : 'No'}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary='Es Persona Expuesta Políticamente (PEP)'
              secondary={ultimateBeneficialOwner.isPoliticallyExposed ? 'Sí' : 'No'}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary='Número de identificación'
              secondary={ultimateBeneficialOwner.identificationNumber}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary='Dirección'
              secondary={getFullAddress(ultimateBeneficialOwner)}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary='Relación con la empresa'
              secondary={<RolesList roles={ultimateBeneficialOwner.roles} />}
            />
          </ListItem>
          {hasOwnershipRole(ultimateBeneficialOwner.roles) && (
            <ListItem disablePadding>
              <ListItemText
                primary='Porcentaje de participación en la empresa'
                secondary={`${ultimateBeneficialOwner.participationPercentage} %`}
              />
            </ListItem>
          )}
        </List>
        <Button
          onClick={closeDialog}
          variant='contained'
          size='large'
          sx={{ mt: 1 }}
          fullWidth
        >
          Cerrar
        </Button>
      </Dialog>
    </React.Fragment>
  )
}
