import { gql } from '@apollo/client'

export type ElectronicSignature = {
  id: string
  imageUrl: string
}

export const ELECTRONIC_SIGNATURE_FIELDS = gql`
  fragment ElectronicSignatureFields on ElectronicSignature {
    id
    imageUrl
  }
`

export type ElectronicSignatureVars = Record<string, never>

export type ElectronicSignatureData = {
  electronicSignature?: ElectronicSignature
}

export const ELECTRONIC_SIGNATURE_QUERY = gql`
  ${ELECTRONIC_SIGNATURE_FIELDS}
  query ElectronicSignature {
    electronicSignature {
      ...ElectronicSignatureFields
    }
  }
`

export type UpdateElectronicSignatureVars = {
  imageUrl: string
}

export type UpdateElectronicSignatureData = {
  updateElectronicSignature: string
}

export const UPDATE_ELECTRONIC_SIGNATURE_MUTATION = gql`
  mutation UpdateElectronicSignature(
    $imageUrl: String!
  ) {
    updateElectronicSignature(
      input: {
        imageUrl: $imageUrl
      }
    )
  }
`
