import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Delete } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay } from '../components'
import {
  DELETE_USER_DOCUMENT_MUTATION,
  USER_DOCUMENTS_QUERY,
} from '../queries'
import { translateError } from '../services'

import type {
  DeleteUserDocumentData,
  DeleteUserDocumentVars,
} from '../queries'

type UserDocumentDeleterProps = {
  userDocumentId: string
}

export const UserDocumentDeleter = ({
  userDocumentId,
}: UserDocumentDeleterProps) => {
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
  const [updating, setUpdating] = React.useState(false)

  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)

  const [deleteUserDocument] =
    useMutation<DeleteUserDocumentData, DeleteUserDocumentVars>(DELETE_USER_DOCUMENT_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_DOCUMENTS_QUERY },
      ],
    })

  const handleConfirm = async () => {
    setUpdating(true)
    const response = await deleteUserDocument({
      variables: {
        userDocumentId,
      },
    })
    setUpdating(false)

    if (response.data?.deleteUserDocument) {
      closeDialog()
      return
    }

    setErrorMsg(translateError(response))
  }

  return (
    <React.Fragment>
      <IconButton
        aria-label='Eliminar documento'
        onClick={openDialog}
      >
        <Delete />
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Eliminar documento'
        contentText={<>Esta acción <strong>NO</strong> se puede revertir.</>}
      >
        <ErrorDisplay
          errorMsg={errorMsg}
          mt={2}
        />
        <ButtonsContainer mt={2}>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              onClick={closeDialog}
              disabled={updating}
            >
              Cancelar
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='error'
              variant='contained'
              onClick={handleConfirm}
              disabled={updating}
            >
              Eliminar
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Dialog>
    </React.Fragment>
  )
}
