import { gql } from '@apollo/client'

export type OtpProvisioningUriVars = Record<string, never>

export type OtpProvisioningUriData = {
  otpProvisioningUri: string
}

export const OTP_PROVISIONING_URI_QUERY = gql`
  query OtpProvisioningUri {
    otpProvisioningUri
  }
`

export type ProvisionOtpVars = {
  otp: string
}

export type ProvisionOtpData = {
  provisionOtp: string
}

export const PROVISION_OTP_MUTATION = gql`
  mutation ProvisionOtp(
    $otp: String!
  ) {
    provisionOtp(
      input: {
        otp: $otp
      }
    )
  }
`
