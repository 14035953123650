import * as React from 'react'

import { Wrapper } from './src/wrapper'

import type { GatsbySSR } from 'gatsby'
import type { DynamicColors, ThemeColors, Themes } from 'shared/contexts'

export const wrapRootElement: GatsbySSR['wrapRootElement'] = (
  { element },
  { dynamicColors, themeColors, themes },
) => (
  <Wrapper
    dynamicColors={dynamicColors as DynamicColors}
    themeColors={themeColors as ThemeColors}
    themes={themes as Themes}
  >
    {element}
  </Wrapper>
)
