import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'

import {
  ButtonContainer,
  ButtonsContainer,
  ErrorDisplay,
  Loading,
} from '../../../components'
import {
  OccupationProfileFields,
  getOccupationProfileInitialValues as getInitialValues,
  occupationProfileValidationSchema as validationSchema,
} from '../../../forms'
import {
  OCCUPATION_PROFILE_QUERY,
  UPDATE_OCCUPATION_PROFILE_MUTATION,
} from '../../../queries'
import { setFormError, translateError } from '../../../services'

import type { OccupationProfileFormValues as FormValues } from '../../../forms'
import type {
  OccupationProfileData,
  OccupationProfileVars,
  UpdateOccupationProfileData,
  UpdateOccupationProfileVars,
} from '../../../queries'

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  handleBack,
}: FormikProps<FormValues> & { handleBack: () => void }) => (
  <Form>
    <OccupationProfileFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={handleBack}
          variant='outlined'
        >
          Atrás
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          Siguiente
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type OccupationProfileStepProps = {
  handleBack: () => void
  handleNext: () => void
}

export const OccupationProfileStep = ({
  handleBack,
  handleNext,
}: OccupationProfileStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const { loading, data } =
    useQuery<OccupationProfileData, OccupationProfileVars>(OCCUPATION_PROFILE_QUERY)

  const [updateOccupationProfile] =
    useMutation<UpdateOccupationProfileData, UpdateOccupationProfileVars>(
      UPDATE_OCCUPATION_PROFILE_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          OCCUPATION_PROFILE_QUERY,
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateOccupationProfile({
      variables: values,
    })

    if (response.data?.updateOccupationProfile === 'OK!') {
      return handleNext()
    }

    setFormError(formRef, translateError(response))
  }

  return loading ? (
    <Loading />
  ) : (
    <Formik
      innerRef={formRef}
      initialValues={getInitialValues(data?.occupationProfile)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <StepForm
          {...props}
          handleBack={handleBack}
        />
      )}
    </Formik>
  )
}
