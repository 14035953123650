import * as React from 'react'

import { Email } from '@mui/icons-material'
import { Button } from '@mui/material'

import { AppContext } from '../contexts'

import type { ButtonProps } from '@mui/material'

type SupportMailLinkProps = Pick<ButtonProps, 'variant'>

export const SupportMailLink = ({
  variant = 'contained',
}: SupportMailLinkProps) => {
  const { supportEmail } = React.useContext(AppContext)

  return (
    <Button
      target='_blank'
      href={`mailto:${supportEmail}?subject=Ayuda`}
      rel='noopener noreferrer'
      variant={variant}
      startIcon={<Email />}
      size='large'
    >
      {supportEmail}
    </Button>
  )
}
