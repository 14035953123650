import * as React from 'react'

import { TextField } from '@mui/material'
import { useFormikContext } from 'formik'

import type { TextFieldProps } from '@mui/material'

type FormikValues = {
  [key: string]: string
}

type SelectFieldProps =
  Omit<TextFieldProps, 'select' | 'type' | 'value' | 'error' | 'helperText' | 'onBlur' | 'onChange'> & {
    name: string
    onChange?: (newValue: string) => void
  }

export const SelectField = ({
  name,
  onChange,
  children,
  ...rest
}: SelectFieldProps) => {
  const {
    errors,
    values,
    setFieldValue,
    setFieldTouched,
    touched,
  } = useFormikContext<FormikValues>()

  return (
    <TextField
      select
      type='text'
      value={values[name]}
      error={touched[name] && !!errors[name]}
      helperText={touched[name] &&  errors[name]}
      onBlur={() => setFieldTouched(name, true)}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        setFieldValue(name, newValue)
        onChange && onChange(newValue)
      }}
      {...rest}
    >
      {children}
    </TextField>
  )
}
