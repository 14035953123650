import * as React from 'react'

import { Close, GppGood, Info, Report, Warning } from '@mui/icons-material'
import { IconButton, Paper, Stack, Typography } from '@mui/material'

const colorMap = {
  'info': {
    icon: <Info />,
    color: 'info.contrastText',
    bgcolor: 'info.main',
  },
  'warning': {
    icon: <Warning />,
    color: 'warning.contrastText',
    bgcolor: 'warning.main',
  },
  'error': {
    icon: <Report />,
    color: 'error.contrastText',
    bgcolor: 'error.main',
  },
  'success': {
    icon: <GppGood />,
    color: 'success.contrastText',
    bgcolor: 'success.main',
  },
}

export type BannerProps = {
  id: string
  body: React.ReactNode
  action?: React.ReactNode
  closeBanner?: (id: string) => void
  color?: 'info' | 'warning' | 'error' | 'success'
}

export const Banner = ({
  id,
  body,
  action,
  closeBanner,
  color = 'info',
}: BannerProps) => {
  const map = colorMap[color]
  return (
    <Paper
      elevation={12}
      sx={{
        color: map.color,
        bgcolor: map.bgcolor,
        px: 2,
        py: 1,
        mb: 1,
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        spacing={2}
      >
        {map.icon}
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          flexWrap='wrap'
          flexGrow={1}
          spacing={2}
        >
          <Typography
            variant='body2'
            textAlign='center'
            my={1}
          >
            {body}
          </Typography>
          {action}
        </Stack>
        {closeBanner && (
          <IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={() => closeBanner(id)}
          >
            <Close fontSize='inherit' />
          </IconButton>
        )}
      </Stack>
    </Paper>
  )
}
