import * as React from 'react'

import { findCountryByCode } from './countries'
import { ROLE_LABELS, TOP_MANAGEMENT_ROLE_LABELS } from '../queries'

import type { Role, UltimateBeneficialOwner } from '../queries'

export const hasOwnershipRole = (roles: string[]) => (
  roles.includes('ULTIMATE_BENEFICIARY') || roles.includes('EFFECTIVE_CONTROL')
)

export const withoutTopManagementRoles = (roles: string[]) => (
  roles.filter((role) => !Object.keys(TOP_MANAGEMENT_ROLE_LABELS).includes(role))
)

export const RolesList = ({ roles }: { roles: Role[] }) => {
  return (
    <React.Fragment>
      {roles.map((role, index) => (
        <span
          key={index}
          style={{ display: 'list-item', listStyleType: 'disc', listStylePosition: 'inside' }}
        >
          {ROLE_LABELS[role]}
        </span>
      ))}
    </React.Fragment>
  )
}

export const getFullAddress = (ubo: UltimateBeneficialOwner) => (
  `${findCountryByCode(ubo.countryCode)?.name}, ${ubo.city}, ${ubo.streetAddress}`
)
