import { gql } from '@apollo/client'

export type DepositAddress = {
  id: string
  value: string
  blockchain: string
}

export const DEPOSIT_ADDRESS_FIELDS = gql`
  fragment DepositAddressFields on DepositAddress {
    id
    value
    blockchain
  }
`

export type DepositAddressesVars = Record<string, never>

export type DepositAddressesData = {
  depositAddresses: DepositAddress[]
}

export const DEPOSIT_ADDRESSES_QUERY = gql`
  ${DEPOSIT_ADDRESS_FIELDS}
  query DepositAddresses {
    depositAddresses {
      ...DepositAddressFields
    }
  }
`

export type CreateDepositAddressVars = {
  blockchain: string
  symbol: string
}

export type CreateDepositAddressData = {
  createDepositAddress: string
}

export const CREATE_DEPOSIT_ADDRESS_MUTATION = gql`
  mutation CreateDepositAddress(
    $blockchain: String!
    $symbol: String!
  ) {
    createDepositAddress(
      input: {
        blockchain: $blockchain
        symbol: $symbol
      }
    )
  }
`
