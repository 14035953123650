import * as React from 'react'

import { Box, Checkbox, InputAdornment, TextField } from '@mui/material'

import { AppContainer, AssetBadge } from '../components'

import type { AppContainerProps } from '../components'
import type { AutocompleteRenderInputParams, CheckboxProps } from '@mui/material'

type OptionCheckboxProps = CheckboxProps

export const OptionCheckbox = (props: OptionCheckboxProps) => (
  <Checkbox {...props} />
)

type OptionDisplayProps = React.HTMLAttributes<HTMLLIElement>

export const OptionDisplay = ({ children, ...rest }: OptionDisplayProps) => (
  <Box
    component='li'
    sx={{
      '& > img': {
        mr: 2,
        flexShrink: 0,
      },
      '& > .MuiCheckbox-root, & > .MuiSvgIcon-root': {
        mr: 1,
      },
    }}
    {...rest}
  >
    {children}
  </Box>
)

type OptionTextFieldProps = {
  startAdornment?: React.ReactNode
  name: string
  label: string
  params: AutocompleteRenderInputParams
}

export const OptionTextField = ({
  startAdornment,
  name,
  label,
  params,
}: OptionTextFieldProps) => (
  <TextField
    {...params}
    label={label}
    inputProps={{
      ...params.inputProps,
      name,
      autoComplete: 'off', // disable autocomplete and autofill
    }}
    InputProps={{
      ...params.InputProps,
      ...(startAdornment && { startAdornment }),
    }}
  />
)

type AutocompleteContainerProps = Omit<AppContainerProps, 'md' | 'sx'>

export const AutocompleteContainer = ({ children, ...rest }: AutocompleteContainerProps) => (
  <AppContainer
    md={6}
    sx={{ p: 3 }}
    {...rest}
  >
    {children}
  </AppContainer>
)

type MarketAssetAdornmentProps = {
  symbol?: string
}

export const MarketAssetAdornment = ({
  symbol,
}: MarketAssetAdornmentProps) => (
  symbol ? (
    <InputAdornment
      position='start'
      sx={{ pl: 1 }}
    >
      <AssetBadge
        symbol={symbol}
        height={20}
      />
    </InputAdornment>
  ) : (
    null
  )
)
