import * as React from 'react'

import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import * as Yup from 'yup'

import { CIVIL_STATE_LABELS } from '../queries'

export type CivilStateFormValues = {
  civilState: string
}

export const getCivilStateInitialValues =
  (data?: { civilState?: string }): CivilStateFormValues => ({
    civilState: data?.civilState || '',
  })

export const civilStateValidationSchema: Yup.SchemaOf<CivilStateFormValues> =
  Yup.object({
    civilState: Yup.string()
      .required('Este campo es obligatorio')
      .oneOf(Object.keys(CIVIL_STATE_LABELS)),
  })

export const CivilStateFields = () => (
  <Field
    required
    name='civilState'
    type='text'
    label='Estado civil'
    component={Select}
  >
    {Object.entries(CIVIL_STATE_LABELS).map(
      ([civilStateType, civilStateLabel]) => (
        <MenuItem
          key={civilStateType}
          value={civilStateType}
        >
          {civilStateLabel}
        </MenuItem>
      ))}
  </Field>
)
