import { gql } from '@apollo/client'

type NotificationType = 'BULK_PURCHASE'
  | 'DEPOSIT_ADDRESS'
  | 'FUND_PURCHASE'
  | 'FUND_SALE'
  | 'PURCHASE'
  | 'SWAP'
  | 'WITHDRAW_BANK'
  | 'WITHDRAW_BLOCKCHAIN'

export type NotifyUserRequestVars = {
  content: string[]
  notificationType: NotificationType
}

export type NotifyUserRequestData = {
  notifyUserRequest: string[]
}

export const NOTIFY_USER_REQUEST_MUTATION = gql`
  mutation NotifyUserRequest(
    $content: [String!]!
    $notificationType: String!
  ) {
    notifyUserRequest(
      input: {
        content: $content
        notificationType: $notificationType
      }
    )
  }
`
