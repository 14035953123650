import * as React from 'react'

import { Grid } from '@mui/material'

import type { GridProps } from '@mui/material'

export type ButtonContainerProps = GridProps

export const ButtonContainer = ({
  children,
  ...rest
}: ButtonContainerProps) => (
  <Grid
    item
    display='flex'
    {...rest}
  >
    {children}
  </Grid>
)

export type ButtonsContainerProps = GridProps

export const ButtonsContainer = ({
  children,
  ...rest
}: ButtonsContainerProps) => (
  <Grid
    item
    container
    spacing={1}
    xs={12}
    sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
    {...rest}
  >
    {children}
  </Grid>
)
