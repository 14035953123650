import { gql } from '@apollo/client'

export type EmploymentSituation = 'EMPLOYEE' | 'INDEPENDENT' | 'RETIRED' | 'UNEMPLOYED' | 'STUDENT'

export const EMPLOYMENT_SITUATION_LABELS: { [key in EmploymentSituation]: string } = {
  'EMPLOYEE': 'Empleado(a)',
  'STUDENT': 'Estudiante',
  'INDEPENDENT': 'Independiente',
  'RETIRED': 'Jubilado(a)',
  'UNEMPLOYED': 'Sin empleo',
}

export type IncomeRange = 'LOW' | 'MEDIUM_LOW' | 'MEDIUM_HIGH' | 'HIGH'

export const INCOME_RANGE_LABELS: { [key in IncomeRange]: string } = {
  'LOW': 'Menos de 1.000.000 CLP',
  'MEDIUM_LOW': '1.000.000 CLP - 2.500.000 CLP',
  'MEDIUM_HIGH': '2.500.000 CLP - 5.000.000 CLP',
  'HIGH': 'Más de 5.000.000 CLP',
}

export type OccupationProfile = {
  id: string
  employmentSituation: EmploymentSituation
  profession: string
  incomeRange: IncomeRange
  company?: string
  companyIdentificationNumber?: string
  jobTitle?: string
}

export const OCCUPATION_PROFILE_FIELDS = gql`
  fragment OccupationProfileFields on OccupationProfile {
    id
    employmentSituation
    profession
    incomeRange
    company
    companyIdentificationNumber
    jobTitle
  }
`

export type OccupationProfileVars = Record<string, never>

export type OccupationProfileData = {
  occupationProfile: OccupationProfile
}

export const OCCUPATION_PROFILE_QUERY = gql`
  ${OCCUPATION_PROFILE_FIELDS}
  query OccupationProfile {
    occupationProfile {
      ...OccupationProfileFields
    }
  }
`

export type UpdateOccupationProfileVars = {
  employmentSituation: string
  profession: string
  incomeRange: string
  company?: string
  companyIdentificationNumber?: string
  jobTitle?: string
}

export type UpdateOccupationProfileData = {
  updateOccupationProfile: string
}

export const UPDATE_OCCUPATION_PROFILE_MUTATION = gql`
  mutation UpdateOccupationProfile(
    $employmentSituation: String!
    $profession: String!
    $incomeRange: String!
    $company: String
    $companyIdentificationNumber: String
    $jobTitle: String
  ) {
    updateOccupationProfile(
      input: {
        employmentSituation: $employmentSituation
        profession: $profession
        incomeRange: $incomeRange
        company: $company
        companyIdentificationNumber: $companyIdentificationNumber
        jobTitle: $jobTitle
      }
    )
  }
`
