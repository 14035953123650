import * as React from 'react'

import { Grid, Paper } from '@mui/material'

import type { GridSize, PaperProps } from '@mui/material'

export type AppContainerProps = Omit<PaperProps, 'elevation' | 'variant'> & {
  sm?: boolean | GridSize
  md?: boolean | GridSize
  lg?: boolean | GridSize
  xl?: boolean | GridSize
}

export const AppContainer = ({
  sm = false,
  md = false,
  lg = false,
  xl = false,
  sx = [],
  children,
  ...rest
}: AppContainerProps) => (
  <Grid
    item
    xs={12}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
  >
    <Paper
      elevation={12}
      variant='elevation'
      sx={[
        {
          borderRadius: 2,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </Paper>
  </Grid>
)
