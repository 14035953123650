import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import {
  AccountBalanceWallet,
  AlternateEmail,
  BorderColor,
  Email,
  Google,
  HourglassEmpty,
  Logout,
  Refresh,
  Security,
  Settings,
} from '@mui/icons-material'
import {
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from '@mui/material'
import { navigate } from '@reach/router'

import { FirebaseContext } from '../contexts'
import { ACCOUNT_INFORMATION_QUERY, USER_LOGOUT_MUTATION } from '../queries'
import { apolloClient, clearCredential, getCredential } from '../services'

import type {
  AccountInformationData,
  AccountInformationVars,
  UserLogoutData,
  UserLogoutVars,
} from '../queries'

type ConfigurationPages = 'signature' | 'bank' | 'withdrawal-address'

type AppNavElementsProps = {
  configurationPages?: ConfigurationPages[]
}

const RegistrationLogo = () => {
  switch (getCredential()?.provider) {
  case 'email': return <Email fontSize='small' />
  case 'google': return <Google fontSize='small' />
  }
}

export const AppNavElements = ({
  configurationPages,
}: AppNavElementsProps) => {
  const { analytics, logEvent } = React.useContext(FirebaseContext)
  const [refreshing, setRefreshing] = React.useState(false)
  const [loggingOut, setLoggingOut] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const { loading, data } =
    useQuery<AccountInformationData, AccountInformationVars>(ACCOUNT_INFORMATION_QUERY)

  const [userLogout] =
    useMutation<UserLogoutData, UserLogoutVars>(USER_LOGOUT_MUTATION, {
      errorPolicy: 'all',
    })

  const refresh = async () => {
    setRefreshing(true)
    await apolloClient.refetchQueries({ include: 'active' })
    setRefreshing(false)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    setLoggingOut(true)
    await userLogout()
    clearCredential()

    if (analytics) {
      logEvent(analytics, 'logout', { method: 'dashboard_avatar' })
    }

    window.location.href = '/auth/login/'
  }

  const goToPage = (page: string) => {
    navigate(`/app/${page}/`)
    handleClose()
  }

  return (
    <React.Fragment>
      <IconButton
        id='refresh-button'
        aria-label='Actualizar'
        disabled={refreshing}
        onClick={refresh}
        sx={(theme) => ({
          display: 'none',
          [theme.breakpoints.up('sm')]: {
            display: 'flex',
          },
        })}
      >
        {refreshing ? <HourglassEmpty /> : <Refresh />}
      </IconButton>
      <Button
        id='settings-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        aria-label='Configuración'
        onClick={handleClick}
        endIcon={<Settings />}
        variant='contained'
        color='primary'
        sx={(theme) => ({
          py: 1,
          bgcolor: theme.palette.primary.main,
          boxShadow: theme.shadows[1],
          borderRadius: 8,
          maxWidth: 'min(33vw, 200px)',
        })}
      >
        {loading ? (
          <Skeleton
            width='20vw'
            animation='wave'
            sx={{ bgcolor: 'grey.700' }}
          />
        ) : (
          <Typography
            component='span'
            sx={(theme) => ({
              pt: '1px',
              minWidth: 0,
              lineHeight: 1,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              [theme.breakpoints.down('sm')]: {
                fontSize: 0,
                ':first-letter': {
                  fontSize: theme.typography.body1.fontSize,
                },
              },
            })}
          >
            {data?.accountInformation.nickName}
          </Typography>
        )}
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'settings-button' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              boxShadow: 12,
              overflow: 'visible',
              mt: 0.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 16,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(-135deg)',
                zIndex: 0,
              },
            },
          },
        }}
      >
        {loggingOut ? (
          <MenuItem>
            Cerrando sesión...
          </MenuItem>
        ) : (
          [
            (
              <MenuItem
                key={'uid'}
                disabled
              >
                <ListItemIcon>
                  <RegistrationLogo />
                </ListItemIcon>
                {data?.accountInformation.email}
              </MenuItem>
            ),
            (
              <MenuItem
                key='security'
                onClick={() => goToPage('security')}
              >
                <ListItemIcon>
                  <Security fontSize='small' />
                </ListItemIcon>
                Seguridad
              </MenuItem>
            ),
            (
              configurationPages?.includes('withdrawal-address') && (
                <MenuItem
                  key='withdrawal-address'
                  onClick={() => goToPage('withdrawal-address')}
                >
                  <ListItemIcon>
                    <AlternateEmail fontSize='small' />
                  </ListItemIcon>
                  Dirección de retiro
                </MenuItem>
              )
            ),
            (
              configurationPages?.includes('signature') && (
                <MenuItem
                  key='signature'
                  onClick={() => goToPage('signature')}
                >
                  <ListItemIcon>
                    <BorderColor fontSize='small' />
                  </ListItemIcon>
                  Firma electrónica
                </MenuItem>
              )
            ),
            (
              configurationPages?.includes('bank') && (
                <MenuItem
                  key='bank'
                  onClick={() => goToPage('bank')}
                >
                  <ListItemIcon>
                    <AccountBalanceWallet fontSize='small' />
                  </ListItemIcon>
                  Cuentas bancarias
                </MenuItem>
              )
            ),
            (
              <MenuItem
                key='logout'
                onClick={handleLogout}
              >
                <ListItemIcon>
                  <Logout fontSize='small' />
                </ListItemIcon>
                Cerrar sesión
              </MenuItem>
            ),
          ]
        )}
      </Menu>
    </React.Fragment>
  )
}
