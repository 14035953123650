import { gql } from '@apollo/client'

export type PhoneNumber = {
  id: string
  value: string
}

export const PHONE_NUMBER_FIELDS = gql`
  fragment PhoneNumberFields on PhoneNumber {
    id
    value
  }
`

export type PhoneNumberVars = Record<string, never>

export type PhoneNumberData = {
  phoneNumber: PhoneNumber
}

export const PHONE_NUMBER_QUERY = gql`
  ${PHONE_NUMBER_FIELDS}
  query PhoneNumber {
    phoneNumber {
      ...PhoneNumberFields
    }
  }
`
