import { BUSINESS_CONTENT_TYPES, PERSON_CONTENT_TYPES } from '../queries'

const MAX_FILE_SIZE = 16 * 1024 * 1024

export const hasValidContentType = (isBusiness: boolean, file: File | undefined) => {
  if (!file) {
    return true
  }

  const contentTypes = isBusiness ? BUSINESS_CONTENT_TYPES : PERSON_CONTENT_TYPES

  return contentTypes.includes(file.type)
}

export const hasValidSize = (file: File | undefined) => (
  !file || file.size <= MAX_FILE_SIZE
)

export const minPoiDate = () => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0, 0, 0, 0)
  return tomorrow
}
