import { gql } from '@apollo/client'

import { STORAGE_POST_FIELDS } from './storage_post'

import type { StoragePost } from './storage_post'

export type LivenessContentType = 'video/mp4' | 'video/webm' | 'image/jpg'

export type LivenessDocumentType = 'LIVENESS' | 'FACE_IMAGE'

export type LivenessProof = {
  id: string
  contentType: string
  documentType: LivenessDocumentType
  storagePost: StoragePost
}

export const LIVENESS_PROOF_FIELDS = gql`
  ${STORAGE_POST_FIELDS}
  fragment LivenessProofFields on LivenessProof {
    id
    contentType
    documentType
    storagePost {
      ...StoragePostFields
    }
  }
`

export type CreateLivenessProofVars = {
  contentType: string
  documentType: string
}

export type CreateLivenessProofData = {
  createLivenessProof: LivenessProof
}

export const CREATE_LIVENESS_PROOF_MUTATION = gql`
  ${LIVENESS_PROOF_FIELDS}
  mutation CreateLivenessProof(
    $contentType: String!
    $documentType: String!
  ) {
    createLivenessProof(
      input: {
        contentType: $contentType
        documentType: $documentType
      }
    ) {
      ...LivenessProofFields
    }
  }
`

export type UpdateLivenessProofVars = {
  id: string
}

export type UpdateLivenessProofData = {
  updateLivenessProof: string
}

export const UPDATE_LIVENESS_PROOF_MUTATION = gql`
  mutation UpdateLivenessProof(
    $id: ID!
  ) {
    updateLivenessProof(
      input: {
        id: $id
      }
    )
  }
`

export type LivenessProofView = {
  id: string
  contentType: LivenessContentType
  documentType: LivenessDocumentType
  storageUrl?: string
}

export const LIVENESS_PROOF_VIEW_FIELDS = gql`
  fragment LivenessProofViewFields on LivenessProofView {
    id
    contentType
    documentType
    storageUrl
  }
`

export type LivenessProofsVars = Record<string, never>

export type LivenessProofsData = {
  livenessProofs: LivenessProofView[]
}

export const LIVENESS_PROOFS_QUERY = gql`
  ${LIVENESS_PROOF_VIEW_FIELDS}
  query LivenessProofs {
    livenessProofs {
      ...LivenessProofViewFields
    }
  }
`
