import * as React from 'react'

import {
  MenuItem,
  Stack,
} from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { Select, TextField } from 'formik-mui'
import { MobileDatePicker } from 'formik-mui-x-date-pickers'
import * as Yup from 'yup'

import {
  SORTED_COUNTRIES,
  fromISO8601Date,
  hasValidRut,
  maxDate,
} from '../services'

export type BaseIdentityFormValues = {
  givenName: string
  familyName: string
  birthdate: Date | null
  nationality: string
  identificationNumber: string
}

type InitialValuesProps = {
  givenName: string
  familyName: string
  birthdate: string
  nationality: string
  identificationNumber: string
}

export const getBaseIdentityInitialValues =
  (data?: InitialValuesProps): BaseIdentityFormValues => ({
    givenName: data?.givenName || '',
    familyName: data?.familyName || '',
    birthdate: fromISO8601Date(data?.birthdate),
    nationality: data?.nationality || '',
    identificationNumber: data?.identificationNumber || '',
  })

export const baseIdentityValidationSchema: Yup.SchemaOf<BaseIdentityFormValues> =
  Yup.object({
    givenName: Yup.string()
      .required('Este campo es obligatorio')
      .min(2, 'Debes ingresar al menos dos caracteres')
      .max(64, 'No puede tener más de 64 caracteres'),
    familyName: Yup.string()
      .required('Este campo es obligatorio')
      .min(2, 'Debes ingresar al menos dos caracteres')
      .max(64, 'No puede tener más de 64 caracteres'),
    birthdate: Yup.date()
      .typeError('La fecha ingresada es inválida')
      .required('Este campo es obligatorio')
      .max(maxDate(), 'Debes ser mayor de edad'),
    nationality: Yup.string()
      .required('Este campo es obligatorio'),
    identificationNumber: Yup.string()
      .required('Este campo es obligatorio')
      .test({
        message: 'El rut ingresado no es valido',
        test: (documentNumber, context) => {
          if (context.parent.nationality === 'CL') {
            return hasValidRut(documentNumber)
          }
          return true
        },
      }),
  })

export const BaseIdentityFields = <FormValues extends BaseIdentityFormValues>() => {
  const { values } = useFormikContext<FormValues>()

  return (
    <Stack spacing={3}>
      <Field
        required
        name='givenName'
        type='text'
        label='Nombre(s)'
        component={TextField}
        fullWidth
      />
      <Field
        required
        name='familyName'
        type='text'
        label='Apellido(s)'
        component={TextField}
        fullWidth
      />
      <Field
        required
        name='birthdate'
        type='date'
        label='Fecha de nacimiento'
        component={MobileDatePicker}
        views={['year', 'month', 'day']}
        inputFormat='yyyy/MM/dd'
        toolbarTitle='Ingresa tu fecha de nacimiento'
        disableFuture
        closeOnSelect
      />
      <Field
        required
        name='nationality'
        type='text'
        label='País de origen'
        component={Select}
        fullWidth
      >
        {SORTED_COUNTRIES.map((country) => (
          <MenuItem
            key={country.iso}
            value={country.iso}
          >
            {country.name}
          </MenuItem>
        ))}
      </Field>
      <Field
        required
        name='identificationNumber'
        type='text'
        label={values.nationality === 'CL' ? 'Rut' : 'DNI'}
        component={TextField}
        fullWidth
      />
    </Stack>
  )
}
