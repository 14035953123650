import * as React from 'react'

import { Button } from '@mui/material'

import type { ButtonProps } from '@mui/material'

const colorMap = {
  'info': {
    color: 'info',
    bgcolor: 'info.dark',
  },
  'warning': {
    color: 'warning',
    bgcolor: 'warning.dark',
  },
  'error': {
    color: 'error',
    bgcolor: 'error.dark',
  },
  'success': {
    color: 'success',
    bgcolor: 'success.dark',
  },
}

export type BannerButtonProps = Omit<ButtonProps, 'color' | 'size' | 'sx' | 'variant'> & {
  color?: 'info' | 'warning' | 'error' | 'success'
}

export const BannerButton = ({
  color = 'info',
  children,
  ...rest
}: BannerButtonProps) => {
  const map = colorMap[color]
  return (
    <Button
      variant='contained'
      size='small'
      color={map.color as ButtonProps['color']}
      sx={{ bgcolor: map.bgcolor, my: 1 }}
      {...rest}
    >
      {children}
    </Button>
  )
}
