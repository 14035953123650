import { gql } from '@apollo/client'

export type PurchaseQuote = {
  id: string
  cryptoAmount: number
  remainingSeconds: number
}

export type CreatePurchaseQuoteVars = {
  fiatAmount: number
}

export type CreatePurchaseQuoteData = {
  createPurchaseQuote: PurchaseQuote
}

export const CREATE_PURCHASE_QUOTE_MUTATION = gql`
  mutation CreatePurchaseQuote(
    $fiatAmount: Float!
  ) {
    createPurchaseQuote(
      input: {
        fiatAmount: $fiatAmount
      }
    ) {
      id
      cryptoAmount
      remainingSeconds
    }
  }
`

export type Payment = {
  paymentMethodDetails: string
  providedAction: string | null
}

export type CreatePaymentVars = {
  quoteId: string
}

export type CreatePaymentData = {
  createPayment: Payment
}

export const CREATE_PAYMENT_MUTATION = gql`
  mutation CreatePayment(
    $quoteId: String!
  ) {
    createPayment(
      input: {
        quoteId: $quoteId
      }
    ) {
      paymentMethodDetails
      providedAction
    }
  }
`
