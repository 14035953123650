import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'

import { ButtonContainer, ButtonsContainer, ErrorDisplay, Loading } from '../../components'
import {
  LegalAddressFields,
  getLegalAddressInitialValues as getInitialValues,
  legalAddressValidationSchema as validationSchema,
} from '../../forms'
import { LEGAL_ADDRESS_QUERY, UPDATE_LEGAL_ADDRESS_MUTATION } from '../../queries'
import { setFormError, translateError } from '../../services'

import type { LegalAddressFormValues as FormValues } from '../../forms'
import type {
  LegalAddressData,
  LegalAddressVars,
  UpdateLegalAddressData,
  UpdateLegalAddressVars,
} from '../../queries'

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  handleBack,
}: FormikProps<FormValues> & { handleBack: () => void }) => (
  <Form>
    <LegalAddressFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={handleBack}
          variant='outlined'
        >
          Atrás
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          Siguiente
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type LegalAddressStepProps = {
  handleBack: () => void
  handleNext: () => void
}

export const LegalAddressStep = ({
  handleBack,
  handleNext,
}: LegalAddressStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const { loading, data } = useQuery<LegalAddressData, LegalAddressVars>(LEGAL_ADDRESS_QUERY)

  const [updateLegalAddress] =
    useMutation<UpdateLegalAddressData, UpdateLegalAddressVars>(UPDATE_LEGAL_ADDRESS_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        LEGAL_ADDRESS_QUERY,
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateLegalAddress({
      variables: values,
    })

    if (response.data?.updateLegalAddress === 'OK!') {
      return handleNext()
    }

    setFormError(formRef, translateError(response))
  }

  return loading ? (
    <Loading />
  ) : (
    <Formik
      innerRef={formRef}
      initialValues={getInitialValues(data?.legalAddress)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <StepForm
          handleBack={handleBack}
          {...props}
        />
      )}
    </Formik>
  )
}
