import * as React from 'react'

import { useQuery } from '@apollo/client'

import { OnboardingFormKYB } from './KYB'
import { OnboardingFormKYC } from './KYC'
import { UserTypeStep } from './user_type_step'
import { Loading } from '../../components'
import { ACCOUNT_INFORMATION_QUERY } from '../../queries'

import type { AccountInformationData, AccountInformationVars } from '../../queries'

export const OnboardingAdvanced = () => {
  const { loading, data } =
    useQuery<AccountInformationData, AccountInformationVars>(ACCOUNT_INFORMATION_QUERY)

  if (loading) {
    return <Loading />
  }

  const isBusiness = data?.accountInformation.isBusiness

  return (isBusiness === null) ? (
    <UserTypeStep />
  ) : (
    isBusiness ? <OnboardingFormKYB /> : <OnboardingFormKYC />
  )
}
