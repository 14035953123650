import * as React from 'react'

import { useMutation } from '@apollo/client'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Currency,
  ErrorDisplay,
} from '../../components'
import {
  CREATE_BLOCKCHAIN_WITHDRAWAL_MUTATION,
  RENEW_AUTHORIZATION_CODE_MUTATION,
  USER_OPERATIONS_QUERY,
} from '../../queries'
import { setFormError, translateError } from '../../services'

import type {
  CreateBlockchainWithdrawalData,
  CreateBlockchainWithdrawalVars,
  MarketAsset,
  RenewAuthorizationCodeData,
  RenewAuthorizationCodeVars,
} from '../../queries'
import type { FormikProps } from 'formik'

type FormValues = {
  authorizationCode: string
}

const initialValues: FormValues = {
  authorizationCode: '',
}

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    authorizationCode: Yup.string()
      .matches(/^[A-Z0-9]*$/, 'El código solo puede contener letras o números')
      .required('Este campo es obligatorio'),
  })

type InnerFormProps = FormikProps<FormValues> & {
  handleBack: () => void
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  handleBack,
}: InnerFormProps) => (
  <Form>
    <Typography>
      Ingresa el código que enviamos a tu email para autorizar esta operación.
    </Typography>
    <Field
      required
      inputProps={{
        autoComplete: 'off',
      }}
      name='authorizationCode'
      type='text'
      label={'Código de autorización'}
      component={TextField}
      margin='normal'
      fullWidth
    />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={handleBack}
          variant='outlined'
        >
          Regresar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          Confirmar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type AuthCodeRenewerProps = {
  handleBack: () => void
  setAuthCodeRenewed: (value: boolean) => void
}

const AuthCodeRenewer = ({
  handleBack,
  setAuthCodeRenewed,
}: AuthCodeRenewerProps) => {
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()
  const [updating, setUpdating] = React.useState(false)

  const [renewAuthorizationCode] =
    useMutation<RenewAuthorizationCodeData, RenewAuthorizationCodeVars>(
      RENEW_AUTHORIZATION_CODE_MUTATION, {
        errorPolicy: 'all',
      })

  const handleClick = async () => {
    setUpdating(true)
    const response = await renewAuthorizationCode()
    setUpdating(false)

    if (response.data?.renewAuthorizationCode) {
      setAuthCodeRenewed(true)
    } else {
      setErrorMsg(translateError(response))
    }
  }

  return (
    <React.Fragment>
      <Typography>
        Presiona el botón para que enviemos un código a tu email que te permitirá
        autorizar esta operación.
      </Typography>
      <ErrorDisplay
        errorMsg={errorMsg}
        mt={2}
      />
      <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={updating}
            onClick={handleBack}
            variant='outlined'
          >
            Regresar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={updating}
            onClick={handleClick}
            variant='contained'
          >
            Obtener código
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </React.Fragment>
  )
}

type AuthCodeStepProps = {
  marketAsset: MarketAsset | null
  blockchain: string
  address: string
  amount: number
  handleBack: () => void
  handleNext: () => void
}

export const AuthCodeStep = ({
  marketAsset,
  blockchain,
  address,
  amount,
  handleBack,
  handleNext,
}: AuthCodeStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [authCodeRenewed, setAuthCodeRenewed] = React.useState(false)

  const [createBlockchainWithdrawal] =
    useMutation<CreateBlockchainWithdrawalData, CreateBlockchainWithdrawalVars>(
      CREATE_BLOCKCHAIN_WITHDRAWAL_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          USER_OPERATIONS_QUERY,
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    if (!marketAsset || !blockchain || !address || !amount) {
      setFormError(formRef, 'Los campos introducidos al formulario son inválidos')
      return
    }

    const response = await createBlockchainWithdrawal({
      variables: {
        marketAssetId: marketAsset.id,
        blockchain: blockchain,
        address: address,
        amount: amount,
        authorizationCode: values.authorizationCode,
      },
    })

    if (response.data?.createBlockchainWithdrawal) {
      handleNext()
    } else {
      setFormError(formRef, translateError(response))
    }
  }

  return (
    <React.Fragment>
      <List
        disablePadding
        aria-label='Autoriza tu retiro'
      >
        <ListItem
          divider
          disableGutters
          sx={{ pt: 0, mb: 1 }}
        >
          <ListItemText
            primary='Autoriza tu retiro'
            primaryTypographyProps={{ variant: 'h6', textAlign: 'center' }}
          />
        </ListItem>
        <ListItem
          divider
          disableGutters
        >
          <ListItemText primary='Cantidad' />
          <ListItemText
            primary={(
              <Currency
                currency={marketAsset?.symbol || '-'}
                value={amount}
              />
            )}
            primaryTypographyProps={{ textAlign: 'right' }}
          />
        </ListItem>
        <ListItem
          divider
          disableGutters
        >
          <ListItemText primary='Comisión de red' />
          <ListItemText
            primary='Incluida'
            primaryTypographyProps={{ textAlign: 'right' }}
          />
        </ListItem>
        <ListItem
          divider
          disableGutters
        >
          <ListItemText primary='Blockchain' />
          <ListItemText
            primary={blockchain}
            primaryTypographyProps={{ textAlign: 'right' }}
          />
        </ListItem>
        <ListItem
          divider
          disableGutters
        >
          <ListItemText primary='Dirección' />
          <ListItemText
            primary={address}
            primaryTypographyProps={{ fontFamily: 'monospace', fontSize: '.95em', textAlign: 'right' }}
            sx={{ overflowWrap: 'anywhere', maxWidth: '60%' }}
          />
        </ListItem>
      </List>
      <Typography
        variant='h6'
        component='p'
        textAlign='center'
        sx={{ pt: 2 }}
      >
        Código de autorización
      </Typography>
      {(authCodeRenewed) ? (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <InnerForm
              handleBack={handleBack}
              {...props}
            />
          )}
        </Formik>
      ) : (
        <AuthCodeRenewer
          handleBack={handleBack}
          setAuthCodeRenewed={setAuthCodeRenewed}
        />
      )}
    </React.Fragment>
  )
}
