import { gql } from '@apollo/client'

import { BANK_DEPOSIT_FIELDS, BULK_PURCHASE_FIELDS } from './user_operations'

import type { BankDeposit, BulkPurchase } from './user_operations'

export type User = {
  id: string
  email: string
  nickName: string
}

export const BULK_PURCHASE_TRANSFERABLE_USERS_FIELDS = gql`
  fragment BulkPurchaseTransferableUsersFields on User {
    id
    email
    nickName
  }
`

export type BulkPurchaseTransferableUsersVars = {
  filter?: string
}

export type BulkPurchaseTransferableUsersData = {
  bulkPurchaseTransferableUsers: User[]
}

export const BULK_PURCHASE_TRANSFERABLE_USERS_QUERY = gql`
  ${BULK_PURCHASE_TRANSFERABLE_USERS_FIELDS}
  query BulkPurchaseTransferableUsers($filter: String) {
    bulkPurchaseTransferableUsers(filter: $filter) {
      ...BulkPurchaseTransferableUsersFields
    }
  }
`

export type TransferBulkPurchaseVars = {
  amount: number
  bulkPurchaseId: string
  receiverId: string
}

export type TransferBulkPurchaseData = {
  transferBulkPurchase: string
}

export const TRANSFER_BULK_PURCHASE_MUTATION = gql`
  mutation TransferBulkPurchase(
    $amount: Float!
    $bulkPurchaseId: ID!
    $receiverId: ID!
  ) {
    transferBulkPurchase(
      input: {
        amount: $amount
        bulkPurchaseId: $bulkPurchaseId
        receiverId: $receiverId
      }
    )
  }
`

export type BulkPurchaseConfig = {
  creationAllowed: boolean
  minAmount: number
  maxAmount: number
  dailyMaxAmount: number
  dailyRemainingAmount: number
  aftermarketCreationAllowed: boolean
  aftermarketPrice: number
  aftermarketTotalAmount: number
  aftermarketRemainingAmount: number
  isBusinessHours: boolean
}

export const BULK_PURCHASE_CONFIG_FIELDS = gql`
  fragment BulkPurchaseConfigFields on BulkPurchaseConfig {
    creationAllowed
    minAmount
    maxAmount
    dailyMaxAmount
    dailyRemainingAmount
    aftermarketCreationAllowed
    aftermarketPrice
    aftermarketTotalAmount
    aftermarketRemainingAmount
    isBusinessHours
  }
`

export type BulkPurchaseConfigVars = Record<string, never>

export type BulkPurchaseConfigData = {
  bulkPurchaseConfig: BulkPurchaseConfig
}

export const BULK_PURCHASE_CONFIG_QUERY = gql`
  ${BULK_PURCHASE_CONFIG_FIELDS}
  query BulkPurchaseConfig {
    bulkPurchaseConfig {
      ...BulkPurchaseConfigFields
    }
  }
`

export type CreateBulkPurchaseVars = {
  inAmount: number
  quoteId: string
}

export type CreateBulkPurchaseData = {
  createBulkPurchase: string
}

export const CREATE_BULK_PURCHASE_MUTATION = gql`
  mutation CreateBulkPurchase(
    $inAmount: Float!
    $quoteId: ID!
  ) {
    createBulkPurchase(
      input: {
        inAmount: $inAmount
        quoteId: $quoteId
      }
    )
  }
`

export type CreateBulkPurchaseAftermarketVars = {
  inAmount: number
}

export type CreateBulkPurchaseAftermarketData = {
  createBulkPurchaseAftermarket: string
}

export const CREATE_BULK_PURCHASE_AFTERMARKET_MUTATION = gql`
  mutation CreateBulkPurchaseAftermarket(
    $inAmount: Float!
  ) {
    createBulkPurchaseAftermarket(
      input: {
        inAmount: $inAmount
      }
    )
  }
`

export type BulkPurchasesOverview = {
  openBulkPurchases: BulkPurchase[]
  unspentBankDeposits: BankDeposit[]
  withdrawableAmount: number
}

export const BULK_PURCHASES_OVERVIEW_FIELDS = gql`
  ${BANK_DEPOSIT_FIELDS}
  ${BULK_PURCHASE_FIELDS}
  fragment BulkPurchasesOverviewFields on BulkPurchasesOverview {
    openBulkPurchases {
      ...BulkPurchaseFields
    }
    unspentBankDeposits {
      ...BankDepositFields
    }
    withdrawableAmount
  }
`

export type BulkPurchasesOverviewVars = Record<string, never>

export type BulkPurchasesOverviewData = {
  bulkPurchasesOverview: BulkPurchasesOverview
}

export const BULK_PURCHASES_OVERVIEW_QUERY = gql`
  ${BULK_PURCHASES_OVERVIEW_FIELDS}
  query BulkPurchasesOverview {
    bulkPurchasesOverview {
      ...BulkPurchasesOverviewFields
    }
  }
`
