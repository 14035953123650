import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'

import {
  ButtonContainer,
  ButtonsContainer,
  ErrorDisplay,
  Loading,
} from '../../components'
import {
  TransactionProfileFields,
  getTransactionProfileInitialValues as getInitialValues,
  transactionProfileValidationSchema as validationSchema,
} from '../../forms'
import {
  TRANSACTION_PROFILE_QUERY,
  UPDATE_TRANSACTION_PROFILE_MUTATION,
} from '../../queries'
import { setFormError, translateError } from '../../services'

import type { TransactionProfileFormValues as FormValues } from '../../forms'
import type {
  TransactionProfileData,
  TransactionProfileVars,
  UpdateTransactionProfileData,
  UpdateTransactionProfileVars,
} from '../../queries'

type StepFormProps = FormikProps<FormValues> & {
  isBusiness: boolean
  handleBack: () => void
}

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  isBusiness,
  handleBack,
}: StepFormProps) => (
  <Form>
    <TransactionProfileFields isBusiness={isBusiness} />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={handleBack}
          variant='outlined'
        >
          Atrás
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          Siguiente
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type TransactionProfileStepProps = {
  isBusiness: boolean
  handleBack: () => void
  handleNext: () => void
}

export const TransactionProfileStep = ({
  isBusiness,
  handleBack,
  handleNext,
}: TransactionProfileStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const { loading, data } =
    useQuery<TransactionProfileData, TransactionProfileVars>(TRANSACTION_PROFILE_QUERY)

  const [updateTransactionProfile] =
    useMutation<UpdateTransactionProfileData, UpdateTransactionProfileVars>(
      UPDATE_TRANSACTION_PROFILE_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          TRANSACTION_PROFILE_QUERY,
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateTransactionProfile({
      variables: values,
    })

    if (response.data?.updateTransactionProfile === 'OK!') {
      return handleNext()
    }

    setFormError(formRef, translateError(response))
  }

  return loading ? (
    <Loading />
  ) : (
    <Formik
      innerRef={formRef}
      initialValues={getInitialValues(data?.transactionProfile)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <StepForm
          {...props}
          isBusiness={isBusiness}
          handleBack={handleBack}
        />
      )}
    </Formik>
  )
}
