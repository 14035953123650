import { gql } from '@apollo/client'

import { SWAP_FIELDS } from './user_operations'

import type { Swap } from './user_operations'

export type SwapPair = string[]

export type SwapConfig = {
  allowedPairs: SwapPair[]
}

export type SwapConfigVars = Record<string, never>

export type SwapConfigData = {
  swapConfig: SwapConfig
}

export const SWAP_CONFIG_QUERY = gql`
  query SwapConfig {
    swapConfig {
      allowedPairs
    }
  }
`

export type SwapQuote = {
  id: string
  toAmount: number
  remainingSeconds: number
}

export type CreateSwapQuoteVars = {
  fromSymbol: string
  fromAmount: number
  toSymbol: string
  useMaxAmount: boolean
}

export type CreateSwapQuoteData = {
  createSwapQuote: SwapQuote
}

export const CREATE_SWAP_QUOTE_MUTATION = gql`
  mutation CreateSwapQuote(
    $fromSymbol: String!
    $fromAmount: Float!
    $toSymbol: String!
    $useMaxAmount: Boolean!
  ) {
    createSwapQuote(
      input: {
        fromSymbol: $fromSymbol
        fromAmount: $fromAmount
        toSymbol: $toSymbol
        useMaxAmount: $useMaxAmount
      }
    ) {
      id
      toAmount
      remainingSeconds
    }
  }
`

export type CreateSwapVars = {
  quoteId: string
}

export type CreateSwapData = {
  createSwap: Swap
}

export const CREATE_SWAP_MUTATION = gql`
  ${SWAP_FIELDS}
  mutation CreateSwap(
    $quoteId: String!
  ) {
    createSwap(
      input: {
        quoteId: $quoteId
      }
    ) {
      ...SwapFields
    }
  }
`
