import { gql } from '@apollo/client'

export type SocietyKind = 'PRIVATE' | 'PUBLIC'

export const SOCIETY_KIND_LABELS: { [key in SocietyKind]: string } = {
  'PRIVATE': 'Privada',
  'PUBLIC': 'Pública',
}

export type SocietyType =
  'EIRL'
  | 'Ltda'
  | 'SA(a)'
  | 'SA(c)'
  | 'SA(e)'
  | 'SC(civ)'
  | 'SC(com)'
  | 'SeC(c)'
  | 'SeC(pa)'
  | 'SeC(s)'
  | 'SpA'

export const SOCIETY_TYPE_LABELS: { [key in SocietyType]: string } = {
  'EIRL': 'Empresa Individual de Responsabilidad Limitada',
  'Ltda': 'Sociedad de Responsabilidad Limitada',
  'SA(a)': 'Sociedad Anónima (abierta)',
  'SA(c)': 'Sociedad Anónima (cerrada)',
  'SA(e)': 'Sociedad Anónima (especial)',
  'SC(civ)': 'Sociedad Colectiva (civil)',
  'SC(com)': 'Sociedad Colectiva (comercial)',
  'SeC(c)': 'Sociedad en Comandita (civil)',
  'SeC(pa)': 'Sociedad en Comandita (por acciones)',
  'SeC(s)': 'Sociedad en Comandita (simple)',
  'SpA': 'Sociedad por Acciones',
}

export type BusinessIdentity = {
  id: string
  companyName: string
  identificationNumber: string
  siiActivityCode: string
  societyKind: SocietyKind
  societyType: SocietyType
}

export const BUSINESS_IDENTITY_FIELDS = gql`
  fragment BusinessIdentityFields on BusinessIdentity {
    id
    companyName
    identificationNumber
    siiActivityCode
    societyKind
    societyType
  }
`

export type BusinessIdentityVars = Record<string, never>

export type BusinessIdentityData = {
  businessIdentity: BusinessIdentity
}

export const BUSINESS_IDENTITY_QUERY = gql`
  ${BUSINESS_IDENTITY_FIELDS}
  query BusinessIdentity {
    businessIdentity {
      ...BusinessIdentityFields
    }
  }
`

export type UpdateBusinessIdentityVars = {
  companyName: string
  identificationNumber: string
  siiActivityCode: string
  societyKind: string
  societyType: string
  phoneNumber: string
}

export type UpdateBusinessIdentityData = {
  updateBusinessIdentity: string
  updatePhoneNumber: string
}

export const UPDATE_BUSINESS_IDENTITY_MUTATION = gql`
  mutation UpdateBusinessIdentity(
    $companyName: String!
    $identificationNumber: String!
    $siiActivityCode: String!
    $societyKind: String!
    $societyType: String!
    $phoneNumber: String!
  ) {
    updateBusinessIdentity(input: {
      companyName: $companyName
      identificationNumber: $identificationNumber
      siiActivityCode: $siiActivityCode
      societyKind: $societyKind
      societyType: $societyType
    })
    updatePhoneNumber(input: {
      value: $phoneNumber
    })
  }
`
