import * as React from 'react'

import { Button, CircularProgress } from '@mui/material'

import { isMobileDevice } from '../../services'

export const LoadingOverlay = () => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    }}
  >
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CircularProgress />
    </div>
  </div>
)

type StartDetectionOverlayProps = {
  onClick: () => void
}

export const StartDetectionOverlay = ({
  onClick,
}: StartDetectionOverlayProps) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    }}
  >
    <Button
      variant='contained'
      onClick={onClick}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        aspectRatio: 1,
        borderRadius: '50%',
      }}
    >
      COMENZAR
    </Button>
  </div>
)

export type FaceDirection = 'LEFT' | 'RIGHT'

type FaceDetectionOverlayProps = {
  direction: FaceDirection
}

export const FaceDetectionOverlay = ({
  direction,
}: FaceDetectionOverlayProps) => {
  const arrowStyle = {
    display: 'block',
    width: isMobileDevice() ? '6vw' : '3vw',
    height: isMobileDevice() ? '6vw' : '3vw',
    borderBottom: '8px solid white',
    borderRight: '8px solid white',
    transform: 'rotate(45deg)',
    margin: '-10px',
    opacity: 0,
    animation: 'animate 2s',
  }

  const animationStyle = `
    @keyframes animate {
      0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
      }
    }
  `

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: (direction === 'LEFT')
          ? 'scale(2) translate(-25%, -25%) rotate(90deg)'
          : 'scale(2) translate(-25%, -25%) rotate(-90deg)',
      }}
    >
      <React.Fragment key={direction}>
        <span style={{ ...arrowStyle, animationDelay: '0s' }}></span>
        <span style={{ ...arrowStyle, animationDelay: '-0.2s' }}></span>
        <span style={{ ...arrowStyle, animationDelay: '-0.4s' }}></span>
      </React.Fragment>
      <style>{animationStyle}</style>
    </div>
  )
}
