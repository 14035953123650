import { gql } from '@apollo/client'

export type StoragePost = {
    url: string
    fieldsKeys: string[]
    fieldsValues: string[]
  }

export const STORAGE_POST_FIELDS = gql`
    fragment StoragePostFields on StoragePost {
      url
      fieldsKeys
      fieldsValues
    }
  `
