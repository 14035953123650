import { gql } from '@apollo/client'

export type RelationshipPurpose = 'FINANCIAL_ASSET_MANAGEMENT'
  | 'CORPORATE_FINANCING'
  | 'CUSTODY_SERVICES'
  | 'FOREIGN_EXCHANGE_OPERATIONS'
  | 'DIGITAL_ASSET_OPERATIONS'
  | 'FINANCIAL_CONSULTING'
  | 'PAYMENT_AND_SETTLEMENT_SOLUTIONS'
  | 'PROJECT_FINANCING'
  | 'REGULATORY_COMPLIANCE'
  | 'MARKET_INTERMEDIATION'
  | 'FINANCIAL_AUDIT_AND_REVIEW'

export const RELATIONSHIP_PURPOSE_LABELS: { [key in RelationshipPurpose]: string } = {
  'FINANCIAL_ASSET_MANAGEMENT': 'Gestión de activos financieros',
  'CORPORATE_FINANCING': 'Financiamiento corporativo',
  'CUSTODY_SERVICES': 'Servicios de custodia',
  'FOREIGN_EXCHANGE_OPERATIONS': 'Operaciones de cambio de divisas',
  'DIGITAL_ASSET_OPERATIONS': 'Operaciones con activos digitales',
  'FINANCIAL_CONSULTING': 'Consultoría financiera',
  'PAYMENT_AND_SETTLEMENT_SOLUTIONS': 'Soluciones de pago y liquidación',
  'PROJECT_FINANCING': 'Financiamiento de proyectos',
  'REGULATORY_COMPLIANCE': 'Cumplimiento regulatorio',
  'MARKET_INTERMEDIATION': 'Intermediación en mercados financieros',
  'FINANCIAL_AUDIT_AND_REVIEW': 'Servicios de auditoría y revisión financiera',
}

export type TradingRange = 'LOW' | 'MEDIUM_LOW' | 'MEDIUM_HIGH' | 'HIGH'

export const PERSON_TRADING_RANGE_LABELS: { [key in TradingRange]: string } = {
  'LOW': 'Menos de 1.000.000 CLP',
  'MEDIUM_LOW': '1.000.000 CLP - 5.000.000 CLP',
  'MEDIUM_HIGH': '5.000.000 CLP - 10.000.000 CLP',
  'HIGH': 'Más de 10.000.000 CLP',
}

export const BUSINESS_TRADING_RANGE_LABELS: { [key in TradingRange]: string } = {
  'LOW': 'Menos de 50.000.000 CLP',
  'MEDIUM_LOW': '50.000.000 CLP - 100.000.000 CLP',
  'MEDIUM_HIGH': '100.000.000 CLP - 250.000.000 CLP',
  'HIGH': 'Más de 250.000.000 CLP',
}

export type TransactionProfile = {
  id: string
  declaredDailyTradingRange: TradingRange
  lastDailyTradingRange?: TradingRange
  relationshipPurpose: RelationshipPurpose
}

export const TRANSACTION_PROFILE_FIELDS = gql`
  fragment TransactionProfileFields on TransactionProfile {
    id
    declaredDailyTradingRange
    lastDailyTradingRange
    relationshipPurpose
  }
`

export type TransactionProfileVars = Record<string, never>

export type TransactionProfileData = {
  transactionProfile: TransactionProfile
}

export const TRANSACTION_PROFILE_QUERY = gql`
  ${TRANSACTION_PROFILE_FIELDS}
  query TransactionProfile {
    transactionProfile {
      ...TransactionProfileFields
    }
  }
`

export type UpdateTransactionProfileVars = {
  declaredDailyTradingRange: string
  relationshipPurpose: string
}

export type UpdateTransactionProfileData = {
  updateTransactionProfile: string
}

export const UPDATE_TRANSACTION_PROFILE_MUTATION = gql`
  mutation UpdateTransactionProfile(
    $declaredDailyTradingRange: String!
    $relationshipPurpose: String!
  ) {
    updateTransactionProfile(
      input: {
        declaredDailyTradingRange: $declaredDailyTradingRange
        relationshipPurpose: $relationshipPurpose
      }
    )
  }
`
