import { gql } from '@apollo/client'

export type UpdateInvitationCodeVars = {
  value: string
}

export type UpdateInvitationCodeData = {
  updateInvitationCode: string
}

export const UPDATE_INVITATION_CODE_MUTATION = gql`
  mutation UpdateInvitationCode(
    $value: String!
  ) {
    updateInvitationCode(
      input: {
        value: $value
      }
    )
  }
`
