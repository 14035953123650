import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Info } from '@mui/icons-material'
import { Button, CircularProgress, Typography, useTheme } from '@mui/material'
import { Field, Form, Formik, FormikProps } from 'formik'
import { TextField } from 'formik-mui'
import { QRCodeSVG } from 'qrcode.react'
import * as Yup from 'yup'

import { ErrorDisplay, IconCard } from '../components'
import { OTP_PROVISIONING_URI_QUERY, PROVISION_OTP_MUTATION } from '../queries'
import { setFormError, translateError } from '../services'

import type {
  OtpProvisioningUriData,
  OtpProvisioningUriVars,
  ProvisionOtpData,
  ProvisionOtpVars,
} from '../queries'

type FormValues = ProvisionOtpVars

const initialValues: FormValues = {
  otp: '',
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  otp: Yup.string()
    .length(6, 'El código debe tener 6 dígitos')
    .matches(/^\d+$/, 'El código debe contener sólo números')
    .required('Este campo es obligatorio'),
})

const OtpForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
}: FormikProps<FormValues>) => (
  <Form
    onSubmit={(event) => {
      event?.preventDefault()
      submitForm()
    }}
    style={{ maxWidth: useTheme().breakpoints.values.sm }}
  >
    <Field
      name='otp'
      type='text'
      label='Escanea el QR e ingresa el código'
      component={TextField}
      margin='normal'
      fullWidth
    />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <Button
      disabled={isSubmitting || !isValid}
      variant='contained'
      type='submit'
      size='large'
      fullWidth
      sx={{ mt: 2 }}
    >
      Enviar
    </Button>
  </Form>
)

export const OtpSettings = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const { data, loading, error, refetch } =
    useQuery<OtpProvisioningUriData, OtpProvisioningUriVars>(OTP_PROVISIONING_URI_QUERY, {
      notifyOnNetworkStatusChange: true,
    })

  const [provisionOtp] =
    useMutation<ProvisionOtpData, ProvisionOtpVars>(PROVISION_OTP_MUTATION, {
      errorPolicy: 'all',
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await provisionOtp({ variables: values })
    const ok = response.data?.provisionOtp

    if (ok) {
      refetch()
    } else {
      setFormError(formRef, translateError(response))
    }
  }

  const otpProvisioningUri = data?.otpProvisioningUri

  if (loading) {
    return <CircularProgress />
  } else if (error) {
    return <Typography>Ocurrió un error al consultar el estado de 2FA.</Typography>
  } else if (!otpProvisioningUri) {
    return <Typography>2FA está habilitado ✅</Typography>
  }

  return (
    <React.Fragment>
      <IconCard
        icon={<Info />}
        color='primary.contrastText'
        bgcolor='primary.dark'
        sx={{ mb: 2 }}
      >
        Para habilitar autentificación de dos factores (2FA) escanea el siguiente
        código QR desde tu teléfono con una aplicación compatible &mdash;por
        ejemplo, Google Authenticator o Authy&mdash; e ingresa el código que
        ésta te entregue.
      </IconCard>
      <QRCodeSVG
        includeMargin
        value={otpProvisioningUri}
        size={200}
        level='M'
      />
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => <OtpForm {...props} />}
      </Formik>
    </React.Fragment>
  )
}
