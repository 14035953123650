import * as React from 'react'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types'
import {
  Box,
  Divider,
  Link,
  ListItem,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { renderRichText as baseRenderRichText } from 'gatsby-source-contentful/rich-text'

import { BlogImage, Table } from '../components'

import type { BlogImageData } from '../components'
import type { Options } from '@contentful/rich-text-react-renderer'
import type { Block, Inline } from '@contentful/rich-text-types'

export type BlogRichText = {
  raw: string
  references: {
    __typename: string
    contentful_id: string
    gatsbyImageData: BlogImageData
    title: string
  }[]
}

const youTubeNode = (node: Block | Inline) => {
  if (node.content.length !== 1) {
    return
  } else if (node.content[0].nodeType !== 'text') {
    return
  }

  const text = node.content[0].value

  if (text.match(
    /^((?:https:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/,
  ) === null) {
    return
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        '& > iframe': {
          boxShadow: 8,
          borderRadius: 1,
          maxHeight: '90vh',
          maxWidth: '600px',
        },
      }}
    >
      <iframe
        title='YouTube video player'
        src={text}
        frameBorder='0'
        width='560'
        height='315'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      >
      </iframe>
    </Box>
  )
}

const renderHyperlink = (node: Block | Inline, children: React.ReactNode) => (
  <Link
    href={node.data.uri}
    target='_blank'
    rel='noopener noreferrer'
  >
    {children}
  </Link>
)

const bodyRenderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      youTubeNode(node) || (
        <Typography
          textAlign='justify'
        >
          {children}
        </Typography>
      )
    ),
    [BLOCKS.HEADING_1]: (_node, children) => (
      <Typography
        variant='h4'
        component='h2'
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Typography
        variant='h4'
        component='h3'
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <Typography
        variant='h5'
        component='h4'
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (_node, children) => (
      <Typography
        variant='h5'
        component='h5'
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (_node, children) => (
      <Typography
        variant='h6'
        component='h6'
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (_node, children) => (
      <Typography
        variant='h6'
        component='p'
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HR]: () => (
      <Divider />
    ),
    [BLOCKS.LIST_ITEM]: (node) => {
      const unTaggedChildren = documentToReactComponents(node as Document, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (_node, children) => children,
          [BLOCKS.LIST_ITEM]: (_node, children) => children,
          [INLINES.HYPERLINK]: renderHyperlink,
        },
      })
      return (
        <ListItem
          sx={(theme) => ({
            display: 'list-item',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
          })}
        >
          {unTaggedChildren}
        </ListItem>
      )
    },
    [BLOCKS.TABLE]: (node, children) => (
      <TableContainer
        component={Paper}
        elevation={8}
      >
        <Table>
          <TableHead>
            {React.Children.toArray(children).filter((_child, i) => {
              const subNode = node.content[i] as Block
              return subNode.content.some((subSubNode) => subSubNode.nodeType === 'table-header-cell')
            })}
          </TableHead>
          <TableBody>
            {React.Children.toArray(children).filter((_child, i) => {
              const subNode = node.content[i] as Block
              return subNode.content.some((subSubNode) => subSubNode.nodeType === 'table-cell')
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    [BLOCKS.TABLE_ROW]: (_node, children) => (
      <TableRow>{children}</TableRow>
    ),
    [BLOCKS.TABLE_CELL]: (node) => (
      <TableCell>
        {documentToReactComponents(node as Document, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => children,
            [BLOCKS.TABLE_CELL]: (_node, children) => children,
          },
        })}
      </TableCell>
    ),
    [BLOCKS.TABLE_HEADER_CELL]: (node) => (
      <TableCell>
        {documentToReactComponents(node as Document, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => children,
            [BLOCKS.TABLE_HEADER_CELL]: (_node, children) => children,
          },
        })}
      </TableCell>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData, title } = node.data.target

      if (!gatsbyImageData) {
        return null
      }

      return (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            display: 'flex',
            '& > .gatsby-image-wrapper': {
              boxShadow: 8,
              borderRadius: 1,
              maxWidth: '600px',
            },
          }}
        >
          <BlogImage
            image={node.data.target}
            alt={title}
            objectFit='cover'
          />
        </Box>
      )
    },
    [INLINES.HYPERLINK]: renderHyperlink,
  },
}

export const renderRichText = (text: BlogRichText) => baseRenderRichText(text, bodyRenderOptions)
