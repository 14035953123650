import * as React from 'react'

import { useQuery } from '@apollo/client'
import { AccountBalance, AddShoppingCart, Close } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { Link } from '@reach/router'

import {
  ButtonContainer,
  ButtonsContainer,
  CopyButton,
  Loading,
  MonospaceText,
  SupportMailLink,
} from '../../components'
import { RAMP_CONFIG_QUERY } from '../../queries'
import { numberFormatter } from '../../services'

import type { Payment, RampConfigData, RampConfigVars } from '../../queries'

type ModalStatus = 'OPEN' | 'SUCCESS' | 'FAILURE' | 'CANCELLED'

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: theme.shadows[12],
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}))

type FlowRestartFnType = () => void

type FlowEndMessageProps = {
  title: string
  subtitle: string
  handleRestart: FlowRestartFnType
  children?: React.ReactNode
}

const FlowEndMessage = ({
  title,
  subtitle,
  handleRestart,
  children,
}: FlowEndMessageProps) => (
  <Stack
    spacing={1}
    alignItems='center'
  >
    <Typography
      variant='h6'
      component='span'
      textAlign='center'
    >
      {title}
    </Typography>
    <Typography
      variant='subtitle1'
      component='p'
      textAlign='center'
    >
      {subtitle}
    </Typography>
    {children}
    <ButtonsContainer sx={{ alignItems: 'flex-end', pt: 1 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleRestart}
          startIcon={<AddShoppingCart />}
          variant='outlined'
          sx={{ textAlign: 'center' }}
        >
          Volver a comprar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          component={Link}
          to='../portfolio'
          startIcon={<AccountBalance />}
          variant='contained'
          sx={{ textAlign: 'center' }}
        >
          Ir a Portafolio
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Stack>
)

const CancelledMessage = ({
  handleRestart,
}: { handleRestart: FlowRestartFnType }) => (
  <FlowEndMessage
    title='Compra cancelada'
    subtitle={(
      'Si tuviste problemas para realizar la compra, escríbenos a nuestro'
      + ' correo de soporte:'
    )}
    handleRestart={handleRestart}
  >
    <SupportMailLink variant='text' />
  </FlowEndMessage>
)

const SuccessMessage = ({
  handleRestart,
}: { handleRestart: FlowRestartFnType }) => (
  <FlowEndMessage
    title='Dólares digitales en camino'
    subtitle='Tu balance se actualizará apenas confirmemos el pago con nuestro proveedor.'
    handleRestart={handleRestart}
  />
)

const FailureMessage = ({
  handleRestart,
}: { handleRestart: FlowRestartFnType }) => (
  <FlowEndMessage
    title='Ocurrió un error'
    subtitle={(
      'Si tuviste problemas para realizar la compra, escríbenos a nuestro'
      + ' correo de soporte:'
    )}
    handleRestart={handleRestart}
  >
    <SupportMailLink variant='text' />
  </FlowEndMessage>
)

type KoyweBankTransferContentProps = {
  fiatAmount: number
  paymentMethodDetails: string
  handleSuccess: () => void
}

const KoyweBankTransferContent = ({
  fiatAmount,
  paymentMethodDetails,
  handleSuccess,
}: KoyweBankTransferContentProps) => {
  const { loading, data } = useQuery<RampConfigData, RampConfigVars>(RAMP_CONFIG_QUERY)

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Typography>
        Para finalizar esta compra, deberás transferir
        {' '}
        ${numberFormatter(0).format(fiatAmount)}
        {' '}
        {data?.rampConfig?.userFiatCurrency || '--'} a la cuenta de nuestro proveedor:
      </Typography>
      <MonospaceText
        width='100%'
        textAlign='center'
      >
        {paymentMethodDetails}
      </MonospaceText>
      <CopyButton
        textToCopy={paymentMethodDetails}
        buttonText='Copiar datos'
        buttonPressedText='¡Datos copiados!'
      />
      <Button
        onClick={handleSuccess}
        variant='outlined'
        size='large'
        fullWidth
      >
        Cerrar
      </Button>
    </React.Fragment>
  )
}

type KoyweProvidedActionProps = {
  providedAction: string | null
  handleSuccess: () => void
}

const KoyweProvidedAction = ({
  providedAction,
  handleSuccess,
}: KoyweProvidedActionProps) => providedAction && (
  <React.Fragment>
    <Typography>
      Al hacer clic serás redireccionado a una nueva pestaña para completar la transferencia:
    </Typography>
    <Button
      href={providedAction}
      target='_blank'
      rel='noopener noreferrer'
      onClick={handleSuccess}
      variant='contained'
      size='large'
      fullWidth
    >
      Completar transferencia
    </Button>
  </React.Fragment>
)

type KoyweWidgetProps = {
  fiatAmount: number
  paymentMethodDetails: string
  providedAction: string | null
  handleSuccess: () => void
  handleCancel: () => void
}

const KoyweWidget = ({
  fiatAmount,
  paymentMethodDetails,
  providedAction,
  handleSuccess,
  handleCancel,
}: KoyweWidgetProps) => (
  <Stack
    height='100%'
    alignItems='center'
    justifyContent='center'
    padding={3}
    spacing={3}
  >
    <Stack
      width='100%'
      direction='row'
    >
      <Typography
        flexGrow={1}
        variant='h5'
        component='p'
      >
        Dólares digitales en camino
      </Typography>
      <IconButton
        aria-label='Cerrar'
        onClick={handleCancel}
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          p: 0,
        })}
      >
        <Close />
      </IconButton>
    </Stack>
    {(paymentMethodDetails) ? (
      <KoyweBankTransferContent
        fiatAmount={fiatAmount}
        paymentMethodDetails={paymentMethodDetails}
        handleSuccess={handleSuccess}
      />
    ) : (
      <KoyweProvidedAction
        providedAction={providedAction}
        handleSuccess={handleSuccess}
      />
    )}
  </Stack>
)

type PaymentStepProps = {
  fiatAmount: number
  payment?: Payment
  handleRestart: FlowRestartFnType
}

export const PaymentStep = ({
  fiatAmount,
  payment,
  handleRestart,
}: PaymentStepProps) => {
  const [modalStatus, setModalStatus] = React.useState<ModalStatus>('OPEN')

  if (!payment) {
    return <Loading />
  }

  const { providedAction, paymentMethodDetails } = payment

  return (
    <React.Fragment>
      {(() => {
        switch (modalStatus) {
        case 'OPEN': return <Loading />
        case 'CANCELLED': return <CancelledMessage handleRestart={handleRestart} />
        case 'SUCCESS': return <SuccessMessage handleRestart={handleRestart} />
        case 'FAILURE': return <FailureMessage handleRestart={handleRestart} />
        }
      })()}
      <Modal
        open={modalStatus === 'OPEN'}
        onClose={() => setModalStatus('CANCELLED')}
      >
        <ModalContainer
          sx={{
            maxWidth: '500px',
            maxHeight: '600px',
            overflow: 'hidden',
          }}
        >
          <KoyweWidget
            fiatAmount={fiatAmount}
            providedAction={providedAction}
            paymentMethodDetails={paymentMethodDetails}
            handleSuccess={() => setModalStatus('SUCCESS')}
            handleCancel={() => setModalStatus('CANCELLED')}
          />
        </ModalContainer>
      </Modal>
    </React.Fragment>
  )
}
