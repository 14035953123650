import * as React from 'react'

import { useMediaQuery, useTheme } from '@mui/material'

import { Body, Image, SplitSection, Subtitle, Title } from '../components'

import type { ImageProps, SplitSectionProps } from '../components'

type SeoImageProps = Omit<ImageProps, 'duration' | 'fit'> & {
  reversed?: boolean
}

export const SeoImage = ({
  reversed,
  shift,
  height,
  ...rest
}: SeoImageProps) => (
  <Image
    height={height || 'min(50vh, 400px)'}
    shift={shift || (reversed ? 'right' : 'left')}
    duration={3000}
    fit='contain'
    loading='lazy'
    {...rest}
  />
)

type BaseSeoSectionProps = SplitSectionProps & {
  seoTitle: React.ReactNode
  seoSubtitle?: React.ReactNode
  seoBody?: React.ReactNode
}

export const BaseSeoSection = ({
  reversed = false,
  seoTitle,
  seoSubtitle,
  seoBody,
  ...rest
}: BaseSeoSectionProps) => {
  const theme = useTheme()
  let textAlign: 'left' | 'center' | 'right'

  if (useMediaQuery(theme.breakpoints.up('sm'))) {
    textAlign = reversed ? 'right' : 'left'
  } else {
    textAlign = 'center'
  }

  return (
    <SplitSection
      reversed={reversed}
      textAlign={textAlign}
      {...rest}
    >
      <Title
        colorful
      >
        {seoTitle}
      </Title>
      {seoSubtitle && (
        <Subtitle
          color={'text.secondary'}
          fontWeight={300}
          paddingTop={2}
        >
          {seoSubtitle}
        </Subtitle>
      )}
      {seoBody && (
        <Body
          color='text.secondary'
          paddingTop={2}
        >
          {seoBody}
        </Body>
      )}
    </SplitSection>
  )
}

type SeoSectionProps = Omit<BaseSeoSectionProps, 'imageComponent'> & {
  imageSrc: string
  imageAlt: string
  imageHeight?: ImageProps['height']
  imageShift?: ImageProps['shift']
}

export const SeoSection = ({
  reversed = false,
  imageSrc,
  imageAlt,
  imageHeight,
  imageShift,
  ...rest
}: SeoSectionProps) => {
  return (
    <BaseSeoSection
      reversed={reversed}
      imageComponent={(
        <SeoImage
          reversed={reversed}
          src={imageSrc}
          alt={imageAlt}
          shift={imageShift}
          height={imageHeight}
        />
      )}
      {...rest}
    />
  )
}
