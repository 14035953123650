import * as React from 'react'

import { Description, OpenInNew, OpenInNewOff } from '@mui/icons-material'
import {
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
} from '@mui/material'

import { DatedList, DatedListItem, UserDocumentDeleter } from '../components'

import type {
  UserDocumentView,
} from '../queries'

type UserDocumentLinkProps = {
  storageUrl?: string
}

export const UserDocumentLink = ({
  storageUrl,
}: UserDocumentLinkProps) =>
  (storageUrl) ? (
    <IconButton
      component='a'
      target='_blank'
      href={storageUrl}
    >
      <OpenInNew />
    </IconButton>
  ) : (
    <Tooltip title='Este documento no se ha subido aún'>
      <span>
        <IconButton disabled>
          <OpenInNewOff />
        </IconButton>
      </span>
    </Tooltip>
  )

type UserDocumentDisplayProps = {
  children?: React.ReactNode
  document: UserDocumentView
}

const UserDocumentDisplay = ({
  children,
  document,
}: UserDocumentDisplayProps) => {
  const providerText =
    document.provider === 'ADMIN' ? 'Creado por administrador' : 'Creado por usuario'

  return (
    <DatedListItem
      icon={<Description />}
      text={document.description}
      timestamp={providerText}
    >
      <ListItemText
        disableTypography
        sx={(theme) => ({ textAlign: 'right', minWidth: theme.spacing(5) })}
      >
        {children}
        {document.provider === 'USER' && <UserDocumentDeleter userDocumentId={document.id} />}
        <UserDocumentLink storageUrl={document.storageUrl} />
      </ListItemText>
    </DatedListItem>
  )
}

export const EmptyDocumentsDisplay = () => (
  <ListItem>
    <ListItemText
      primary='No hay documentos para mostrar'
      primaryTypographyProps={{ color: 'text.secondary', textAlign: 'center' }}
    />
  </ListItem>
)

type UserDocumentsListProps = {
  loading: boolean
  documents: UserDocumentView[]
}

export const UserDocumentsList = ({
  loading,
  documents,
}: UserDocumentsListProps) => (
  <DatedList
    emptyListDisplay={<EmptyDocumentsDisplay />}
    loading={loading}
    items={documents.map((document) => ({
      timestamp: document.timestamp * 1000,
      component: <UserDocumentDisplay document={document} />,
    }))}
  />
)
