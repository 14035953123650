import { gql } from '@apollo/client'

export type UpdateUserTypeVars = {
  isBusiness: boolean
}

export type UpdateUserTypeData = {
  updateUserType: string
}

export const UPDATE_USER_TYPE_MUTATION = gql`
  mutation UpdateUserType(
    $isBusiness: Boolean!
  ) {
    updateUserType(
      input: {
        isBusiness: $isBusiness
      }
    )
  }
`
