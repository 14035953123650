import * as React from 'react'

import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Skeleton,
  alpha,
} from '@mui/material'
import { timeFormat } from 'd3-time-format'

import { AppContainer } from '../components'

const formatDate = timeFormat('%Y-%m-%d')
const formatHour = timeFormat('%H:%M')

const toDate = (timestamp: number) => new Date(timestamp)

type DatedItem = {
  timestamp: number
  component: React.ReactNode
}

const asDatedList = (items: DatedItem[]) => {
  let currentDate = ''

  return items
    .sort((a, b) => b.timestamp - a.timestamp)
    .map((item, index) => {
      const formattedDate = formatDate(toDate(item.timestamp))
      let dateRow = null

      if (formattedDate !== currentDate) {
        dateRow = <DatedListSubheader>{formattedDate}</DatedListSubheader>
        currentDate = formattedDate
      }

      return (
        <React.Fragment key={index}>
          {dateRow}
          {dateRow && <Divider />}
          {item.component}
          {(index !== items.length - 1) && <Divider />}
        </React.Fragment>
      )
    })
}

type DatedListSubheaderProps = {
  children: React.ReactNode
}

const DatedListSubheader = ({
  children,
}: DatedListSubheaderProps) => (
  <ListSubheader sx={{ bgcolor: 'action.hover' }}>
    {children}
  </ListSubheader>
)

type DatedListItemProps = {
  icon: React.ReactNode
  text: React.ReactNode
  timestamp: React.ReactNode | number
  onClick?: () => void
  children?: React.ReactNode
}

export const DatedListItem = ({
  icon,
  text,
  timestamp,
  onClick,
  children,
}: DatedListItemProps) => (
  <ListItem
    onClick={onClick}
    sx={{ ...(onClick && { cursor: 'pointer' }) }}
  >
    <ListItemAvatar>
      <Avatar
        sx={(theme) => ({
          color: 'text.primary',
          backgroundColor: alpha(
            theme.palette.primary[theme.palette.mode === 'light' ? 'dark' : 'light'],
            theme.palette.action.activatedOpacity,
          ),
        })}
      >
        {icon}
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={text}
      secondary={(typeof timestamp === 'number')
        ? formatHour(toDate(timestamp))
        : timestamp
      }
      secondaryTypographyProps={{ lineHeight: 1.9 }}
      sx={{ minWidth: '130px' }}
    />
    {children}
  </ListItem>
)

const DatedListLoadingItem = () => (
  <DatedListItem
    icon={(
      <Skeleton
        variant='circular'
        width={40}
        height={40}
      />
    )}
    text={<Skeleton width='100' />}
    timestamp={<Skeleton width='50' />}
  />
)

type DatedListProps = {
  emptyListDisplay: React.ReactNode
  loading?: boolean
  items: DatedItem[]
}

export const DatedList = ({
  emptyListDisplay,
  loading,
  items,
}: DatedListProps) => (
  <AppContainer sx={{ overflow: 'hidden' }}>
    <List disablePadding>
      {(loading) ? (
        <React.Fragment>
          <DatedListSubheader>Obteniendo datos...</DatedListSubheader>
          {[...Array(3)].map((_e, i) => <DatedListLoadingItem key={i} />)}
        </React.Fragment>
      ) : (
        (items.length > 0)
          ? asDatedList(items)
          : emptyListDisplay
      )}
    </List>
  </AppContainer>
)
