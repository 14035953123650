import * as React from 'react'

import { Close } from '@mui/icons-material'
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  Stack,
} from '@mui/material'

import type {
  Breakpoint,
  DialogContentTextProps,
} from '@mui/material'

type DialogProps = {
  open: boolean
  onClose: () => void
  maxWidth?: Breakpoint
  title: string
  contentText?: React.ReactNode
  contentTextProps?: DialogContentTextProps
  children?: React.ReactNode
}

export const Dialog = ({
  open,
  onClose,
  maxWidth,
  title,
  contentText,
  contentTextProps,
  children,
}: DialogProps) => (
  <MuiDialog
    open={open}
    onClose={onClose}
    maxWidth={maxWidth}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    fullWidth
    sx={(theme) => ({
      '& > .MuiDialog-container > .MuiPaper-root': {
        margin: theme.spacing(1),
        maxHeight: `calc(100vh - ${theme.spacing(2)})`,
        width: `calc(100vh - ${theme.spacing(2)})`,
      },
    })}
  >
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      px={2}
      pt={2}
    >
      <DialogTitle
        id='alert-dialog-title'
        sx={{
          maxWidth: 'calc(100% - 34px)',
          px: 1,
          py: 0,
        }}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label='Cerrar'
        color='inherit'
        size='small'
        onClick={onClose}
      >
        <Close />
      </IconButton>
    </Stack>
    <DialogContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        '& .MuiFormControl-root': {
          width: '100%',
        },
      }}
    >
      {contentText && (
        <DialogContentText
          id='alert-dialog-description'
          {...contentTextProps}
        >
          {contentText}
        </DialogContentText>
      )}
      {children}
    </DialogContent>
  </MuiDialog>
)
