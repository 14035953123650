import * as React from 'react'

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'

type FormikValues = {
  [key: string]: string
}

type BooleanFieldProps = {
  label: string
  name: string
}

export const BooleanField = ({
  label,
  name,
}: BooleanFieldProps) => {
  const { errors, isSubmitting, setFieldValue, touched, values } = useFormikContext<FormikValues>()

  return (
    <Stack alignItems='center'>
      <Typography textAlign='center'>
        {label}
      </Typography>
      <FormControl>
        <RadioGroup
          row
          name={name}
          value={values[name]}
          onChange={(event) => setFieldValue(name, event.target.value)}
          sx={{ justifyContent: 'space-evenly' }}
        >
          <FormControlLabel
            value='true'
            label='Sí'
            control={<Radio />}
            disabled={isSubmitting}
          />
          <FormControlLabel
            value='false'
            label='No'
            control={<Radio />}
            disabled={isSubmitting}
          />
        </RadioGroup>
      </FormControl>
      <FormHelperText error>
        {touched[name] && errors[name]}
      </FormHelperText>
    </Stack>
  )
}
