import * as React from 'react'

import {
  Backdrop,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'

export const BackdropLoading = ({ message }: { message?: string }) => (
  <Backdrop
    sx={{
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: (theme) => theme.zIndex.drawer + 1,
    }}
    open={true}
  >
    <Stack
      spacing={3}
      alignItems='center'
    >
      <Typography textAlign='center'>{message}</Typography>
      <CircularProgress color='inherit' />
    </Stack>
  </Backdrop>
)
