import { gql } from '@apollo/client'

export type LegalAddress = {
  id: string
  countryCode: string
  regionCode: string
  city: string
  streetAddress: string
  postalCode: string
}

export const LEGAL_ADDRESS_FIELDS = gql`
  fragment LegalAddressFields on LegalAddress {
    id
    countryCode
    regionCode
    city
    streetAddress
    postalCode
  }
`

export type LegalAddressVars = Record<string, never>

export type LegalAddressData = {
  legalAddress: LegalAddress
}

export const LEGAL_ADDRESS_QUERY = gql`
  ${LEGAL_ADDRESS_FIELDS}
  query LegalAddress {
    legalAddress {
      ...LegalAddressFields
    }
  }
`

export type UpdateLegalAddressVars = {
  countryCode: string
  regionCode: string
  city: string
  streetAddress: string
  postalCode: string
}

export type UpdateLegalAddressData = {
  updateLegalAddress: string
}

export const UPDATE_LEGAL_ADDRESS_MUTATION = gql`
  mutation UpdateLegalAddress(
    $countryCode: String!
    $regionCode: String!
    $city: String!
    $streetAddress: String!
    $postalCode: String!
  ) {
    updateLegalAddress(
      input: {
        countryCode: $countryCode
        regionCode: $regionCode
        city: $city
        streetAddress: $streetAddress
        postalCode: $postalCode
      }
    )
  }
`
