import * as React from 'react'

import { Paper, Stack, Typography } from '@mui/material'

import type { PaperProps } from '@mui/material'

type InfoCardProps = PaperProps & {
  icon: React.ReactNode
  color: string
  bgcolor: string
}

export const IconCard = ({
  sx = {},
  elevation = 12,
  icon,
  color,
  bgcolor,
  children,
}: InfoCardProps) => (
  <Paper
    sx={{ ...sx, color, bgcolor, p: 2 }}
    elevation={elevation}
  >
    <Stack
      direction='row'
      alignItems='center'
      spacing={2}
    >
      {icon}
      <Typography variant='body2'>
        {children}
      </Typography>
    </Stack>
  </Paper>
)
