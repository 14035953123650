import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik, FormikProps } from 'formik'

import {
  BackdropLoading,
  ButtonContainer,
  ButtonsContainer,
  ErrorDisplay,
  Loading,
} from '../../components'
import {
  BusinessIdentityProofsFields,
  PersonIdentityProofsFields,
  getIdentityProofsInitialValues as getInitialValues,
  identityProofsValidationSchema as validationSchema,
} from '../../forms'
import {
  CREATE_IDENTITY_PROOFS_MUTATION,
  IDENTITY_PROOFS_QUERY,
  POI_DOCUMENT_TYPE_LABELS,
  UPDATE_IDENTITY_PROOF_MUTATION,
} from '../../queries'
import {
  heicToJpeg,
  setFormError,
  toISO8601Date,
  translateError,
  uploadToStorage,
} from '../../services'
import { DocumentDisplay } from '../document_display'

import type { IdentityProofsFormValues as FormValues } from '../../forms'
import type {
  CreateIdentityProofsData,
  CreateIdentityProofsVars,
  IdentityProof,
  IdentityProofView,
  IdentityProofsData,
  IdentityProofsVars,
  UpdateIdentityProofData,
  UpdateIdentityProofVars,
} from '../../queries'

const validProofsQuantity = (proofs: IdentityProofView[]) => {
  if (proofs.length === 0) {
    return false
  }

  const idCardProofsCount = proofs
    .filter((proof) => proof.documentType.startsWith('ID_CARD_'))
    .length

  return idCardProofsCount !== 1
}

type StepFormProps = FormikProps<FormValues> & {
  handleBack: () => void
  showWaitingMessage: boolean
  isBusiness: boolean
}

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  values,
  submitForm,
  handleBack,
  showWaitingMessage,
  isBusiness,
}: StepFormProps) => (
  <Form>
    {showWaitingMessage && (
      <BackdropLoading
        message={(values.backImage) ? (
          'Por favor espera mientras cargamos tus documentos...'
        ) : (
          'Por favor espera mientras cargamos tu documento...'
        )}
      />
    )}
    {isBusiness ? <BusinessIdentityProofsFields /> : <PersonIdentityProofsFields />}
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={handleBack}
          variant='outlined'
        >
          Atrás
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          {isSubmitting ? 'Cargando...' : 'Continuar'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type IdentityProofsDisplayProps = {
  handleBack: () => void
  handleNext: () => void
  handleShowForm: () => void
  identityProofs: IdentityProofView[]
}

const IdentityProofsDisplay = ({
  handleBack,
  handleNext,
  handleShowForm,
  identityProofs,
}: IdentityProofsDisplayProps) => (
  <List>
    {identityProofs.map((identityProof, index) => (
      <React.Fragment key={index}>
        <ListItem>
          <DocumentDisplay
            contentType={identityProof.contentType}
            documentType={identityProof.documentType}
            storageUrl={identityProof.storageUrl}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary={POI_DOCUMENT_TYPE_LABELS[identityProof.documentType]}
            primaryTypographyProps={{ textAlign: 'center' }}
          />
        </ListItem>
      </React.Fragment>
    ))}
    <Button
      onClick={handleShowForm}
      variant='outlined'
      sx={{ mt: 3, mb: 2 }}
      fullWidth
    >
      Resubir nuevos documentos
    </Button>
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleBack}
          variant='outlined'
        >
          Atrás
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleNext}
          variant='contained'
          color='primary'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </List>
)

type IdentityProofsStepProps = {
  handleBack: () => void
  handleNext: () => void
  isBusiness: boolean
}

export const IdentityProofsStep = ({
  handleBack,
  handleNext,
  isBusiness,
}: IdentityProofsStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [showForm, setShowForm] = React.useState(false)
  const [showWaitingMessage, setShowWaitingMessage] = React.useState(false)

  const { loading: proofLoading, data: proofData } =
    useQuery<IdentityProofsData, IdentityProofsVars>(IDENTITY_PROOFS_QUERY)

  const identityProofs = proofData?.identityProofs || []

  const [createIdentityProofs] =
    useMutation<CreateIdentityProofsData, CreateIdentityProofsVars>(
      CREATE_IDENTITY_PROOFS_MUTATION, {
        errorPolicy: 'all',
      })

  const [updateIdentityProof] =
    useMutation<UpdateIdentityProofData, UpdateIdentityProofVars>(
      UPDATE_IDENTITY_PROOF_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          IDENTITY_PROOFS_QUERY,
        ],
      })

  const uploadFile = async (
    identityProof: IdentityProof | undefined,
    file: File | undefined,
  ) => {
    if (!identityProof || !file) {
      return true
    }

    const storageResponse = await uploadToStorage(identityProof.storagePost, file)

    if (!storageResponse.ok) {
      setFormError(formRef, 'Ocurrió un error al subir tu archivo.')
      return false
    }

    const response = await updateIdentityProof({
      variables: {
        id: identityProof.id,
      },
    })

    if (response.data?.updateIdentityProof === 'OK!') {
      return true
    }

    setFormError(formRef, translateError(response))
    return false
  }

  const uploadFiles = async (
    backFile: File | undefined,
    frontFile: File | undefined,
    documentType: string,
    expirationDate: Date | null,
  ) => {
    if (!frontFile) {
      setFormError(formRef, 'No se encontró el archivo de origen.')
      return false
    }

    const response = await createIdentityProofs({
      variables: {
        contentTypeBack: backFile?.type,
        contentTypeFront: frontFile.type,
        documentType,
        expirationDate: toISO8601Date(expirationDate),
      },
    })

    const identityProofs = response.data?.createIdentityProofs || []

    if (identityProofs.length === 0) {
      setFormError(formRef, translateError(response))
      return false
    }

    const uploadResults = await Promise.all([
      uploadFile(identityProofs[0], frontFile),
      uploadFile(identityProofs[1], backFile),
    ])

    return uploadResults.reduce((acc, curr) => acc && curr)
  }

  const handleSubmit = async (values: FormValues) => {
    setShowWaitingMessage(true)
    const backImage = values.backImage && await heicToJpeg(values.backImage)
    const frontImage = values.frontImage && await heicToJpeg(values.frontImage)

    const response = await uploadFiles(
      backImage,
      frontImage,
      values.documentType,
      values.expirationDate,
    )

    if (response) {
      handleNext()
    } else {
      setShowWaitingMessage(false)
    }
  }

  if (proofLoading) {
    return <Loading />
  }

  return (!showForm && validProofsQuantity(identityProofs)) ? (
    <IdentityProofsDisplay
      handleBack={handleBack}
      handleNext={handleNext}
      handleShowForm={() => setShowForm(true)}
      identityProofs={identityProofs}
    />
  ) : (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(isBusiness)}
        validationSchema={validationSchema(isBusiness)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <StepForm
            handleBack={handleBack}
            showWaitingMessage={showWaitingMessage}
            isBusiness={isBusiness}
            {...props}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}
