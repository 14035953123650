import SII_ACTIVITIES_DATA from './sii_activities.json'

export const SII_ACTIVITIES = (
  Object.values(SII_ACTIVITIES_DATA)
    .map((category) => Object.values(category))
    .flat()
    .flat()
)

export const findSiiActivity = (code: string) => (
  SII_ACTIVITIES.find((activity) => activity.code === code)
)

export const siiActivityLabel = (code: string) => {
  const activity = findSiiActivity(code)
  return activity ? `${code} - ${activity.name}` : 'Sin declarar'
}
