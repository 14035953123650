export const heicToJpeg = async (file: File): Promise<File> => {
  if (file.type !== 'image/heic') {
    return file
  }

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const heic2any = require('heic2any')
  const heicBlob = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.9 })
  const blob = Array.isArray(heicBlob) ? heicBlob[0] : heicBlob

  return new File(
    [blob],
    file.name.replace(/\.heic$/, '.jpg'),
    { type: 'image/jpeg', lastModified: Date.now() },
  )
}
