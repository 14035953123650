import * as faceapi from 'face-api.js'

const FACE_API_MODELS_URL = 'https://storage.googleapis.com/guita-face-api-models/'

export const detectFace = async (imgDataUrl: string) => {
  await faceapi.nets.ssdMobilenetv1.loadFromUri(FACE_API_MODELS_URL)

  const image = new Image() as HTMLImageElement
  image.src = imgDataUrl
  await image.decode()

  const detections = await faceapi.detectAllFaces(image)

  return (detections.length > 0)
}
