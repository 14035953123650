import * as React from 'react'

import { CacheProvider } from '@emotion/react'

import getEmotionCache from './src/get_emotion_cache'

import type { GatsbyBrowser } from 'gatsby'

const cache = getEmotionCache()

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <CacheProvider value={cache}>{element}</CacheProvider>
)
