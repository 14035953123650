import * as React from 'react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import type { GatsbyImageProps } from 'gatsby-plugin-image'

export type BlogImageData = {
  gatsbyImageData: GatsbyImageProps
}

type BlogImageProps = Omit<GatsbyImageProps, 'image'> & {
  image: BlogImageData
}

export const BlogImage = ({
  image,
  ...rest
}: BlogImageProps) => {
  const fetchedImage = getImage(image)

  if (!fetchedImage) {
    return null
  }

  return (
    <GatsbyImage
      image={fetchedImage}
      {...rest}
    />
  )
}
