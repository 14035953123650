import type { UserCredential } from '../queries'

const userCredentialKey = 'userCredential'

export const getCredential = (): UserCredential | null => {
  const data = typeof window !== 'undefined'
    ? window.localStorage.getItem(userCredentialKey)
    : null

  return data ? JSON.parse(data) : null
}

export const setCredential = (credentials: UserCredential | null): void =>
  credentials !== null
    ? window.localStorage.setItem(userCredentialKey, JSON.stringify(credentials))
    : window.localStorage.removeItem(userCredentialKey)

export const clearCredential = () => setCredential(null)

export const isLoggedIn = (): boolean => getCredential() !== null
