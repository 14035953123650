import * as React from 'react'

import { Stack } from '@mui/material'
import { ErrorMessage, useFormikContext } from 'formik'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import * as Yup from 'yup'

import type { PhoneNumber } from '../queries'

export type PhoneNumberFormValues = {
  phoneNumber: string
}

export const getPhoneNumberInitialValues =
  (data?: PhoneNumber): PhoneNumberFormValues => ({
    phoneNumber: data?.value || '',
  })

export const phoneNumberValidationSchema: Yup.SchemaOf<PhoneNumberFormValues> =
  Yup.object({
    phoneNumber: Yup.string()
      .required('Este campo es obligatorio')
      .test({
        message: 'El número de teléfono es inválido',
        test: (value) => matchIsValidTel(value || ''),
      }),
  })

export const PhoneNumberFields = <FormValues extends PhoneNumberFormValues>() => {
  const { errors, touched, values, setFieldValue, setFieldTouched } = useFormikContext<FormValues>()

  return (
    <Stack spacing={3}>
      <MuiTelInput
        name='phoneNumber'
        fullWidth
        required
        defaultCountry='CL'
        langOfCountryName='es'
        label='Teléfono'
        helperText={touched.phoneNumber && <ErrorMessage name='phoneNumber' />}
        error={touched.phoneNumber && !!errors.phoneNumber}
        value={values.phoneNumber}
        onChange={(newValue) => setFieldValue('phoneNumber', newValue)}
        onBlur={() => setFieldTouched('phoneNumber')}
      />
    </Stack>
  )
}
