import * as React from 'react'

import { CircularProgress, createSvgIcon, styled } from '@mui/material'

const heavyMoveTransition = 'cubic-bezier(0.7, 0, 0.6, 1)'

const BrokenImageIcon = createSvgIcon(
  <path
    d={
      'M21 5v6.59l-2.29-2.3c-.39-.39-1.03-.39-1.42 0L14 12.59 10.71 9.3a.9959.9959 0 0 0-1.41 0L6 12.59 3'
      + ' 9.58V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2zm-3 6.42 3 3.01V19c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-6.58l2.29'
      + ' 2.29c.39.39 1.02.39 1.41 0l3.3-3.3 3.29 3.29c.39.39 1.02.39 1.41 0l3.3-3.28z'
    }
  />,
  'BrokenImageIcon',
)

const Img = styled('img')({
  '@keyframes materialize': {
    '0%': {
      filter: 'saturate(20%) contrast(50%) brightness(120%)',
    },
    '75%': {
      filter: 'saturate(60%) contrast(100%) brightness(100%)',
    },
    '100%': {
      filter: 'saturate(100%) contrast(100%) brightness(100%)',
    },
  },
})

export const Image = ({
  src,
  alt = '',
  height = '100%',
  width = '100%',
  position = 'relative',
  fit = 'cover',
  style = {},
  className = '',
  wrapperStyle = {},
  wrapperClassName = '',
  iconWrapperStyle = {},
  iconWrapperClassName = '',
  showLoading = false,
  errorIcon = true,
  shift = false,
  distance = 100,
  shiftDuration = 0,
  bgColor = 'inherit',
  duration = 3000,
  easing = heavyMoveTransition,
  onLoad: onLoadProp = undefined,
  onError: onErrorProp = undefined,
  ...rest
}: ImageProps) => {
  const image = React.useRef<HTMLImageElement>(null)

  const [loaded, setLoaded] = React.useState(false)
  const [error, setError] = React.useState(false)

  function handleLoad() {
    setLoaded(true)
    setError(false)

    if (onLoadProp) {
      onLoadProp()
    }
  }

  function handleError() {
    setError(true)
    setLoaded(false)

    if (onErrorProp) {
      onErrorProp()
    }
  }

  const styles = {
    root: {
      width,
      height,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: bgColor,
      ...wrapperStyle,
    },
    image: {
      position,
      width: '100%',
      height: '100%',
      objectFit: fit,
      transitionProperty: `${shift ? `${shift}, ` : ''}opacity`,
      transitionDuration: `${
        shift ? `${shiftDuration || duration * 0.3}ms, ` : ''
      }${duration / 2}ms`,
      transitionTimingFunction: easing,
      opacity: loaded ? 1 : 0,
      animation: loaded ? `materialize ${duration}ms 1 ${easing}` : 'none',
      ...(typeof shift === 'string' && {
        [shift]: loaded ? 0 : distance,
      }),
      ...style,
    },
    icons: {
      width: '100%',
      marginLeft: '-100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: loaded ? 0 : 1,
      ...iconWrapperStyle,
    },
  }

  React.useEffect(() => {
    const currentImage = image.current
    if (currentImage && currentImage.complete) {
      handleLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showErrorIcon = (typeof errorIcon !== 'boolean' && errorIcon) || (
    <BrokenImageIcon
      sx={{
        fontSize: 56,
        color: 'action.disabled',
      }}
    />
  )

  const loadingIndicator = (typeof showLoading !== 'boolean' && showLoading) || (
    <CircularProgress
      size={56}
      thickness={6}
    />
  )

  return (
    <div
      style={styles.root}
      className={`mui-image-wrapper ${wrapperClassName}`}
    >
      <Img
        ref={image}
        src={src}
        alt={alt}
        style={styles.image}
        className={`mui-image-img ${className}`}
        onLoad={handleLoad}
        onError={handleError}
        {...rest}
      />
      {(Boolean(showLoading) || Boolean(errorIcon)) && (
        <div
          style={styles.icons}
          className={`mui-image-iconWrapper ${iconWrapperClassName}`}
        >
          {Boolean(errorIcon) && error && showErrorIcon}
          {Boolean(showLoading) && !error && !loaded && loadingIndicator}
        </div>
      )}
    </div>
  )
}

export type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  src: string
  alt: string
  height?: number | string
  width?: number | string
  style?: React.CSSProperties
  className?: string
  wrapperStyle?: React.CSSProperties
  wrapperClassName?: string
  iconWrapperStyle?: React.CSSProperties
  iconWrapperClassName?: string
  showLoading?: boolean | React.ReactNode
  errorIcon?: boolean | React.ReactNode
  shift?: false | null | 'top' | 'bottom' | 'left' | 'right'
  distance?: number | string
  shiftDuration?: number
  bgColor?: string
  duration?: number
  easing?: string
  onLoad?: (() => void) | undefined
  onError?: (() => void) | undefined
  position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky' | 'inherit' | 'initial' | 'revert' | 'unset'
  fit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' | 'inherit' | 'initial' | 'revert' | 'unset'
}
